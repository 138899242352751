import {
  Divider,
  Stack,
  Box,
  Backdrop,
  CircularProgress,
  useTheme,
  Typography,
  Button,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import TopBar from './components/TopBar';
import useGetRule from './hooks/useGetRule';
import { BlocklyWorkspace } from './components/BlocklyWorkspace';
import { Editor } from '@monaco-editor/react';
import { Events, serialization } from 'blockly';
import { jsonGenerator } from 'blockly/json';
import { LeftBar } from './components/LeftBar';

export function EditLogic() {
  const { data, loading } = useGetRule();
  const { palette } = useTheme();
  const [workspace, setWorkspace] = useState(null);
  const [code, setCode] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onWorkspaceChange = useCallback(() => {
    if (workspace) {
      if (workspace.allInputsFilled()) {
        const code = jsonGenerator.workspaceToCode(workspace);
        try {
          const formattedCode = JSON.stringify(JSON.parse(code), undefined, 2);
          setCode(formattedCode);
        } catch {
          setCode(code);
        }
      } else {
        setCode(`{}`);
      }
    }
  }, [workspace]);

  useEffect(() => {
    if (workspace) {
      workspace.addChangeListener(onWorkspaceChange);
      workspace.addChangeListener(Events.disableOrphans);
    }
  }, [workspace, onWorkspaceChange]);

  useEffect(() => {
    if (!loading && data && workspace) {
      const { blockly_definition } = data;
      try {
        serialization.workspaces.load(blockly_definition || {}, workspace, {
          recordUndo: false,
        });
      } catch (error) {
        console.error(error);
      }
      if (workspace.remainingCapacityOfType('rule_base') === 1) {
        serialization.blocks.append({ type: 'rule_base' }, workspace, {
          recordUndo: false,
        });
      }
      workspace.cleanUp();
      workspace.scrollCenter();
    }
  }, [loading, data, workspace]);

  return (
    <Stack
      direction={'column'}
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      minHeight={'100vh'}
      maxHeight={'100vh'}
      overflow={'hidden'}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <Typography
          marginLeft={2}
          variant={'h4'}
          border={'solid 1px'}
          borderRadius={1}
          p={1}
          sx={{ cursor: 'pointer', userSelect: 'none' }}
          borderColor={palette.divider}
          component={Button}
          onClick={() => setIsOpen(true)}
        >
          {data?.title}
        </Typography>
        <TopBar workspace={workspace} />
      </Stack>
      <Divider />
      <Stack flex={1} direction={'row'} alignItems={'stretch'}>
        <Box
          flex={process.env.REACT_APP_ENV === process.env.REACT_APP_ENV_LOCAL ? 7.5 : 1}
        >
          <BlocklyWorkspace ref={setWorkspace} />
        </Box>
        {process.env.REACT_APP_ENV === process.env.REACT_APP_ENV_LOCAL && (
          <>
            <Divider orientation='vertical' flexItem />
            <Box flex={2.5}>
              <Editor
                theme='vs-dark'
                value={code}
                defaultLanguage='json'
                defaultValue='{}'
                options={{
                  readOnly: true,
                  folding: false,
                  fontSize: 18,
                  lineNumbersMinChars: 3,
                  renderLineHighlight: 'none',
                  renderWhitespace: 'none',
                  selectionHighlight: false,
                  occurrencesHighlight: false,
                  lineHeight: 1.4,
                  minimap: {
                    enabled: false,
                  },
                  scrollbar: {
                    horizontalScrollbarSize: 4,
                    verticalScrollbarSize: 4,
                  },
                }}
              />
            </Box>
          </>
        )}
      </Stack>
      <Backdrop open={loading} sx={{ zIndex: 5000 }}>
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            backgroundColor: palette.background.default,
            height: '100vh',
            width: '100vw',
          }}
        >
          <CircularProgress />
        </Stack>
      </Backdrop>
      <LeftBar isOpen={isOpen} onClose={setIsOpen} />
    </Stack>
  );
}
