import * as Blockly from 'blockly';

Blockly.Blocks['bd_actions'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('Open Investigation on')
      .appendField(
        new Blockly.FieldDropdown([
          ['Entity', 'entity'],
          ['Sender Entity', 'sender_entity'],
          ['Receiver Entity', 'receiver_entity'],
        ]),
        'DD0'
      );
    this.setOutput(true, 'Action');
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};
