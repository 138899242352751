// Lists
export const listsListURL = {
  url: 'lists',
  method: 'GET',
};

export const addListsURL = {
  url: 'lists',
  method: 'POST',
};

export const getListsByIdURL = (list_id) => ({
  url: `lists/${list_id}`,
  method: 'GET',
});

export const updateListsURL = (list_id) => ({
  url: `lists/${list_id}`,
  method: 'PUT',
});

export const getListEntriesURL = (list_id) => ({
  url: `lists/${list_id}/entries`,
  method: 'GET',
});

export const addListEntryURL = (list_id) => ({
  url: `lists/${list_id}/entries`,
  method: 'POST',
});

export const deleteListEntryURL = (list_id) => ({
  url: `lists/${list_id}/entries`,
  method: 'DELETE',
});
