import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import Axios from 'services/api/Config';
import { Header, HFTextInput, Toast } from 'components';

import { tokens } from 'theme';
import { clearLocal } from 'utils/common';
import { currentUserURL, updateUsersURL } from 'services/api/routes/users';

const userSettingSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const UserSetting = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const { logout } = useAuth0();

  const [userDetail, setUserDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const initialValues = {
    name: '',
  };

  const {
    // reset,
    handleSubmit,
    setValue,
    control: userControl,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(userSettingSchema),
  });

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    clearLocal();
  };

  useEffect(() => {
    setIsLoading(true);
    Axios({ ...currentUserURL })
      .then((response) => {
        const res = response.data;
        setValue('name', res?.name ?? '');
        setUserDetail(res);
      })
      .catch(() => {
        navigate('/dashboard');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [navigate, setValue]);

  const onSubmit = (data) => {
    setIsSubmitLoading(true);
    Axios({ ...updateUsersURL(userDetail?.id), data: data })
      .then((res) => {
        Toast.success('User updated');
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  return (
    <Box m='20px'>
      {isLoading ? (
        <div className='w-full my-4 text-center'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <>
          <Header title='User' />
          <div className='flex '>
            <Card
              className='basis-full md:basis-1/2 lg:basis-1/3'
              sx={{
                backgroundColor: colors.primary[400],
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent className='!pb-0'>
                  <span className='text-base'>Email: {userDetail?.email}</span>
                  <div className='mt-4 mb-3 row'>
                    <div className='col-md-12'>
                      <HFTextInput
                        id='name'
                        name='name'
                        label='Name'
                        isRequired={true}
                        control={userControl}
                      />
                    </div>
                  </div>
                </CardContent>
                <CardActions className='justify-between'>
                  <Link to={'/accounts'}>
                    <Button
                      color='secondary'
                      variant='text'
                      className='!mt-4'
                      sx={{ textTransform: 'none', color: colors.grey[100] }}
                    >
                      Switch Account
                    </Button>
                  </Link>
                  <LoadingButton
                    type='submit'
                    color='secondary'
                    variant='contained'
                    loading={isSubmitLoading}
                    loadingPosition='center'
                    sx={{
                      textTransform: 'none',
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[100],
                    }}
                  >
                    Update
                  </LoadingButton>
                </CardActions>
              </form>
            </Card>
          </div>
          <Button
            variant='text'
            color='error'
            className='!mt-4'
            sx={{ textTransform: 'none', color: colors.grey[100] }}
            startIcon={<PowerSettingsNewIcon />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </>
      )}
    </Box>
  );
};

export default UserSetting;
