import { CodeGenerator } from 'blockly';

class JSONGenerator extends CodeGenerator {
  constructor() {
    super('JSON');
    this.INDENT = '';
  }
  scrub_(block, code, thisOnly) {
    const nextBlock = block.nextConnection && block.nextConnection.targetBlock();
    if (nextBlock && !thisOnly) {
      return `${code}\n${this.blockToCode(nextBlock)}`;
    }
    return code;
  }
}

export const jsonGenerator = new JSONGenerator();
