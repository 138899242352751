import React from 'react';

import { CircularProgress } from '@mui/material';
// import Logo from "components/Logo";

const BackdropLoader = () => {
  return (
    <div className='flex flex-col items-center justify-center !h-screen !w-screen'>
      <div>
        {/* <Logo /> */}
        <span className='text-5xl'>Byte Detection</span>
      </div>
      <div className='mt-4'>
        <CircularProgress color='secondary' />
      </div>
    </div>
  );
};

export default BackdropLoader;
