// Dynamic Data
export const getDDTypesURL = {
  url: '/dynamic_data/types',
  method: 'GET',
};

export const getDDPropertiesURL = (type_id) => ({
  url: `/dynamic_data/types/${type_id}`,
  method: 'GET',
});
export const searchDDTableURL = {
  url: '/dynamic_data/data',
  method: 'GET',
};
