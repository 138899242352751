import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { tokens } from 'theme';
import { LoadingButton } from '@mui/lab';
import Axios from 'services/api/Config';
import { deleteListEntryURL } from 'services/api/routes/lists';
import { useAuthUser } from 'utils/context/AuthUserContext';

const DeleteAlertModel = ({
  listId,
  selectedEntries,
  setSelectedEntries,
  handleAlertClose,
  setTableRefresh,
  alertModel,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accessAccount } = useAuthUser();
  const [alertLoading, setAlertLoading] = useState(false);

  const entryKeyword = selectedEntries?.length > 1 ? 'Entries' : 'Entry';

  const entryDeleteHandler = () => {
    setAlertLoading(true);
    Axios({
      ...deleteListEntryURL(listId),
      params: { account_id: accessAccount?.id },
      data: selectedEntries,
    })
      .then((res) => {
        setSelectedEntries([]);
        fetch();
      })
      .finally(() => {
        setTableRefresh((prev) => !prev);
        setAlertLoading(false);
        handleAlertClose();
      });
  };

  return (
    <Dialog
      maxWidth='xs'
      fullWidth={true}
      open={alertModel}
      onClose={handleAlertClose}
      scroll='paper'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{ '& .MuiDialog-paper': { backgroundColor: colors.primary[500] } }}
    >
      <DialogTitle id='alert-dialog-title' className='!font-bold !text-base'>
        Delete {entryKeyword}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to <strong>Delete </strong>{' '}
          {selectedEntries?.length > 1 ? 'these' : 'this'} {entryKeyword}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAlertClose}
          variant='outlined'
          color='error'
          sx={{
            textTransform: 'none',
            color: colors.grey[100],
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          type='submit'
          color='secondary'
          variant='contained'
          loading={alertLoading}
          loadingPosition='center'
          onClick={entryDeleteHandler}
          sx={{
            textTransform: 'none',
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
          }}
        >
          Delete {entryKeyword}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAlertModel;
