import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';

import { tokens } from 'theme';
import { formatNumber } from 'utils/common';

const StatBox = ({ amount, title, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width='100%' m='0 30px'>
      <div className='flex gap-3'>
        {icon}
        <Typography variant='h3' fontWeight='bold' sx={{ color: colors.grey[100] }}>
          {formatNumber(amount)}
        </Typography>
      </div>
      <div className='flex justify-between mt-2'>
        <Typography variant='h4' sx={{ color: colors.greenAccent[500] }}>
          {title}
        </Typography>
      </div>
    </Box>
  );
};

StatBox.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  amount: PropTypes.number,
};

export default StatBox;
