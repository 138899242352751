import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from 'App';
import { BackdropLoader } from 'components';
import { AuthProvider } from 'router/auth0/AuthProvider';
import { AuthUserProvider } from 'utils/context/AuthUserContext';

import './assets/css/main.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Suspense fallback={<BackdropLoader />}>
    <BrowserRouter>
      <AuthProvider>
        <AuthUserProvider>
          <App />
        </AuthUserProvider>
      </AuthProvider>
    </BrowserRouter>
  </Suspense>
  // </React.StrictMode>
);
