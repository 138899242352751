import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { HFTextInput } from 'components';
import Axios from 'services/api/Config';

import { tokens } from 'theme';

import { useAuthUser } from 'utils/context/AuthUserContext';
import { addRulesURL } from 'services/api/routes/rules';
import { useNavigate } from 'react-router';

const addRuleSchema = Yup.object().shape({
  title: Yup.string().trim().required('Title is required'),
  description: Yup.string().trim().required('Rule Description is required'),
});

const AddRuleModel = (props) => {
  const { openModel = false, handleModelClose } = props;

  const navigate = useNavigate();
  const { accessAccount } = useAuthUser();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    title: '',
    description: '',
  };

  const {
    reset,
    handleSubmit,
    control: ruleControl,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(addRuleSchema),
  });

  const onSubmit = (data) => {
    const tData = {
      ...data,
      account_id: accessAccount?.id,
      definition: {},
    };
    setIsLoading(true);
    Axios({ ...addRulesURL, data: tData })
      .then((res) => {
        handleClose();
        navigate(`/rules/${res.data?.id}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    reset(initialValues);
    handleModelClose();
  };

  return (
    <>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={openModel}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: colors.primary[500],
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id='alert-dialog-title' className='!text-lg !font-bold'>
            Add Rule
          </DialogTitle>
          <DialogContent>
            <div className='mt-2'>
              <HFTextInput
                id='title'
                name='title'
                label='Title'
                control={ruleControl}
                classes={{ wrapper: 'mb-3' }}
              />
              <HFTextInput
                id='description'
                name='description'
                label='Rule Description'
                multiline={true}
                control={ruleControl}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant='outlined'
              color='error'
              sx={{
                textTransform: 'none',
                color: colors.grey[100],
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              color='secondary'
              variant='contained'
              loading={isLoading}
              loadingPosition='center'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
            >
              Save Rule
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

AddRuleModel.propTypes = {
  handleModelClose: PropTypes.func,
  openModel: PropTypes.bool,
};

export default AddRuleModel;
