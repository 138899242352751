import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ToastContainer } from 'react-toastify';

import Router from 'router/Router';

import { ColorModeContext, useMode } from 'theme';

const App = () => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <Router />
        </SnackbarProvider>
        <ToastContainer />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
