import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { HFTextInput, Table } from 'components';
import { tokens } from 'theme';
import { useAuthUser } from 'utils/context/AuthUserContext';

const PERMISSIONS_ARRAY = [
  { id: 1, name: 'View Rules' },
  { id: 2, name: 'Create Rules' },
  { id: 3, name: 'Update Rules' },
  { id: 4, name: 'Deactivate Rules' },
  { id: 5, name: 'Test Rules' },
  { id: 6, name: 'Activate Rules' },
  { id: 7, name: 'View Data' },
  { id: 8, name: 'View Teams' },
  { id: 9, name: 'Create Teams' },
  { id: 10, name: 'Update Teams' },
  { id: 11, name: 'View User Permissions' },
  { id: 12, name: 'Update User Permissions' },
  { id: 13, name: 'View Api Keys' },
  { id: 14, name: 'Create Api Keys' },
  { id: 15, name: 'Deactivate Api Keys' },
  { id: 16, name: 'Account Admin' },
];

const EditUserPermissions = (props) => {
  const { editModel = false, handleModelClose, handleTableRefresh } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const { accessAccount } = useAuthUser();

  // const [isLoading, setIsLoading] = useState(false);
  const [filteredList, setFilteredList] = useState(PERMISSIONS_ARRAY);
  const [searchText, setSearchText] = useState('');

  const [selectedPermissions, setSelectedPermissions] = useState([1, 3, 14]);

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      minWidth: 80,
      maxWidth: 120,
      sortable: false,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 180,
      sortable: false,
      flex: 1,
    },
  ];

  const handleClose = () => {
    // reset(initialValues);
    handleModelClose();
  };

  const handleSearch = (val) => {
    setSearchText(val);
    if (val !== '') {
      const tFiltered = PERMISSIONS_ARRAY.filter((permission) =>
        permission.name.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredList(tFiltered);
    } else {
      setFilteredList(PERMISSIONS_ARRAY);
    }
  };

  return (
    <Dialog
      maxWidth='sm'
      fullWidth={true}
      open={!!editModel}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: colors.primary[500],
        },
      }}
    >
      <DialogTitle id='alert-dialog-title'>Edit Permissions</DialogTitle>
      <DialogContent className='max-h-[580px]'>
        <HFTextInput
          name='Search'
          label='Search'
          placeholder='Search Table...'
          isController={false}
          classes={{ wrapper: 'mt-2 mb-3' }}
          defaultValue={searchText}
          handleOnChange={handleSearch}
        />
        <Table
          columns={columns}
          tableDATA={filteredList}
          rowSelection
          rowSelectionModel={selectedPermissions}
          handleSelection={(val) => setSelectedPermissions(val)}
          hideFooter={true}
          showPagination={false}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          variant='outlined'
          sx={{
            textTransform: 'none',
            color: colors.grey[100],
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <LoadingButton
          type='submit'
          color='primary'
          variant='contained'
          // loading={isLoading}
          loadingPosition='center'
          // onClick={handleDelete}
          sx={{
            textTransform: 'none',
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
          }}
        >
          Save Permissions
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

EditUserPermissions.propTypes = {
  editModel: PropTypes.bool,
  handleModelClose: PropTypes.func,
  handleTableRefresh: PropTypes.func,
};

export default EditUserPermissions;
