import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { HFTextInput, Toast } from 'components';
import Axios from 'services/api/Config';

import { tokens } from 'theme';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { inviteAccountURL } from 'services/api/routes/accounts';

const inviteUserSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid Email').required('Email is required'),
});

const InviteModel = (props) => {
  const { inviteModel = false, handleInviteClose, handleTableRefresh } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { accessAccount } = useAuthUser();

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = { email: '' };

  const {
    reset,
    handleSubmit,
    control: inviteControl,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(inviteUserSchema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    Axios({ ...inviteAccountURL(accessAccount?.id), data: data })
      .then((res) => {
        Toast.success(`Email invite sent to "${res.data.email}"`);
        reset(initialValues);
        handleTableRefresh((prev) => !prev);
        handleInviteClose();
      })
      .catch((err) => {
        Toast.error(err.data.detail.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    reset(initialValues);
    handleInviteClose();
  };

  return (
    <>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={inviteModel}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: colors.primary[500],
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id='alert-dialog-title'>Invite User</DialogTitle>
          <DialogContent>
            <div className='my-3 row'>
              <div className='col-md-12'>
                <HFTextInput
                  id='email'
                  name='email'
                  label='Invite Email'
                  isRequired={true}
                  control={inviteControl}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant='outlined'
              color='error'
              sx={{
                textTransform: 'none',
                color: colors.grey[100],
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              color='secondary'
              variant='contained'
              loading={isLoading}
              loadingPosition='center'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
            >
              Send Invite
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

InviteModel.propTypes = {
  handleInviteClose: PropTypes.func,
  handleTableRefresh: PropTypes.func,
  inviteModel: PropTypes.bool,
};

export default InviteModel;
