import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Toast } from 'components';
import { tokens } from 'theme';

import Axios from 'services/api/Config';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { deleteAPIKeyURL } from 'services/api/routes/accounts';

const DeleteAPIModel = (props) => {
  const { deleteModel = null, handleDeleteClose, tableRefresh } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accessAccount } = useAuthUser();

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    Axios({
      ...deleteAPIKeyURL(accessAccount?.id),
      params: {
        api_key_id: deleteModel?.id,
      },
    })
      .then((res) => {
        Toast.success('API Key Deleted.');
        tableRefresh();
        handleClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    handleDeleteClose(null);
  };

  return (
    <Dialog
      maxWidth='xs'
      fullWidth={true}
      open={!!deleteModel}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: colors.primary[500],
        },
      }}
    >
      <DialogTitle id='alert-dialog-title'>Delete API key</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <p>
            Are you sure you want to Delete API key <strong>{deleteModel?.name}</strong> ?
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type='submit'
          color='error'
          variant='contained'
          loading={isLoading}
          loadingPosition='center'
          onClick={handleDelete}
          sx={{
            textTransform: 'none',
            backgroundColor: colors.redAccent[700],
            color: colors.grey[100],
          }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteAPIModel.propTypes = {
  deleteModel: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  handleDeleteClose: PropTypes.func,
  tableRefresh: PropTypes.func,
};

export default DeleteAPIModel;
