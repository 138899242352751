import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uid } from 'uuid';

import { Box, Button, useTheme } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { tokens } from 'theme';
import HFTextInput from 'components/formControl/HFTextInput';

function EditToolbar(props) {
  const {
    setRows,
    setRowModesModel,
    fieldToFocus,
    newEmptyRow,
    rowID,
    searchVal,
    handleTableSearch,
  } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = () => {
    const id = uid();
    setRows((oldRows) => [{ [rowID]: id, ...newEmptyRow }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldToFocus },
    }));
  };

  return (
    <GridToolbarContainer className='flex justify-between my-2'>
      <HFTextInput
        name='search'
        label='Search..'
        placeholder='Search..'
        isController={false}
        value={searchVal}
        handleOnChange={handleTableSearch}
      />
      <Button
        variant='outlined'
        startIcon={<AddIcon />}
        sx={{ color: colors.grey[100] }}
        onClick={handleClick}
      >
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

const EditTable = (props) => {
  const {
    columns = [],
    tableRows = [],
    rowID = 'id',
    fieldToFocus = 'name',
    newEmptyRow = {},
    rowsUpdate,
  } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [rows, setRows] = useState(tableRows);
  const [rowModesModel, setRowModesModel] = useState({});

  const [searchVal, setSearchVal] = useState('');
  const [filteredRow, setFilteredRow] = useState(tableRows);
  // const [isSearchDisable, setIsSearchDisable] = useState(true);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    // setIsSearchDisable(true);
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row[rowID] !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    setRows(rows.filter((row) => row[rowID] !== id));
    // setIsSearchDisable(true);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    setRows(rows.map((row) => (row[rowID] === newRow[rowID] ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = () => {};

  const handleTableSearch = (val) => {
    let tFilterRows = [];

    if (val === '') {
      tFilterRows = rows;
    } else {
      tFilterRows = rows.filter((row) =>
        row[fieldToFocus].toLowerCase().includes(val.toLowerCase())
      );
    }

    setSearchVal(val);
    setFilteredRow(tFilterRows);
  };

  const tableColumns = [
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      align: 'right',
      headerAlign: 'right',
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={handleDeleteClick(id)}
            color='inherit'
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    rowsUpdate(rows);
    setFilteredRow(rows);
  }, [rows, rowsUpdate]);

  return (
    <>
      <Box
        sx={{
          '& .MuiDataGrid-root': { border: 'none', fontSize: '14px' },
          '& .MuiDataGrid-cell': { borderBottom: 'none' },
          '& .name-column--cell': { color: colors.greenAccent[300] },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
            overflow: 'hidden',
            minHeight: '50px',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: `16px !important`,
            fontWeight: 600,
          },
          '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
            backgroundColor: `${colors.primary[500]} !important`,
          },
        }}
      >
        <DataGrid
          hideFooter
          editMode='row'
          getRowId={(row) => row[rowID]}
          disableRowSelectionOnClick
          disableColumnFilter
          rows={filteredRow}
          columns={tableColumns}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{ toolbar: EditToolbar }}
          slotProps={{
            toolbar: {
              setRows,
              setRowModesModel,
              fieldToFocus,
              newEmptyRow,
              rowID,
              searchVal,
              handleTableSearch,
            },
          }}
        />
      </Box>
    </>
  );
};

EditTable.propTypes = {
  columns: PropTypes.array,
  fieldToFocus: PropTypes.string,
  newEmptyRow: PropTypes.object,
  rowID: PropTypes.string,
  rowsUpdate: PropTypes.func,
  tableRows: PropTypes.array,
};

export default EditTable;
