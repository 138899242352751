import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { HFTextInput } from 'components';
import Axios from 'services/api/Config';
import { tokens } from 'theme';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { addListEntryURL } from 'services/api/routes/lists';

const addEntriesSchema = Yup.object().shape({
  entry_string: Yup.string().trim().required('Value is required'),
});

const AddEntriesModel = (props) => {
  const { openModel = false, handleModelClose, setTableRefresh } = props;

  const navigate = useNavigate();
  const { accessAccount } = useAuthUser();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    entry_string: '',
  };

  let { listId } = useParams();

  const {
    reset,
    handleSubmit,
    control: entryControl,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(addEntriesSchema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    Axios({
      ...addListEntryURL(listId),
      params: { account_id: accessAccount?.id },
      data: { ...data, entry_data: {} },
    })
      .then((res) => {
        handleClose();
        navigate(`/lists/${listId}`);
        setTableRefresh((prev) => !prev);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    reset(initialValues);
    handleModelClose();
  };

  return (
    <>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={openModel}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: colors.primary[500],
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id='alert-dialog-title' className='!text-lg !font-bold'>
            Add Entry
          </DialogTitle>
          <DialogContent>
            <div className='mt-2'>
              <HFTextInput
                id='entry_string'
                name='entry_string'
                label='Value'
                control={entryControl}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant='outlined'
              color='error'
              sx={{
                textTransform: 'none',
                color: colors.grey[100],
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              color='secondary'
              variant='contained'
              loading={isLoading}
              loadingPosition='center'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

AddEntriesModel.propTypes = {
  handleModelClose: PropTypes.func,
  openModel: PropTypes.bool,
};

export default AddEntriesModel;
