// Rules
export const rulesListURL = {
  url: 'rules',
  method: 'GET',
};

export const addRulesURL = {
  url: 'rules',
  method: 'POST',
};

export const getRulesByIdURL = (id) => ({
  url: `rules/${id}`,
  method: 'GET',
});

export const getRulesStatsURL = (rule_id) => ({
  url: `rules/${rule_id}/stats`,
  method: 'GET',
});

export const updateRulesURL = (id) => ({
  url: `rules/${id}`,
  method: 'PUT',
});

export const disableRulesURL = (id) => ({
  url: `rules/${id}`,
  method: 'DELETE',
});

export const activateRulesURL = (id) => ({
  url: `rules/${id}/activate`,
  method: 'PUT',
});

export const enableRulesTestingURL = (id) => ({
  url: `rules/${id}/enable_testing`,
  method: 'PUT',
});

export const disableRulesTestingURL = (id) => ({
  url: `rules/${id}/disable_testing`,
  method: 'PUT',
});

export const ruleDecisionsListURL = (id) => ({
  url: `rules/${id}/decisions`,
  method: 'GET',
});
