import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  useTheme,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { FiltersCreator, Header, IconMenu, Table } from 'components';
import AddRuleModel from './components/AddRuleModel';

import { tokens } from 'theme';
import { getFormattedDate } from 'utils/common';
import { rulesListURL } from 'services/api/routes/rules';
import { CRUD_FILERS_TYPE } from 'constants/Filter.constants';
import { RULE_STATUS_TYPE, ruleStatusBadgeColor } from 'constants/Rules.constants';

const filterJSON = {
  initialQuery: { statuses: [] },
  wrapperClass: 'gap-3 grid grid-cols-6',
  fields: [
    {
      type: CRUD_FILERS_TYPE.SEARCH,
      name: 'title',
      label: 'Search',
      placeholder: 'Search...',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.SELECT,
      name: 'statuses',
      label: 'Status',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      fillOptions: {
        byApi: false,
        apiConfig: {},
        options: [
          { label: 'Active', value: RULE_STATUS_TYPE.ACTIVE },
          { label: 'Draft', value: RULE_STATUS_TYPE.DRAFT },
          { label: 'Testing', value: RULE_STATUS_TYPE.TESTING },
          { label: 'Deactivate', value: RULE_STATUS_TYPE.DEACTIVATED },
        ],
      },
      selectPluginConfig: {
        isClearable: true,
        isMulti: true,
      },
    },
    {
      type: CRUD_FILERS_TYPE.DATE_TIME,
      name: 'created_start',
      label: 'From',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.DATE_TIME,
      name: 'created_end',
      label: 'To',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      dateConfig: {
        dependent: 'created_start',
      },
    },
  ],
};

const Rules = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { initialQuery: filterQuery, fields = [], wrapperClass = '' } = filterJSON;

  const initialQuery = { ...filterQuery };
  const [filterObj, setFilterObj] = useState({ query: initialQuery });
  const [selectedRows, setSelectedRows] = useState([]);

  const [addRuleModel, setAddRuleModel] = useState(false);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
      renderCell: ({ row }) => (
        <>
          <Link to={`/rules/${row.id}`} className='underline'>
            {row?.id}
          </Link>
        </>
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Link to={`/rules/${row.id}`} className='underline'>
            {row?.title}
          </Link>
        </>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Chip
            size='small'
            label={`${row?.status}`}
            color={`${ruleStatusBadgeColor(row?.status)}`}
            className='capitalize'
          />
        </>
      ),
    },
    {
      field: 'author.name',
      headerName: 'Author',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => <>{row?.author.name}</>,
    },
    {
      field: 'created',
      headerName: 'Time Created',
      minWidth: 120,
      flex: 0.8,
      renderCell: ({ row: { created } }) => <>{getFormattedDate(created * 1000)}</>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'right',
      headerAlign: 'right',
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconMenu icon={<MoreHorizIcon />}>
              <Link to={`/rules/${row.id}`}>
                <MenuItem>
                  <ListItemIcon>
                    <VisibilityIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>View Details</ListItemText>
                </MenuItem>
              </Link>
            </IconMenu>
          </>
        );
      },
    },
  ];

  const handleRuleModelClose = () => setAddRuleModel(false);
  const handleRuleModelOpen = () => setAddRuleModel(true);

  return (
    <>
      <Box m='20px'>
        <div className='flex justify-between'>
          <div className='flex gap-3'>
            <Header title='Rules' />
            <FiltersCreator
              className='ms-3'
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              fields={fields}
              wrapperClass={wrapperClass}
              initialValues={{ query: initialQuery }}
            />
          </div>
          <div className=''>
            <Button
              variant='contained'
              color='secondary'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
              startIcon={<AddIcon />}
              onClick={handleRuleModelOpen}
            >
              Add Rule
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataURL={rulesListURL}
          filter={filterObj}
          rowSelection
          handleSelection={(id) => setSelectedRows(id)}
          rowSelectionModel={selectedRows}
        />
      </Box>
      <AddRuleModel handleModelClose={handleRuleModelClose} openModel={addRuleModel} />
    </>
  );
};

export default Rules;
