import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'services/api/Config';
import { useAuthUser } from 'utils/context/AuthUserContext';

function useUpdateRule() {
  const { ruleId } = useParams();
  const { accessAccount } = useAuthUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateRule = async (updatedData) => {
    setLoading(true);
    setError(null);
    try {
      await Axios.put(`rules/${ruleId}`, updatedData, {
        params: { account_id: accessAccount?.id },
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { updateRule, loading, error };
}

export default useUpdateRule;
