import React, { useState } from 'react';
import { Navigate } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';

import { Alert, Button, useTheme } from '@mui/material';
import { tokens } from 'theme';

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isError, setIsError] = useState(
    localStorage.getItem('login_warning') === 'true' ? true : false
  );

  const handleLogin = async () => {
    setIsError(false);
    localStorage.setItem('login_warning', null);
    await loginWithRedirect({
      authorizationParams: { prompt: 'login' },
      appState: { returnTo: '/accounts' },
    });
  };

  if (localStorage.getItem('auth_token')) {
    return <Navigate to={'/dashboard'} />;
  }

  return (
    <div className='flex flex-col items-center justify-center !h-screen !w-screen'>
      <div className='flex flex-col text-center'>
        <span className='text-xl text-gray-400'>Welcome to</span>
        <span className='text-5xl'>Byte Detection</span>
      </div>
      <Button
        variant='contained'
        color='secondary'
        size='large'
        className='!text-lg !mt-6'
        sx={{
          textTransform: 'none',
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
        }}
        onClick={handleLogin}
      >
        Login
      </Button>
      {isError && (
        <Alert variant='outlined' severity='error' className='mt-4 w-72'>
          User Not Found!
        </Alert>
      )}
    </div>
  );
};

export default Login;
