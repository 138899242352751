import { Divider, Drawer, Stack, Typography } from '@mui/material';
import React from 'react';
import useGetRule from '../hooks/useGetRule';

export function LeftBar(props) {
  const { isOpen, onClose } = props;
  const { data } = useGetRule();

  return (
    <Drawer anchor='left' open={isOpen} onClose={() => onClose(false)}>
      <Stack width={400} height={'100vh'} direction={'column'} alignItems={'stretch'}>
        <Stack p={2} direction={'row'}>
          <Typography
            variant={'h4'}
            sx={{ cursor: 'pointer', userSelect: 'none' }}
            fontWeight={'bold'}
            color={'primary'}
          >
            {data?.title}
          </Typography>
        </Stack>
        <Divider />
        <Stack p={2} spacing={1}>
          <Typography variant='h4' fontWeight={'bold'} color={'primary'}>
            Descriptions
          </Typography>
          <Typography variant='h5'>{data?.description}</Typography>
        </Stack>
        <Stack p={2} spacing={1}>
          <Typography variant='h4' fontWeight={'bold'} color={'primary'}>
            Author
          </Typography>
          <Typography variant='h5'>{data?.author?.name}</Typography>
          <Typography variant='h5'>{data?.author?.email}</Typography>
        </Stack>
        <Stack p={2} spacing={1}>
          <Typography variant='h4' fontWeight={'bold'} color={'primary'}>
            Status
          </Typography>
          <Typography variant='h5'>{data?.status}</Typography>
        </Stack>
      </Stack>
    </Drawer>
  );
}
