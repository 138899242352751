import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  useTheme,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { Header, IconMenu, Table } from 'components';
import { tokens } from 'theme';
import { getAPIListURL } from 'services/api/routes/accounts';
import { useAuthUser } from 'utils/context/AuthUserContext';

import AddAPIModel from './components/APIKey/AddAPIModel';
import DeleteAPIModel from './components/APIKey/DeleteAPIModel';

const APIKeyTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { accessAccount } = useAuthUser();

  const [addModel, setAddModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(null);

  const [refreshToggle, setRefreshToggle] = useState(false);

  const tableDataURL = useMemo(
    () => ({ ...getAPIListURL(accessAccount?.id) }),
    [accessAccount?.id]
  );

  const apiStatusBadgeColor = (status) => {
    switch (status) {
      case 'deactivated':
        return 'error';
      case 'active':
        return 'success';
      default:
        return 'default';
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 180,
      flex: 0.7,
    },
    {
      field: 'api_key',
      headerName: 'Key',
      minWidth: 180,
      sortable: false,
      flex: 1.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 180,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Chip
            size='small'
            label={`${row?.status}`}
            color={`${apiStatusBadgeColor(row?.status)}`}
            className='capitalize'
          />
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'right',
      headerAlign: 'right',
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: ({ row }) => {
        return (
          <div>
            {row.status === 'active' && (
              <IconMenu icon={<MoreHorizIcon />}>
                <MenuItem onClick={() => setDeleteModel(row)}>
                  <ListItemIcon>
                    <DeleteIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Delete API</ListItemText>
                </MenuItem>
              </IconMenu>
            )}
          </div>
        );
      },
    },
  ];

  const handleAddModelClose = () => setAddModel(false);
  const handleAddModelOpen = () => setAddModel(true);

  const handleRefresh = () => setRefreshToggle((prev) => !prev);

  return (
    <>
      <Box m='20px'>
        <div className='flex justify-between'>
          <Header title='API Key' />
          <div className=''>
            <Button
              variant='contained'
              color='secondary'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
              startIcon={<AddIcon />}
              onClick={handleAddModelOpen}
            >
              Add API key
            </Button>
          </div>
        </div>

        <Table columns={columns} dataURL={tableDataURL} checkEqual={refreshToggle} />
      </Box>

      <AddAPIModel
        addModel={addModel}
        handleAddClose={handleAddModelClose}
        tableRefresh={handleRefresh}
      />

      <DeleteAPIModel
        deleteModel={deleteModel}
        handleDeleteClose={setDeleteModel}
        tableRefresh={handleRefresh}
      />
    </>
  );
};

export default APIKeyTable;
