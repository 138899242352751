import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';

import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';

import { tokens } from 'theme';
import { checkActiveTab } from 'utils/common';

import 'react-pro-sidebar/dist/css/styles.css';

const ItemLink = ({ title, to, icon, className, href }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const location = useLocation();
  const { pathname } = location;

  return (
    <MenuItem
      active={checkActiveTab(to, pathname)}
      icon={icon}
      className={className}
      style={{ color: colors.grey[100] }}
    >
      <Typography fontSize={16}>{title}</Typography>
      {to && <Link to={to} />}
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      {href && <a href={href} target='_blank' rel='noreferrer' />}
    </MenuItem>
  );
};

const ChatSideBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#868dfb !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
        '& .pro-arrow-wrapper': {
          display: 'none',
        },
        '& .pro-icon ': {
          animation: 'none !important',
        },
      }}
    >
      <ProSidebar collapsed={false}>
        <Menu iconShape='square' className='px-6 border-b border-gray-500'>
          <Button
            fullWidth
            size='large'
            variant='outlined'
            startIcon={<AddIcon />}
            className='!normal-case !text-sm'
            sx={{
              color: colors.grey[100],
              border: `1px solid ${colors.grey[300]}`,
            }}
          >
            New Chat
          </Button>
        </Menu>
        <div className='overflow-y-auto' style={{ color: colors.grey[100] }}>
          <List>
            {[1, 2, 3, 4].map((element) => {
              return (
                <ListItem
                  className='group'
                  disableGutters
                  disablePadding
                  divider
                  key={element}
                >
                  <ListItemButton className='flex gap-3 !h-14 !py-4 !px-6'>
                    <ChatBubbleOutlineIcon />
                    <span>Text</span>
                    <div className='hidden gap-2 group-hover:flex ms-auto'>
                      <IconButton edge='end'>
                        <BorderColorIcon />
                      </IconButton>
                      <IconButton edge='end'>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>
        <Menu iconShape='square' className='mt-auto border-t border-gray-500'>
          <ItemLink
            title='Contact Support'
            icon={<SupportAgentIcon />}
            href={'mailto:chalmers@bytedetection.com'}
          />
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default ChatSideBar;

ItemLink.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
};
