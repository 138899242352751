import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import HFTextInput from 'components/formControl/HFTextInput';
import HFUserSelect from 'components/formControl/HFUserSelect';
import HFAutoComplete from 'components/formControl/HFAutoComplete';
import HFDateSelector from 'components/formControl/HFDateSelector';
import HFDateTimeSelector from 'components/formControl/HFDateTimeSelector';

import { tokens } from 'theme';
import { isEqual } from 'lodash';
import { cleanDeepObj, cleanObj, copyObj } from 'utils/common';
import { CRUD_FILERS_TYPE } from 'constants/Filter.constants';

const FiltersCreator = (props) => {
  const {
    filterObj,
    setFilterObj,
    fields = [],
    initialValues,
    className = '',
    wrapperClass = '',
  } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [queryFilters, setQueryFilters] = useState(filterObj.query);
  const [isModelOpen, setIsModelOpen] = useState(false);

  const [clearToggle, setClearToggle] = useState(true);

  const handleSubmit = (e) => {
    e?.preventDefault();
    setFilterObj({ query: cleanObj(queryFilters) });
    setIsModelOpen(false);
  };

  useEffect(() => {
    if (!clearToggle) {
      setClearToggle(true);
    }
  }, [clearToggle]);

  const handleSelectChange = (value, field) => {
    let tobj = { ...queryFilters };

    if (value) {
      tobj[field] = value;
    } else {
      delete tobj[field];
    }
    setQueryFilters(tobj);
  };

  const handleFilters = (field) => {
    const {
      name,
      label,
      maxDate,
      minDate,
      classes,
      placeholder,
      fillOptions = {},
      selectPluginConfig,
      dateConfig = {},
    } = field;

    const checkDateError = () => {
      const { dependent } = dateConfig;
      if (dateConfig?.dependent) {
        if (queryFilters?.[name] < queryFilters?.[dependent]) return true;
        else return false;
      }
      return false;
    };

    const commonProps = {
      id: name,
      name: name,
      label: label,
      classes: classes,
      isController: false,
      placeholder: placeholder ?? label,
    };

    if (field.type === CRUD_FILERS_TYPE.SEARCH) {
      return (
        <HFTextInput
          {...commonProps}
          value={queryFilters?.[name] ?? ''}
          handleOnChange={(val) => handleSelectChange(val, name)}
        />
      );
    }

    if (field.type === CRUD_FILERS_TYPE.NUMBER) {
      return (
        <HFTextInput
          {...commonProps}
          type='number'
          value={queryFilters?.[name] ?? undefined}
          handleOnChange={(val) => handleSelectChange(+val, name)}
        />
      );
    }

    if (field.type === CRUD_FILERS_TYPE.DATE) {
      return (
        <HFDateSelector
          {...commonProps}
          defaultValue={queryFilters?.[name] ? queryFilters?.[name] * 1000 : undefined}
          handleOnChange={(newValue) => handleSelectChange(moment(newValue).unix(), name)}
          maxDate={maxDate}
          minDate={minDate}
        />
      );
    }

    if (field.type === CRUD_FILERS_TYPE.DATE_TIME) {
      const isError = checkDateError();
      return (
        <HFDateTimeSelector
          {...commonProps}
          defaultValue={queryFilters?.[name] ? queryFilters?.[name] * 1000 : undefined}
          handleOnChange={(newValue) => handleSelectChange(moment(newValue).unix(), name)}
          isError={isError}
          maxDate={maxDate}
          minDate={minDate}
        />
      );
    }

    if (field.type === CRUD_FILERS_TYPE.SELECT) {
      return (
        <HFAutoComplete
          {...commonProps}
          fillOptions={fillOptions}
          selectPluginConfig={selectPluginConfig}
          defaultValue={queryFilters?.[field?.name] ?? undefined}
          handleOnChange={(newValue) => {
            if (selectPluginConfig?.isMulti) {
              return handleSelectChange(newValue, field?.name);
            }
            return handleSelectChange(newValue?.value, field?.name);
          }}
        />
      );
    }

    if (field.type === CRUD_FILERS_TYPE.SELECT_USER) {
      return (
        <HFUserSelect
          {...commonProps}
          selectPluginConfig={selectPluginConfig}
          defaultValue={queryFilters?.[field?.name] ?? undefined}
          handleOnChange={(newValue) => {
            if (selectPluginConfig?.isMulti) {
              return handleSelectChange(newValue, field?.name);
            }
            return handleSelectChange(newValue?.value, field?.name);
          }}
        />
      );
    }

    return field?.label;
  };

  const handleClose = () => {
    setIsModelOpen(false);
    setQueryFilters(filterObj.query);
  };

  const handleReset = () => {
    setClearToggle(false);
    setQueryFilters(initialValues.query);
    // setIsModelOpen(false);
    // setFilterObj({ query: cleanObj(initialValues.query) });
  };

  const isFilter = isEqual(
    cleanDeepObj(initialValues.query),
    cleanDeepObj(copyObj(filterObj.query))
  );

  return (
    <>
      <div className={className}>
        <Badge color='secondary' variant='dot' invisible={isFilter}>
          <Button
            variant={isFilter ? 'outlined' : 'contained'}
            color='secondary'
            sx={{
              textTransform: 'none',
              backgroundColor: isFilter ? colors.primary[600] : colors.blueAccent[700],
              color: colors.grey[100],
              border: isFilter ? `1px solid ${colors.grey[100]}` : '',
            }}
            startIcon={<FilterAltIcon />}
            onClick={() => setIsModelOpen(true)}
          >
            Filters
          </Button>
        </Badge>
      </div>
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={isModelOpen}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: colors.primary[500],
          },
          '& .MuiDialog-container': {
            alignItems: 'baseline',
          },
        }}
      >
        <form>
          <DialogTitle id='alert-dialog-title' className='!text-lg !font-bold'>
            Filters
          </DialogTitle>
          <DialogContent>
            <div className={`mt-3 ${wrapperClass}`}>
              {clearToggle &&
                fields.map((field, i) => {
                  return <React.Fragment key={i}>{handleFilters(field)}</React.Fragment>;
                })}
            </div>
          </DialogContent>
          <DialogActions className='!justify-between'>
            <Button
              onClick={handleReset}
              variant='text'
              color='error'
              sx={{ textTransform: 'none' }}
            >
              Clear All
            </Button>
            <Button
              color='secondary'
              variant='contained'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

FiltersCreator.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.array,
  filterObj: PropTypes.shape({
    query: PropTypes.object,
    search: PropTypes.object,
  }),
  initialValues: PropTypes.shape({
    query: PropTypes.object,
    search: PropTypes.string,
  }),
  setFilterObj: PropTypes.func,
  wrapperClass: PropTypes.string,
};

export default FiltersCreator;
