import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import { Typography, useTheme } from '@mui/material';

import { FiltersCreator, Table } from 'components';
import { tokens } from 'theme';

import { getFormattedDate } from 'utils/common';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { CRUD_FILERS_TYPE } from 'constants/Filter.constants';
import { ruleDecisionsListURL } from 'services/api/routes/rules';
import { getDDTypesURL } from 'services/api/routes/dynamicData';

const DecisionTable = (props) => {
  let { ruleId } = useParams();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accessAccount } = useAuthUser();

  const decisionsFilterJSON = {
    initialQuery: {
      // mode: [],
      type: [],
    },
    wrapperClass: 'gap-3 grid grid-cols-6',
    fields: [
      {
        type: CRUD_FILERS_TYPE.SEARCH,
        name: 'decision',
        label: 'Search Decision',
        placeholder: 'Search...',
        classes: {
          wrapper: 'col-span-full',
          field: '',
          error: '',
        },
      },
      {
        type: CRUD_FILERS_TYPE.SELECT,
        name: 'mode',
        label: 'Mode',
        classes: {
          wrapper: 'col-span-full md:col-span-2',
          field: '',
          error: '',
        },
        fillOptions: {
          byApi: false,
          apiConfig: {},
          options: [
            { value: 'test', label: 'Test' },
            { value: 'live', label: 'Live' },
          ],
        },
        selectPluginConfig: {
          isClearable: true,
          isMulti: false,
        },
      },
      {
        type: CRUD_FILERS_TYPE.SELECT,
        name: 'object_type',
        label: 'Object Type',
        classes: {
          wrapper: 'col-span-full md:col-span-2',
          field: '',
          error: '',
        },
        fillOptions: {
          byApi: true,
          apiConfig: {
            url: getDDTypesURL.url,
            method: getDDTypesURL.method,
            params: { account_id: accessAccount?.id },
            labelField: 'display_name',
            valueField: 'name',
          },
          options: [],
        },
        selectPluginConfig: {
          isClearable: true,
          isMulti: true,
        },
      },
      {
        type: CRUD_FILERS_TYPE.NUMBER,
        name: 'object_id',
        label: 'Object Id',
        classes: {
          wrapper: 'col-span-full md:col-span-2',
          field: '',
          error: '',
        },
      },
      {
        type: CRUD_FILERS_TYPE.DATE_TIME,
        name: 'start_time',
        label: 'From',
        classes: {
          wrapper: 'col-span-full md:col-span-3',
          field: '',
          error: '',
        },
      },
      {
        type: CRUD_FILERS_TYPE.DATE_TIME,
        name: 'end_time',
        label: 'To',
        classes: {
          wrapper: 'col-span-full md:col-span-3',
          field: '',
          error: '',
        },
        dateConfig: {
          dependent: 'start_time',
        },
      },
    ],
  };

  const {
    initialQuery: filterQuery,
    fields = [],
    wrapperClass = '',
  } = decisionsFilterJSON;

  const initialQuery = { ...filterQuery };
  const [filterObj, setFilterObj] = useState({ query: initialQuery });

  const decisionColumns = [
    {
      field: 'id',
      headerName: 'Decision ID',
      minWidth: 80,
      flex: 0.7,
    },
    {
      field: 'object_id',
      headerName: 'Data ID',
      minWidth: 80,
      flex: 0.7,
    },
    {
      field: 'object_type',
      headerName: 'Data Type',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row: { object_type } }) => (
        <div className='capitalize'>{object_type}</div>
      ),
    },
    {
      field: 'decision',
      headerName: 'Decision',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'mode',
      headerName: 'Mode',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row: { mode } }) => <div className='capitalize'>{mode}</div>,
    },
    {
      field: 'created',
      headerName: 'Decision At',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row: { created } }) => <>{getFormattedDate(created * 1000)}</>,
    },
  ];

  const tableDataURL = useMemo(
    () => ({
      ...ruleDecisionsListURL(ruleId),
      params: { account_id: accessAccount?.id },
    }),
    [accessAccount?.id, ruleId]
  );

  return (
    <>
      <div className='flex items-end gap-3 mt-4'>
        <Typography
          variant='h3'
          gutterBottom
          color={colors.grey[100]}
          className='capitalize'
        >
          Decisions
        </Typography>
        <FiltersCreator
          className='mb-2 grow'
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          fields={fields}
          wrapperClass={wrapperClass}
          initialValues={{ query: initialQuery }}
        />
      </div>

      <Table columns={decisionColumns} dataURL={tableDataURL} filter={filterObj} />
    </>
  );
};

DecisionTable.propTypes = {
  ruleDetails: PropTypes.object,
};

export default DecisionTable;
