/* eslint-disable no-unused-vars */
import { jsonGenerator } from '../json';

jsonGenerator.forBlock['bd_operator_comparison'] = function (block, generator) {
  const V0 = generator.valueToCode(block, 'V0', 0) || `""`;
  const F0 = block.getFieldValue('F0');
  const V1 = generator.valueToCode(block, 'V1', 0) || `""`;
  const code = `
  {
    "block" : "comparison",
    "return_type" : "boolean",
    "comparison_type" : "${F0}",
    "input" : [
      ${V0},
      ${V1}
    ]
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_operator_arithmetic'] = function (block, generator) {
  const V0 = generator.valueToCode(block, 'V0', 0) || `""`;
  const F0 = block.getFieldValue('F0');
  const V1 = generator.valueToCode(block, 'V1', 0) || `""`;
  const code = `
  {
    "block" : "operator",
    "return_type" : "number",
    "operation" : "${F0}",
    "input" : [
      ${V0},
      ${V1}
    ]
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_operator_logic'] = function (block, generator) {
  const F0 = block.getFieldValue('F0');
  const V0 = generator.valueToCode(block, 'V0', 0) || `""`;
  const V1 = generator.valueToCode(block, 'V1', 0) || `""`;
  const inputs = [V0, V1];
  let i = 0;
  while (block.getInput(`EV${i}`)) {
    const input = generator.valueToCode(block, `EV${i}`, 0) || `""`;
    inputs.push(input);
    i++;
  }
  const code = `
  {
    "block" : "and_or",
    "return_type" : "boolean",
    "val" : "${F0}",
    "input" : [
      ${inputs.join(',')}
    ]
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_operator_at_least'] = function (block, generator) {
  const F0 = block.getFieldValue('F0');
  const F1 = block.getFieldValue('F1');
  const V0 = generator.valueToCode(block, 'V0', 0) || `""`;
  const V1 = generator.valueToCode(block, 'V1', 0) || `""`;
  const inputs = [V0, V1];
  let i = 0;
  while (block.getInput(`EV${i}`)) {
    const input = generator.valueToCode(block, `EV${i}`, 0) || `""`;
    inputs.push(input);
    i++;
  }
  const code = `
  {
    "block" : "at_least",
    "return_type" : "boolean",
    "at_least_val" : ${F0},
    "true_false_val" : ${F1},
    "input" : [
      ${inputs.join(',')}
    ]
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['operator_aggregate_function'] = function (block, generator) {
  const OP0 = block.getFieldValue('OP0');
  const OP1 = block.getFieldValue('OP1');
  const OP2 = block.getFieldValue('OP2');
  const OP3 = block.getFieldValue('OP3');
  const OP4 = block.getFieldValue('OP4');
  const filters = [];
  let i = 0;
  while (block.getInput(`FV${i}`)) {
    const input = generator.valueToCode(block, `FV${i}`, 0);
    filters.push(input || '{}');
    i++;
  }
  const code = `
  {
    "block" : "agg_function",
    "return_type" : "number",
    "function" : "${OP0}",
    "object" : "${OP1}",
    "group_by" : "${OP1}.${OP4}",
    "object_id_field" : "${OP1}.id",
    "time_field" : "${OP1}.${OP3}",
    "agg_field" : "${OP1}.${OP2}",
    "filters": [
      ${filters.join(',')}
    ]
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_operator_all_same'] = function (block, generator) {
  const V0 = generator.valueToCode(block, 'V0', 0) || '';
  const code = `
  {
    "block" : "all_same_value",
    "return_type"  : "boolean",
    "input" : [
      ${V0}
    ]
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_operator_within'] = function (block, generator) {
  var V0 = generator.valueToCode(block, 'V0', 0) || '';
  var F0 = block.getFieldValue('F0');
  var F1 = block.getFieldValue('F1');
  var code = `
  {
    "block" : "within",
    "return_type" : "boolean",
    "within_type" : "${F0}",
    "input" : [
      ${V0}
    ],
    "val" : ${F0 === 'percent' ? F1 / 100 : F1}
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_operator_between'] = function (block, generator) {
  const V0 = generator.valueToCode(block, 'V0', 0) || '';
  const V1 = generator.valueToCode(block, 'V1', 0) || '';
  const V2 = generator.valueToCode(block, 'V2', 0) || '';
  const code = `
  {
    "block" : "between",
    "return_type"  : "boolean",
    "input"  : [
      ${V0},
      ${V1},
      ${V2}
    ]
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_operator_agg_function'] = function (block, generator) {
  const F0 = block.getFieldValue('F0'); // function name
  const F1 = block.getFieldValue('F1').V || ''; // object field
  const F2 = block.getFieldValue('F2'); // sub field
  const F3 = block.getFieldValue('F3'); // group by 1
  const F4 = block.getFieldValue('F4'); // group by 2
  const F5 = block.getFieldValue('F5'); // time tracked 1
  const F6 = block.getFieldValue('F6'); // time tracked 2
  const V0 = generator.valueToCode(block, 'V0', 0) || '[]';
  const code = `
  {
    "block" : "agg_function",
    "return_type" : "number",
    "function"  : "${F0}",
    "object"  : "${F1}",
    "object_id_field": "${F1}.id",
    "agg_field" : "${F1}.${F2.split('**')[0]}",
    "group_by"  : "${F3}.${F4.split('**')[0]}",
    "time_field"  : "${F5}.${F6.split('**')[0]}",
    "filters" : ${V0}
  }
  `;
  return [code, 0];
};
