import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu } from '@mui/material';

const IconMenu = (props) => {
  const {
    id = 'Icon Button',
    menuId = 'popper-menu',
    children,
    icon,
    size = '',
    buttonVariant = 'outlined',
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id={id}
        size={size}
        variant={buttonVariant}
        aria-haspopup='true'
        onClick={handleClick}
        aria-controls={open ? 'fade-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
      >
        {icon}
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': id }}
      >
        {children}
      </Menu>
    </div>
  );
};

IconMenu.propTypes = {
  buttonVariant: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  id: PropTypes.string,
  menuId: PropTypes.string,
  size: PropTypes.string,
};

export default IconMenu;
