import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Chip, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FiltersCreator, Header, Table } from 'components';

import { tokens } from 'theme';
import { getFormattedDate } from 'utils/common';
import { CRUD_FILERS_TYPE } from 'constants/Filter.constants';
import { ruleStatusBadgeColor } from 'constants/Rules.constants';
import { listTypeBadgeColor } from 'constants/List.constants';
import AddListModel from './components/AddListModel';
import { listsListURL } from 'services/api/routes/lists';
import { LIST_OPTIONS } from 'constants/List.constants';

const filterJSON = {
  initialQuery: { statuses: [] },
  wrapperClass: 'gap-3 grid grid-cols-6',
  fields: [
    {
      type: CRUD_FILERS_TYPE.SEARCH,
      name: 'name',
      label: 'Name',
      placeholder: 'Name',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.SEARCH,
      name: 'description',
      label: 'Description',
      placeholder: 'description',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.SELECT,
      name: 'list_types',
      label: 'Type',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      fillOptions: {
        byApi: false,
        apiConfig: {},
        options: LIST_OPTIONS,
      },
      selectPluginConfig: {
        isClearable: true,
        isMulti: true,
      },
    },
    {
      type: CRUD_FILERS_TYPE.SELECT_USER,
      name: 'author_ids',
      label: 'Activity By',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      selectPluginConfig: {
        isClearable: true,
        isMulti: true,
      },
    },
    {
      type: CRUD_FILERS_TYPE.DATE_TIME,
      name: 'created_start',
      label: 'From',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.DATE_TIME,
      name: 'created_end',
      label: 'To',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      dateConfig: {
        dependent: 'created_start',
      },
    },
  ],
};

const Lists = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { initialQuery: filterQuery, fields = [], wrapperClass = '' } = filterJSON;

  const initialQuery = { ...filterQuery };
  const [filterObj, setFilterObj] = useState({ query: initialQuery });
  const [selectedRows, setSelectedRows] = useState([]);

  const [addListModel, setAddListModel] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(false);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
      renderCell: ({ row }) => (
        <>
          <Link to={`/lists/${row.id}`} className='underline'>
            {row?.id}
          </Link>
        </>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Link to={`/lists/${row.id}`} className='underline'>
            {row?.name}
          </Link>
        </>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => <>{row?.description}</>,
    },
    {
      field: 'list_type',
      headerName: 'Type',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Chip
            size='small'
            label={`${row?.list_type}`}
            color={`${listTypeBadgeColor(row?.list_type)}`}
            className='capitalize'
          />
        </>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Chip
            size='small'
            label={`${row?.status}`}
            color={`${ruleStatusBadgeColor(row?.status)}`}
            className='capitalize'
          />
        </>
      ),
    },
    {
      field: 'created',
      id: 'created',
      headerName: 'Created At',
      minWidth: 120,
      flex: 0.8,
      renderCell: ({ row: { created } }) => <>{getFormattedDate(created * 1000)}</>,
    },
  ];

  const handleListModelClose = () => setAddListModel(false);
  const handleListModelOpen = () => setAddListModel(true);

  return (
    <>
      <Box m='20px'>
        <div className='flex justify-between'>
          <div className='flex gap-3'>
            <Header title='Lists' />
            <FiltersCreator
              className='ms-3'
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              fields={fields}
              wrapperClass={wrapperClass}
              initialValues={{ query: initialQuery }}
            />
          </div>
          <div className=''>
            <Button
              variant='contained'
              color='secondary'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
              startIcon={<AddIcon />}
              onClick={handleListModelOpen}
            >
              Create List
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataURL={listsListURL}
          filter={filterObj}
          handleSelection={(id) => setSelectedRows(id)}
          rowSelectionModel={selectedRows}
          checkEqual={tableRefresh}
        />
      </Box>
      <AddListModel
        handleModelClose={handleListModelClose}
        openModel={addListModel}
        setTableRefresh={setTableRefresh}
      />
    </>
  );
};

export default Lists;
