import * as Blockly from 'blockly';
import { FieldDataType } from 'blockly/fields/FieldDataType';

Blockly.Blocks['rule_base_1'] = {
  init: function () {
    this.conditions_ = 0;
    this.actions_ = 0;
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldDropdown([
          ['Select Decision', 'select_decision'],
          ['Block', 'block'],
          ['Manually Review', 'manually_review'],
          ['Process', 'process'],
        ]),
        'F0'
      )
      .appendField(new FieldDataType(), 'F1')
      .appendField('When');
    this.appendDummyInput()
      .setAlign(Blockly.inputs.Align.CENTRE)
      .appendField('Conditions');
    this.appendDummyInput().setAlign(Blockly.inputs.Align.CENTRE).appendField('Actions');
    this.setColour(290);
    this.setTooltip('');
    this.setHelpUrl('');
    this.setDeletable(false);
  },
  saveExtraState: function () {
    return { conditions: this.conditions_, actions: this.actions_ };
  },
  loadExtraState: function (state) {
    this.conditions_ = state.conditions;
    this.actions_ = state.actions;
    this.updateShape();
  },
  updateShape: function () {
    if (this.isInsertionMarker()) return;
    Blockly.Events.setGroup(true);
    // remove all inputs
    let c = 0;
    let a = 0;
    while (this.getInput(`C${c}`)) {
      this.removeInput(`C${c}`, true);
      c += 1;
    }
    while (this.getInput(`A${a}`)) {
      this.removeInput(`A${a}`, true);
      a += 1;
    }
    // add all inputs
    for (let i = 0; i < this.conditions_; i++) {
      this.appendValueInput(`C${i}`)
        .setCheck('boolean')
        .setAlign(Blockly.inputs.Align.RIGHT)
        .appendField(`Condition ${i + 1}`);
    }
    for (let j = 0; j < this.actions_; j++) {
      this.appendValueInput(`A${j}`)
        .setCheck('action')
        .setAlign(Blockly.inputs.Align.RIGHT)
        .appendField(`Condition ${j + 1}`);
    }
    Blockly.Events.setGroup(false);
  },
};

Blockly.Blocks['rule_base'] = {
  init: function () {
    this.conditions = 1;
    this.actions = 0;
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldDropdown([
          ['Select Decision', 'select_decision'],
          ['Block', 'block'],
          ['Manually Review', 'manually_review'],
          ['Process', 'process'],
        ]),
        'F0'
      )
      .appendField(new FieldDataType('Select Object'), 'F1')
      .appendField('When');
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldImage(
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAGVJREFUSEtjZKAxYKSx+QwjzIL///8vBwUpIyNjJLFBS1IQ/f///xvUAq5RC7CGwGgQgYMFmhT9caQSTqj4dxzyG9GTMEYypbkF+NL3aCQTzP2jQURMENG2uCboAiwKSKoPRqYFALluRBnTbhcoAAAAAElFTkSuQmCC',
          24,
          24,
          '',
          this.onAddC.bind(this)
        ),
        'ADD_C'
      )
      .appendField(
        new Blockly.FieldImage(
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAE9JREFUSEtjZKAxYKSx+QyjFhAM4dEgGg0igiFAUAFGKvr///9yBgYGf4I6sSvYyMjIGIksRX8LyHQ5Tm2jGY1giI4G0WgQEQwBggponooAmwYKGdeGr90AAAAASUVORK5CYII=',
          24,
          24,
          '',
          this.onRemoveC.bind(this)
        ),
        'REMOVE_C'
      )
      .setAlign(Blockly.inputs.Align.RIGHT)
      .appendField('Conditions');
    this.setInputsInline(false);
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
    this.updateShape();
    this.setDeletable(false);
  },
  saveExtraState() {
    return { conditions: this.conditions, actions: this.actions };
  },
  loadExtraState(state) {
    this.conditions = state.conditions;
    this.actions = state.actions;
    this.updateShape();
  },
  onAddC() {
    this.conditions += 1;
    this.updateShape();
  },
  onAddA() {
    this.actions += 1;
    this.updateShape();
  },
  onRemoveC() {
    if (this.conditions === 1) return;
    this.conditions -= 1;
    this.updateShape();
  },
  onRemoveA() {
    if (this.actions === 0) return;
    this.actions -= 1;
    this.updateShape();
  },
  updateShape() {
    Blockly.Events.setGroup(true);
    const conditionInputBlocks = [];
    const actionInputBlocks = [];
    let i = 0;
    let j = 0;
    while (this.getInput(`CV${i}`)) {
      const block = this.getInputTargetBlock(`CV${i}`);
      block?.unplug(false);
      conditionInputBlocks.push(block);
      this.removeInput(`CV${i}`, true);
      i++;
    }
    while (this.getInput(`AV${j}`)) {
      const block = this.getInputTargetBlock(`AV${j}`);
      block?.unplug(false);
      actionInputBlocks.push(block);
      this.removeInput(`AV${j}`, true);
      j++;
    }
    this.removeInput('DA', true);
    for (let k = 0; k < this.conditions; k++) {
      const input = this.appendValueInput(`CV${k}`)
        .appendField(`If`)
        .setCheck('boolean')
        .setAlign(Blockly.inputs.Align.RIGHT);
      if (conditionInputBlocks[k]) {
        input.connection.connect(conditionInputBlocks[k].outputConnection);
      }
    }
    this.appendDummyInput('DA')
      .appendField(
        new Blockly.FieldImage(
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAGVJREFUSEtjZKAxYKSx+QwjzIL///8vBwUpIyNjJLFBS1IQ/f///xvUAq5RC7CGwGgQgYMFmhT9caQSTqj4dxzyG9GTMEYypbkF+NL3aCQTzP2jQURMENG2uCboAiwKSKoPRqYFALluRBnTbhcoAAAAAElFTkSuQmCC',
          24,
          24,
          '',
          this.onAddA.bind(this)
        ),
        'ADD_A'
      )
      .appendField(
        new Blockly.FieldImage(
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAE9JREFUSEtjZKAxYKSx+QyjFhAM4dEgGg0igiFAUAFGKvr///9yBgYGf4I6sSvYyMjIGIksRX8LyHQ5Tm2jGY1giI4G0WgQEQwBggponooAmwYKGdeGr90AAAAASUVORK5CYII=',
          24,
          24,
          '',
          this.onRemoveA.bind(this)
        ),
        'REMOVE_A'
      )
      .setAlign(Blockly.inputs.Align.RIGHT)
      .appendField('Perform Actions');
    for (let l = 0; l < this.actions; l++) {
      const input = this.appendValueInput(`AV${l}`)
        .appendField(`Action`)
        .setCheck('Action')
        .setAlign(Blockly.inputs.Align.RIGHT);
      if (actionInputBlocks[l]) {
        input.connection.connect(actionInputBlocks[l].outputConnection);
      }
    }
    this.workspace.fireChangeListener(new Blockly.Events.Click(this));
    Blockly.Events.setGroup(false);
  },
};
