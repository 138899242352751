import { jsonGenerator } from '../json';

jsonGenerator.forBlock['rule_base'] = function (block, generator) {
  const action = block.getFieldValue('F0');
  const dataType = block.getFieldValue('F1').V;
  const actions = [];
  let i = 0;
  while (block.getInput(`AV${i}`)) {
    const input = generator.valueToCode(block, `AV${i}`, 0);
    actions.push(input);
    i++;
  }
  const expressions = [];
  let j = 0;
  while (block.getInput(`CV${j}`)) {
    const input = generator.valueToCode(block, `CV${j}`, 0);
    expressions.push(input);
    j++;
  }
  const code = `
  {
    "base" : {
      "block" : "data_action",
      "action" : "${action}",
      "data_type" : "${dataType}",
      "return_type" : "boolean",
      "expression" : [
        ${expressions.join(',')}
      ]
    },
    "actions" : [
      ${actions.join(',')}
    ]
  }
  `;
  return code;
};
