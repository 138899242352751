import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Chip, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { FiltersCreator, Header, Table } from 'components';

import { tokens } from 'theme';
import { getFormattedDate } from 'utils/common';
import { teamsListURL } from 'services/api/routes/teams';
import { CRUD_FILERS_TYPE } from 'constants/Filter.constants';
import { TEAMS_STATUS_TYPE, teamsStatusBadgeColor } from 'constants/Teams.constants';

import AddTeamsModel from './components/teams/AddTeamsModel';

const filterJSON = {
  initialQuery: { statuses: [] },
  wrapperClass: 'gap-3 grid grid-cols-6',
  fields: [
    {
      type: CRUD_FILERS_TYPE.SEARCH,
      name: 'name',
      label: 'Name',
      placeholder: 'Search Name...',
      classes: {
        wrapper: 'col-span-full',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.SEARCH,
      name: 'description',
      label: 'Description',
      placeholder: 'Search Description...',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.SELECT,
      name: 'statuses',
      label: 'Status',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      fillOptions: {
        byApi: false,
        apiConfig: {},
        options: [
          { label: 'Active', value: TEAMS_STATUS_TYPE.ACTIVE },
          { label: 'Deactivated', value: TEAMS_STATUS_TYPE.DEACTIVATED },
        ],
      },
      selectPluginConfig: {
        isClearable: true,
        isMulti: true,
      },
    },
  ],
};

const TeamsTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { initialQuery: filterQuery = {}, fields = [], wrapperClass = '' } = filterJSON;

  const initialQuery = { ...filterQuery };
  const [filterObj, setFilterObj] = useState({ query: initialQuery });

  const [addTeamModel, setAddTeamModel] = useState(false);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 80,
      flex: 0.5,
      renderCell: ({ row }) => (
        <Link to={`/account/teams/${row.id}`} className='underline'>
          {row?.id}
        </Link>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 0.8,
      renderCell: ({ row: { name, id } }) => (
        <Link to={`/account/teams/${id}`} className='underline'>
          {name}
        </Link>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 180,
      flex: 1.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 80,
      sortable: false,
      flex: 0.7,
      renderCell: ({ row }) => (
        <Chip
          size='small'
          label={`${row?.status}`}
          color={`${teamsStatusBadgeColor(row?.status)}`}
          className='capitalize'
        />
      ),
    },
    {
      field: 'created',
      headerName: 'Created At',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row: { created } }) => <>{getFormattedDate(created * 1000)}</>,
    },
  ];

  const handleTeamModelClose = () => setAddTeamModel(false);
  const handleTeamModelOpen = () => setAddTeamModel(true);

  return (
    <>
      <Box m='20px'>
        <div className='flex justify-between'>
          <div className='flex gap-3'>
            <Header title='Teams' />
            <FiltersCreator
              className='ms-3'
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              fields={fields}
              wrapperClass={wrapperClass}
              initialValues={{ query: initialQuery }}
            />
          </div>
          <div>
            <Button
              variant='contained'
              color='secondary'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
              startIcon={<AddIcon />}
              onClick={handleTeamModelOpen}
            >
              Add Team
            </Button>
          </div>
        </div>

        <Table columns={columns} dataURL={teamsListURL} filter={filterObj} />
      </Box>

      <AddTeamsModel handleModelClose={handleTeamModelClose} openModel={addTeamModel} />
    </>
  );
};

export default TeamsTable;
