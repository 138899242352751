import React, { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { SuspenseLoader } from 'components';
import { useAuthUser } from 'utils/context/AuthUserContext';

import ChatSideBar from './global/ChatSideBar';

const ChatLayout = () => {
  const { isAuth, accessAccount } = useAuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth && !accessAccount) {
      navigate('/accounts', { replace: true });
    }
  }, [accessAccount, isAuth, navigate]);

  return (
    <div className='overflow-hidden app'>
      <ChatSideBar />
      <main
        className='w-full overflow-auto'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Suspense fallback={<SuspenseLoader />}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};

export default ChatLayout;
