import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { HFUserSelect, Toast } from 'components';
import Axios from 'services/api/Config';

import { tokens } from 'theme';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { addTeamUsersURL } from 'services/api/routes/teams';

const addUserSchema = Yup.object().shape({
  users: Yup.array().min(1, 'Users are required').required('Users are required'),
});

const AddUsersModel = (props) => {
  const { addModel = false, handleModelClose, handleTableRefresh } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let { teamId } = useParams();
  const { accessAccount } = useAuthUser();

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = { users: [] };

  const {
    reset,
    handleSubmit,
    control: addUsersControl,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(addUserSchema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    Axios({
      ...addTeamUsersURL(teamId),
      data: data?.users,
      params: { account_id: accessAccount?.id },
    })
      .then((res) => {
        reset(initialValues);
        handleTableRefresh((prev) => !prev);
        handleModelClose();
      })
      .catch((err) => {
        Toast.error(err.data.detail.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    reset(initialValues);
    handleModelClose();
  };

  return (
    <>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={addModel}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: colors.primary[500],
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id='alert-dialog-title'>Add Users</DialogTitle>
          <DialogContent>
            <div className='my-3'>
              <HFUserSelect
                id='users'
                name='users'
                label='Users'
                control={addUsersControl}
                selectPluginConfig={{
                  isMulti: true,
                  isClearable: true,
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant='outlined'
              color='error'
              sx={{
                textTransform: 'none',
                color: colors.grey[100],
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              color='secondary'
              variant='contained'
              loading={isLoading}
              loadingPosition='center'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
            >
              Add Users
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

AddUsersModel.propTypes = {
  handleModelClose: PropTypes.func,
  handleTableRefresh: PropTypes.func,
  addModel: PropTypes.bool,
};

export default AddUsersModel;
