import React, { useRef, useState } from 'react';

import { Button, Fab, IconButton, TextField, useTheme } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Breadcrumb from 'layout/global/Breadcrumb';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { tokens } from 'theme';
import { Link } from 'react-router-dom';

const SELECT_TYPE = [
  { name: 'Rule Builder', value: 'rule_builder' },
  { name: 'Fraud Analyst', value: 'fraud_analyst' },
  { name: 'Data Analytics', value: 'data_analytics' },
];

const LINK_VALUES = {
  rule: 'rules',
  team: 'account/teams',
};

const AIChat = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let chatRef = useRef(null);
  const { accessAccount, authUser } = useAuthUser();
  const [selectedType, setSelectedType] = useState(SELECT_TYPE[0]);
  const [chatInput, setChatInput] = useState('');

  const onSubmit = (e) => {
    e?.preventDefault();
    console.log('ska chatInput', chatInput);
    setChatInput('');
  };

  const USER_CHAT = [
    {
      id: 1,
      name: 'ai_bot',
      chat: {
        text: 'How can i help you with your Fraud Rules?',
        haveLink: false,
      },
    },
    {
      id: 2,
      name: 'user',
      chat: {
        text: 'I want to Block IP Address originated from Russia.',
        haveLink: false,
      },
    },
    {
      id: 3,
      name: 'ai_bot',
      chat: {
        text: 'Thinking...',
        haveLink: false,
      },
    },
    {
      id: 4,
      name: 'ai_bot',
      chat: {
        text: 'You got it! Here`s a link to "Block IP Address from Russia" rule',
        haveLink: true,
        link: { type: 'rule', id: '3' },
      },
    },
    {
      id: 1,
      name: 'ai_bot',
      chat: {
        text: 'How can i help you with your Fraud Rules?',
        haveLink: false,
      },
    },
    {
      id: 2,
      name: 'user',
      chat: {
        text: 'I want to Block IP Address originated from Russia.',
        haveLink: false,
      },
    },
    {
      id: 3,
      name: 'ai_bot',
      chat: {
        text: 'Thinking...',
        haveLink: false,
      },
    },
    {
      id: 4,
      name: 'ai_bot',
      chat: {
        text: 'You got it! Here`s a link to "Block IP Address from Russia" rule',
        haveLink: true,
        link: { type: 'rule', id: '3' },
      },
    },
    {
      id: 1,
      name: 'ai_bot',
      chat: {
        text: 'How can i help you with your Fraud Rules?',
        haveLink: false,
      },
    },
    {
      id: 2,
      name: 'user',
      chat: {
        text: 'I want to Block IP Address originated from Russia.',
        haveLink: false,
      },
    },
    {
      id: 3,
      name: 'ai_bot',
      chat: {
        text: 'Thinking...',
        haveLink: false,
      },
    },
    {
      id: 4,
      name: 'ai_bot',
      chat: {
        text: 'You got it! Here`s a link to "Block IP Address from Russia" rule',
        haveLink: true,
        link: { type: 'rule', id: '3' },
      },
    },
    {
      id: 1,
      name: 'ai_bot',
      chat: {
        text: 'How can i help you with your Fraud Rules?',
        haveLink: false,
      },
    },
    {
      id: 2,
      name: 'user',
      chat: {
        text: 'I want to Block IP Address originated from Russia.',
        haveLink: false,
      },
    },
    {
      id: 3,
      name: 'ai_bot',
      chat: {
        text: 'Thinking...',
        haveLink: false,
      },
    },
    {
      id: 4,
      name: 'ai_bot',
      chat: {
        text: 'You got it! Here`s a link to "Block IP Address from Russia" rule',
        haveLink: true,
        link: { type: 'rule', id: '3' },
      },
    },
    {
      id: 1,
      name: 'ai_bot',
      chat: {
        text: 'How can i help you with your Fraud Rules?',
        haveLink: false,
      },
    },
    {
      id: 2,
      name: 'user',
      chat: {
        text: 'I want to Block IP Address originated from Russia.',
        haveLink: false,
      },
    },
    {
      id: 3,
      name: 'ai_bot',
      chat: {
        text: 'Thinking...',
        haveLink: false,
      },
    },
    {
      id: 4,
      name: 'ai_bot',
      chat: {
        text: 'You got it! Here`s a link to "Block IP Address from Russia" rule',
        haveLink: true,
        link: { type: 'rule', id: '3' },
      },
    },
  ];

  const generateLinks = (obj) => {
    const { type, id } = obj?.link;
    return (
      <Link to={`/${LINK_VALUES[type]}/${id}`} className='font-bold underline'>
        :link to {type}
      </Link>
    );
  };

  const generateChatText = (val) => {
    const {
      name,
      chat: { text: chatText, haveLink, ...chatRest },
    } = val;

    const userName = name === 'ai_bot' ? `${selectedType?.name} Agent` : authUser?.email;

    return (
      <>
        <span className='font-bold'>{userName}: </span>
        <span style={{ color: colors.grey[200] }}>{chatText} </span>
        {haveLink && generateLinks(chatRest)}
      </>
    );
  };
  const handleScrollToBottom = () => {
    chatRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  return (
    <>
      <div className='flex justify-between p-4 pb-0'>
        <Breadcrumb />
        <span className='text-2xl font-bold'>{selectedType?.name}</span>
        <div className='flex'>
          <div className='self-center text-base me-3'>{accessAccount?.name}</div>
        </div>
      </div>
      <div className='h-full m-5'>
        <Fab
          color='primary'
          size='small'
          onClick={handleScrollToBottom}
          className='hover:!bg-[#4cceac14] hover:border-[#4cceac]'
          sx={{
            position: 'absolute',
            bottom: 130,
            right: 170,
            color: colors.grey[100],
            backgroundColor: colors.primary[600],
            border: `1px solid ${colors.grey[100]}`,
          }}
        >
          <ArrowDownwardIcon />
        </Fab>
        <div className='flex flex-col justify-between h-full'>
          <div
            className='mx-32 overflow-y-auto '
            style={{ maxHeight: `calc(100vh - 190px)` }}
          >
            <div ref={chatRef}>
              {USER_CHAT.map((val, index) => (
                <div
                  key={`${val?.id} ${index}`}
                  className='mb-3 text-base'
                  style={{ color: colors.grey[100] }}
                >
                  {generateChatText(val)}
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className='flex justify-center gap-3 my-3'>
              {SELECT_TYPE.map((obj) => {
                return (
                  <Button
                    key={obj?.value}
                    variant='outlined'
                    color='secondary'
                    size='large'
                    disabled={selectedType?.value === obj?.value}
                    onClick={() => setSelectedType(obj)}
                    sx={{
                      textTransform: 'none',
                      color: colors.grey[100],
                      backgroundColor: colors.primary[600],
                      border: `1px solid ${colors.grey[100]}`,
                    }}
                  >
                    {obj?.name}
                  </Button>
                );
              })}
            </div>
            <div className='flex justify-center'>
              <form className='max-w-[800px] w-full'>
                <TextField
                  fullWidth
                  size='small'
                  value={chatInput}
                  onChange={(val) => setChatInput(val?.target?.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton edge='end' type='submit' onClick={onSubmit}>
                        <SendIcon />
                      </IconButton>
                    ),
                  }}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIChat;
