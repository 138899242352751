import * as Blockly from 'blockly';
import { FieldDataType } from 'blockly/fields/FieldDataType';
import { AddIcon } from 'blockly/icons/AddIcon';
import { RemoveIcon } from 'blockly/icons/RemoveIcon';

Blockly.Blocks['bd_operator_agg_function'] = {
  init: function () {
    this.appendDummyInput('D0')
      .setAlign(Blockly.inputs.Align.RIGHT)
      .appendField('Get')
      .appendField(
        new Blockly.FieldDropdown([
          ['Sum', 'sum'],
          ['Count', 'count'],
          ['Count (Unique)', 'count_unique'],
          ['Average', 'average'],
          ['Median', 'median'],
          ['Max', 'max'],
          ['Min', 'min'],
          ['Standard Deviation', 'standard_deviation'],
          ['First', 'first'],
          ['Last', 'last'],
        ]),
        'F0'
      )
      .appendField('of')
      .appendField(new FieldDataType(), 'F1')
      .appendField('.', 'D1')
      .appendField(new Blockly.FieldDropdown([[' ', ' ']]), 'F2');
    this.appendDummyInput('D1')
      .setAlign(Blockly.inputs.Align.RIGHT)
      .appendField('Group by')
      .appendField(new Blockly.FieldTextInput('Object'), 'F3')
      .appendField('.', 'D2')
      .appendField(new Blockly.FieldDropdown([[' ', ' ']]), 'F4');
    this.appendDummyInput('D2')
      .setAlign(Blockly.inputs.Align.RIGHT)
      .appendField('Time Tracked Via')
      .appendField(new Blockly.FieldTextInput('Object'), 'F5')
      .appendField('.', 'D3')
      .appendField(new Blockly.FieldDropdown([[' ', ' ']]), 'F6');
    this.appendValueInput('V0')
      .setCheck('filters')
      .setAlign(Blockly.inputs.Align.RIGHT)
      .appendField('Filters');
    this.setOutput(true, 'number');
    this.setColour(65);
    this.setTooltip('');
    this.setHelpUrl('');
    this.getField('F1').setValidator(this.validateF1.bind(this));
    this.getField('F3').setEnabled(false);
    this.getField('F5').setEnabled(false);
  },
  validateF1: function (value) {
    const options = value.SFO || [];
    let timeOptions = value.SFO || [];

    timeOptions = timeOptions.filter((v) => {
      return v[1].split('**')[1] === 'datetime';
    });

    this.getInput('D0').removeField('D1', true);
    this.getInput('D1').removeField('D2', true);
    this.getInput('D2').removeField('D3', true);

    this.getInput('D0').removeField('F2', true);
    this.getInput('D1').removeField('F4', true);
    this.getInput('D2').removeField('F6', true);

    this.setFieldValue(value.V, 'F3');
    this.setFieldValue(value.V, 'F5');

    if (options.length === 0) options.push(['', '']);
    if (timeOptions.length === 0) timeOptions.push(['', '']);

    this.getInput('D0').appendField('.', 'D1');
    this.getInput('D1').appendField('.', 'D2');
    this.getInput('D2').appendField('.', 'D3');

    this.getInput('D0').appendField(new Blockly.FieldDropdown(options), 'F2');
    this.getInput('D1').appendField(new Blockly.FieldDropdown(options), 'F4');
    this.getInput('D2').appendField(new Blockly.FieldDropdown(timeOptions), 'F6');
    return value;
  },
};

Blockly.Blocks['bd_operator_comparison'] = {
  init: function () {
    this.appendValueInput('V0').setCheck(null);
    this.appendDummyInput()
      .setAlign(Blockly.inputs.Align.CENTRE)
      .appendField(
        new Blockly.FieldDropdown([
          ['<', 'less_than'],
          ['>', 'greater_than'],
          ['≤', 'less_than_or_equal'],
          ['≥', 'greater_than_or_equal'],
          ['=', 'equal'],
          ['≠', 'not_equal'],
          ['Contains', 'contains'],
          ['Contains (Case Sensitive)', 'contains_case_sensitive'],
        ]),
        'F0'
      );
    this.appendValueInput('V1').setCheck(null);
    this.setInputsInline(true);
    this.setOutput(true, 'boolean');
    this.setColour(65);
    this.setTooltip('');
    this.setHelpUrl('');
    this.setOnChange(this.validateInputs.bind(this));
  },
  validateInputs: function (e) {
    if (this.isInsertionMarker()) return;
    const input1Block = this.getInputTargetBlock('V0');
    const input2Block = this.getInputTargetBlock('V1');
    if (input1Block && !input2Block) {
      const type = input1Block.outputConnection.getCheck();
      this.getInput('V0').setCheck(type);
      this.getInput('V1').setCheck(type);
    } else if (!input1Block && input2Block) {
      const type = input2Block.outputConnection.getCheck();
      this.getInput('V0').setCheck(type);
      this.getInput('V1').setCheck(type);
    } else if (!input1Block && !input2Block) {
      this.getInput('V0').setCheck(null);
      this.getInput('V1').setCheck(null);
    }
  },
};

Blockly.Blocks['bd_operator_arithmetic'] = {
  init: function () {
    this.appendValueInput('V0').setCheck('number');
    this.appendDummyInput()
      .setAlign(Blockly.inputs.Align.CENTRE)
      .appendField(
        new Blockly.FieldDropdown([
          ['+', 'add'],
          ['-', 'subtract'],
          ['×', 'multiply'],
          ['÷', 'divide'],
          ['%', 'mode'],
        ]),
        'F0'
      );
    this.appendValueInput('V1').setCheck('number');
    this.setInputsInline(true);
    this.setOutput(true, 'number');
    this.setColour(65);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['bd_operator_logic'] = {
  init: function () {
    this.inputCount = 0;
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ['And', 'and'],
        ['Or', 'or'],
        ['All', 'all'],
        ['Any', 'any'],
        ['None', 'none'],
      ]),
      'F0'
    );
    this.appendValueInput('V0').setCheck('boolean');
    this.appendValueInput('V1').setCheck('boolean');
    this.setInputsInline(false);
    this.setOutput(true, 'boolean');
    this.setColour(65);
    this.setTooltip('');
    this.setHelpUrl('');
    this.addIcon(new AddIcon(this, this.onAddInput.bind(this)));
    this.addIcon(new RemoveIcon(this, this.onRemoveInput.bind(this)));
  },
  saveExtraState: function () {
    return { inputCount: this.inputCount };
  },
  loadExtraState: function (state) {
    this.inputCount = state.inputCount;
    this.onUpdateShape();
  },
  onAddInput: function () {
    this.inputCount += 1;
    this.onUpdateShape();
  },
  onRemoveInput: function () {
    if (this.inputCount === 0) return;
    this.inputCount -= 1;
    this.onUpdateShape();
  },
  onUpdateShape: function () {
    if (this.isInsertionMarker()) return;
    Blockly.Events.setGroup(true);
    const inputBlocks = [];
    let i = 0;
    while (this.getInput(`EV${i}`)) {
      const block = this.getInputTargetBlock(`EV${i}`);
      block?.unplug(false);
      inputBlocks.push(block);
      this.removeInput(`EV${i}`, true);
      i += 1;
    }
    for (let j = 0; j < this.inputCount; j++) {
      const input = this.appendValueInput(`EV${j}`).setCheck('boolean');
      if (inputBlocks[j]) {
        input.connection.connect(inputBlocks[j].outputConnection);
      }
    }
    Blockly.Events.setGroup(false);
  },
};

Blockly.Blocks['bd_operator_at_least'] = {
  init: function () {
    this.inputCount = 0;
    this.appendDummyInput()
      .appendField('At Least')
      .appendField(new Blockly.FieldNumber(0, 0, Infinity, 1), 'F0')
      .appendField('Is')
      .appendField(
        new Blockly.FieldDropdown([
          ['True', 'true'],
          ['False', 'false'],
        ]),
        'F1'
      );
    this.appendValueInput('V0').setCheck('boolean');
    this.appendValueInput('V1').setCheck('boolean');
    this.setInputsInline(false);
    this.setOutput(true, 'boolean');
    this.setColour(65);
    this.setTooltip('');
    this.setHelpUrl('');
    this.addIcon(new AddIcon(this, this.onAddInput.bind(this)));
    this.addIcon(new RemoveIcon(this, this.onRemoveInput.bind(this)));
  },
  saveExtraState: function () {
    return { inputCount: this.inputCount };
  },
  loadExtraState: function (state) {
    this.inputCount = state.inputCount;
    this.onUpdateShape();
  },
  onAddInput: function () {
    this.inputCount += 1;
    this.onUpdateShape();
  },
  onRemoveInput: function () {
    if (this.inputCount === 0) return;
    this.inputCount -= 1;
    this.onUpdateShape();
  },
  onUpdateShape: function () {
    if (this.isInsertionMarker()) return;
    Blockly.Events.setGroup(true);
    const inputBlocks = [];
    let i = 0;
    while (this.getInput(`EV${i}`)) {
      const block = this.getInputTargetBlock(`EV${i}`);
      block?.unplug(false);
      inputBlocks.push(block);
      this.removeInput(`EV${i}`, true);
      i += 1;
    }
    for (let j = 0; j < this.inputCount; j++) {
      const input = this.appendValueInput(`EV${j}`).setCheck('boolean');
      if (inputBlocks[j]) {
        input.connection.connect(inputBlocks[j].outputConnection);
      }
    }
    Blockly.Events.setGroup(false);
  },
};

Blockly.Blocks['bd_operator_all_same'] = {
  init: function () {
    this.appendValueInput('V0').setCheck(null).appendField('Are all values same ?');
    this.setInputsInline(true);
    this.setOutput(true, 'boolean');
    this.setColour(65);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['bd_operator_within'] = {
  init: function () {
    this.appendValueInput('V0').setCheck(null);
    this.appendDummyInput('D0')
      .appendField('Is Within')
      .appendField(
        new Blockly.FieldDropdown(
          [
            ['Amount', 'amount'],
            ['Percent', 'percent'],
          ],
          this.validateF0.bind(this)
        ),
        'F0'
      )
      .appendField(new Blockly.FieldNumber(0), 'F1');
    this.setInputsInline(true);
    this.setOutput(true, 'boolean');
    this.setColour(65);
    this.setTooltip('');
    this.setHelpUrl('');
  },
  validateF0: function (value) {
    this.getInput('D0').removeField('F2', true);
    if (value === 'percent') {
      this.getInput('D0').appendField(new Blockly.FieldLabelSerializable('%'), 'F2');
    }
    return value;
  },
};

Blockly.Blocks['bd_operator_between'] = {
  init: function () {
    this.appendValueInput('V0').setCheck(null);
    this.appendDummyInput().appendField('Is Between');
    this.appendValueInput('V1').setCheck('number');
    this.appendDummyInput().appendField('and');
    this.appendValueInput('V2').setCheck('number');
    this.setInputsInline(true);
    this.setOutput(true, 'boolean');
    this.setColour(65);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};
