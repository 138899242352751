export const VALUES_TOOLBOX = {
  kind: 'category',
  name: 'Values',
  visible: true,
  contents: [
    { kind: 'block', type: 'bd_value_number' },
    { kind: 'block', type: 'bd_value_string' },
    { kind: 'block', type: 'bd_value_boolean' },
    { kind: 'block', type: 'bd_value_percent' },
    { kind: 'block', type: 'bd_value_time' },
    { kind: 'block', type: 'bd_value_field' },
    {
      kind: 'block',
      type: 'bd_operator_comparison',
      fields: {
        F0: 'contains',
      },
      inputs: {
        V0: { block: { type: 'bd_value_list' } },
      },
    },
    { kind: 'block', type: 'bd_value_list' },
    { kind: 'block', type: 'bd_value_filters' },
  ],
};
