export const RULE_STATUS_TYPE = {
  DRAFT: 'draft',
  DEACTIVATED: 'deactivated',
  ACTIVE: 'active',
  TESTING: 'testing',
};

export const ruleStatusBadgeColor = (status) => {
  switch (status) {
    case RULE_STATUS_TYPE.DRAFT:
      return 'secondary';
    case RULE_STATUS_TYPE.DEACTIVATED:
      return 'warning';
    case RULE_STATUS_TYPE.ACTIVE:
      return 'success';
    default:
      return 'default';
  }
};
