import React from 'react';

import { Button, Typography, useTheme } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Table } from 'components';
import { tokens } from 'theme';

import { getFormattedDate } from 'utils/common';

const ChatsTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      minWidth: 185,
      flex: 1,
      renderCell: ({ row: { created_at } }) => <>{getFormattedDate(created_at * 1000)}</>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'right',
      headerAlign: 'right',
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <Button
              variant='contained'
              color='primary'
              sx={{
                textTransform: 'none',
                color: colors.grey[100],
                backgroundColor: colors.blueAccent[700],
              }}
              startIcon={<VisibilityIcon />}
            >
              Open
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className='flex gap-3 !mt-8 mb-1'>
        <Typography
          variant='h3'
          gutterBottom
          color={colors.grey[100]}
          className='capitalize '
        >
          Chats
        </Typography>
      </div>

      <Table
        columns={columns}
        tableDATA={[
          {
            id: 1,
            title: 'Bulk SAR filing',
            created_at: 1694999496,
          },
          {
            id: 2,
            title: 'Run KYC Check',
            created_at: 1694999496,
          },
          {
            id: 3,
            title: 'Run KYB Check',
            created_at: 1694999496,
          },
        ]}
        // dataURL={tableDataURL}
        // filter={filterObj}
      />
    </>
  );
};

export default ChatsTable;
