import React, { Suspense, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Sidebar from './global/Sidebar';
import Topbar from './global/Topbar';

import { useAuthUser } from 'utils/context/AuthUserContext';
import { SuspenseLoader } from 'components';

const DefaultLayout = () => {
  const [isSidebar, setIsSidebar] = useState(true);

  const { isAuth, accessAccount } = useAuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth && !accessAccount) {
      navigate('/accounts', { replace: true });
    }
  }, [accessAccount, isAuth, navigate]);

  return (
    <div className='overflow-hidden app'>
      <Sidebar isSidebar={isSidebar} />
      <main
        className='w-full overflow-auto'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Topbar setIsSidebar={setIsSidebar} />
        <Suspense fallback={<SuspenseLoader />}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};

export default DefaultLayout;
