export const OPERATORS_TOOLBOX = {
  kind: 'category',
  name: 'Operations',
  visible: true,
  contents: [
    { kind: 'block', type: 'bd_operator_comparison' },
    { kind: 'block', type: 'bd_operator_arithmetic' },
    { kind: 'block', type: 'bd_operator_logic' },
    { kind: 'block', type: 'bd_operator_at_least' },
    {
      kind: 'block',
      type: 'bd_operator_all_same',
      inputs: {
        V0: { block: { type: 'bd_value_field' } },
      },
    },
    {
      kind: 'block',
      type: 'bd_operator_within',
      inputs: {
        V0: { block: { type: 'bd_value_field' } },
      },
    },
    {
      kind: 'block',
      type: 'bd_operator_between',
      inputs: {
        V0: { block: { type: 'bd_value_field' } },
      },
    },
    { kind: 'block', type: 'bd_operator_agg_function' },
  ],
};
