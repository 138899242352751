import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const routesArray = [
  { path: '/rules', name: 'Rules' },
  {
    path: '/rules/:ruleId',
    name: 'Rule Details',
    isID: true,
    position: 2,
  },
  {
    path: '/rules/:ruleId/logic',
    name: 'Rule Logic',
    isID: true,
    position: 2,
    isMidVal: true,
  },
  { path: '/lists', name: 'Lists' },
  {
    path: '/lists/:listId',
    name: 'List Details',
    isID: true,
    position: 2,
  },
  {
    path: '/list/:listId/entries',
    name: 'List Entries',
    isID: true,
    position: 2,
    isMidVal: true,
  },
  { path: '/user', name: 'My User' },
  { path: '/account', name: 'Account', isPath: true },
  { path: '/account/api_key', name: 'API Key' },
  { path: '/account/users', name: 'Users' },
  {
    path: '/account/users/:userID',
    name: 'User Details',
    isID: true,
    position: 3,
  },
  { path: '/account/teams', name: 'Teams' },
  {
    path: '/account/teams/:teamID',
    name: 'Team Details',
    isID: true,
    position: 3,
  },
  { path: '/ai_fraud_analysts', name: 'AI Fraud Analysts' },
  {
    path: '/ai_fraud_analysts/:analystsId',
    name: 'AI Fraud Analysts Details',
    isID: true,
    position: 2,
  },
  { path: '/data', name: 'Data' },
  {
    path: '/data/:typeID',
    name: 'Data Types',
    isID: true,
    position: 2,
  },
];

const getRouteName = (pathname, routes) => {
  const pathSplit = pathname.split('/');
  const currentRoute = routes.find((route) => {
    const routeSplit = route.path.split('/');
    if (route.path === pathname) {
      return true;
    }

    if (
      pathSplit.length === routeSplit.length &&
      route?.isID &&
      pathSplit[route?.position - 1] === routeSplit[route?.position - 1]
    ) {
      if (!route?.isMidVal) {
        route.name = pathSplit[route?.position];
      }
      return true;
    }
    return false;
  });
  return currentRoute ? currentRoute : false;
};

const getBreadcrumbs = (location) => {
  const breadcrumbs = [];
  const locationParts = location.split('/');

  locationParts.reduce((prev, curr, index, array) => {
    const currentPathname = `${prev}/${curr}`;
    const currentRoute = getRouteName(currentPathname, routesArray);

    if (currentRoute) {
      breadcrumbs.push({
        pathname: currentPathname,
        name: currentRoute.name,
        active: index + 1 === array.length,
        isID: currentRoute.isID,
        isPath: currentRoute.isPath ?? false,
      });
    }
    return currentPathname;
  });

  return breadcrumbs;
};

const Breadcrumb = () => {
  const { pathname } = useLocation();
  const currentLocation = pathname;
  const breadcrumbs = getBreadcrumbs(currentLocation);
  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      separator={<NavigateNextIcon fontSize='small' />}
    >
      <Link to='/dashboard' className='text-base ms-2'>
        Home
      </Link>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <div key={index}>
            {breadcrumb?.active || breadcrumb?.isPath ? (
              <span className='text-base'>{breadcrumb.name}</span>
            ) : (
              <Link className='text-base' to={breadcrumb.pathname}>
                {breadcrumb.name}
              </Link>
            )}
          </div>
        );
      })}
    </Breadcrumbs>
  );
};

export default React.memo(Breadcrumb);
