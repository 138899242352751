import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

import { Toast } from 'components';

import Emitter from 'utils/emitter';
import Axios from 'services/api/Config';
import { clearLocal } from 'utils/common';
import { EMITTER_CONST } from 'constants/Emitter.constants';

import { currentUserURL } from 'services/api/routes/users';
import { getUsersPermissionByIdURL } from 'services/api/routes/users';

const initialContextState = {
  user: null,
  authUser: null,
  permissions: null,
  accessAccount: localStorage.getItem('user_account')
    ? JSON.parse(localStorage.getItem('user_account'))
    : null,
  isAuth: false,
  isFetching: true,
  fetchNewUserData: () => {},
  handleLogout: () => {},
  fetchNewPermissions: () => {},
};

const AuthUserContext = createContext(initialContextState);

const useAuthUser = () => {
  return useContext(AuthUserContext);
};

const AuthUserProvider = ({ children }) => {
  const { isAuthenticated, logout, user, getAccessTokenSilently } = useAuth0();

  const [accessAccount, setAccessAccount] = useState(initialContextState.accessAccount);
  const [permissions, setPermissions] = useState(initialContextState.permissions);
  const [isFetching, setIsFetching] = useState(initialContextState.isFetching);
  const [authUser, setAuthUser] = useState(initialContextState.authUser);

  const isAuth = useMemo(() => !!isAuthenticated, [isAuthenticated]);

  const handleAccessAccount = (val) => {
    // do not remove below line of code, blockly is reading user id using this.
    localStorage.setItem('user_account', JSON.stringify(val));
    setAccessAccount(val);
  };

  const handleLogout = useCallback(() => {
    clearLocal();
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const fetchPermissions = useCallback(
    async (userID, accountID) => {
      setIsFetching(true);
      try {
        if (!permissions) {
          const permissionRes = await Axios({
            ...getUsersPermissionByIdURL(userID),
            params: { account_id: accountID },
          });
          console.log('ska permissionRes', permissionRes.data);
          // const finalPermissionObj = {};
          // 		Object.keys(permissionRes.data.data).forEach((per) => {
          // 			finalPermissionObj[per.replace("-", "_")] =
          // 				permissionRes?.data?.data[per];
          // 		});
          // setPermissions(finalPermissionObj);
        }
      } catch (err) {
        handleLogout();
        Toast.error('Network Error!!');
      } finally {
        setIsFetching(false);
      }
    },
    [handleLogout, permissions]
  );

  const fetchUserData = useCallback(async () => {
    setIsFetching(true);
    try {
      if (!isAuth) {
        setAuthUser(initialContextState.authUser);
      }
      if (isAuth && !authUser) {
        const authToken = await getAccessTokenSilently();
        localStorage.setItem('auth_token', authToken);

        const res = await Axios({ ...currentUserURL });
        if (accessAccount?.id) {
          await fetchPermissions(res.data?.id, accessAccount?.id);
        }
        setAuthUser(res.data);
      }
    } catch (err) {
      handleLogout();
      Toast.error('No User Found');
      localStorage.setItem('login_warning', true);
    } finally {
      setIsFetching(false);
    }
  }, [
    accessAccount?.id,
    authUser,
    fetchPermissions,
    getAccessTokenSilently,
    handleLogout,
    isAuth,
  ]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const fetchNewUserData = useCallback(() => {
    setAuthUser(initialContextState.authUser);
    fetchUserData();
  }, [fetchUserData]);

  const fetchNewPermissions = useCallback(
    (accountID) => {
      setPermissions(initialContextState.permissions);
      fetchPermissions(authUser?.id, accountID);
    },
    [authUser?.id, fetchPermissions]
  );

  useEffect(() => {
    Emitter.on(EMITTER_CONST.FORCE_LOGOUT, handleLogout);
    return () => {
      Emitter.off(EMITTER_CONST.FORCE_LOGOUT, handleLogout);
    };
  });

  return (
    <AuthUserContext.Provider
      value={{
        user,
        isAuth,
        authUser,
        isFetching,
        handleLogout,
        accessAccount,
        fetchNewUserData,
        handleAccessAccount,
        permissions,
        fetchNewPermissions,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

AuthUserProvider.propTypes = {
  children: PropTypes.node,
};

export { AuthUserProvider, useAuthUser };
