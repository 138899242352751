import PropTypes from 'prop-types';
import { Typography, useTheme } from '@mui/material';

import { tokens } from 'theme';

const Header = (props) => {
  const { title, subtitle, className = '' } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div className={className}>
      <Typography
        variant='h2'
        color={colors.grey[100]}
        fontWeight='bold'
        sx={{ m: '0 0 5px 0' }}
      >
        {title}
      </Typography>
      {subtitle ? (
        <Typography variant='h5' color={colors.grey[100]}>
          {subtitle}
        </Typography>
      ) : null}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default Header;
