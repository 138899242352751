import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import Axios from 'services/api/Config';
import { FiltersCreator, HFTextInput, Header, MenuButton, Table } from 'components';

import { tokens } from 'theme';
import { useAuthUser } from 'utils/context/AuthUserContext';

import { ruleStatusBadgeColor } from 'constants/Rules.constants';
import { listTypeBadgeColor } from 'constants/List.constants';
import { getFormattedDate } from 'utils/common';
import {
  getListEntriesURL,
  getListsByIdURL,
  updateListsURL,
} from 'services/api/routes/lists';
import AddEntriesModel from './components/AddEntriesModel';
import { CRUD_FILERS_TYPE } from 'constants/Filter.constants';
import DeleteAlertModel from './components/DeleteAlertModel';

const listEditSchema = Yup.object().shape({
  description: Yup.string().trim().required('Description is required'),
  name: Yup.string().trim().required('Name is required'),
});

const filterJSON = {
  initialQuery: { statuses: [] },
  wrapperClass: 'gap-3 grid grid-cols-6',
  fields: [
    {
      type: CRUD_FILERS_TYPE.SEARCH,
      name: 'string_value',
      label: 'Value',
      placeholder: 'Value',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.SELECT_USER,
      name: 'author_ids',
      label: 'Activity By',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      selectPluginConfig: {
        isClearable: true,
        isMulti: true,
      },
    },
    {
      type: CRUD_FILERS_TYPE.DATE_TIME,
      name: 'created_start',
      label: 'From',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.DATE_TIME,
      name: 'created_end',
      label: 'To',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      dateConfig: {
        dependent: 'created_start',
      },
    },
  ],
};

const ListDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accessAccount } = useAuthUser();

  const { initialQuery: filterQuery, fields = [], wrapperClass = '' } = filterJSON;

  const initialQuery = { ...filterQuery };
  const [filterObj, setFilterObj] = useState({ query: initialQuery });

  let { listId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [listDetails, setListDetails] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [addEntriesModel, setAddEntriesModel] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(false);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [alertModel, setAlertModel] = useState(false);

  const initialValues = {
    description: '',
    name: '',
  };

  const buttonStyle = {
    textTransform: 'none',
    color: colors.grey[100],
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: colors.blueAccent[700],
  };

  const handleShowAlert = () => setAlertModel(true);
  const handleAlertClose = () => setAlertModel(false);

  const handleEntriesModelClose = () => setAddEntriesModel(false);
  const handleEntriesModelOpen = () => setAddEntriesModel(true);

  const {
    handleSubmit,
    setValue,
    control: listControl,
    reset,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(listEditSchema),
  });

  const fetch = useCallback(() => {
    setIsLoading(true);
    Axios({
      ...getListsByIdURL(listId),
      params: { account_id: accessAccount?.id },
    })
      .then((response) => {
        const res = response.data;
        setValue('description', res.description);
        setValue('name', res.name);
        setListDetails(res);
      })
      .catch(() => navigate('/lists'))
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessAccount?.id, navigate, listId, setValue]);

  useEffect(() => {
    if (listId) {
      fetch();
    } else {
      navigate('/lists');
    }
  }, [listId, navigate, fetch]);

  const handleListEdit = (val) => {
    setIsEdit(val);
  };

  const onSubmit = (data) => {
    setIsSubmitLoading(true);
    Axios({
      ...updateListsURL(listId),
      params: { account_id: accessAccount?.id },
      data: data,
    })
      .then((res) => fetch())
      .finally(() => {
        setIsSubmitLoading(false);
        handleListEdit(false);
      });
  };

  const handleCancelEdit = () => {
    reset({ description: listDetails?.description, name: listDetails?.name });
    handleListEdit(false);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
      renderCell: ({ row }) => <>{row?.id}</>,
    },
    {
      field: 'value',
      headerName: 'Value',
      minWidth: 120,
      flex: 1,
      // sortable: false,
      renderCell: ({ row }) => <>{row?.value}</>,
    },
    {
      field: 'created',
      headerName: 'Created At',
      minWidth: 120,
      flex: 0.8,
      renderCell: ({ row: { created } }) => <>{getFormattedDate(created * 1000)}</>,
    },
  ];

  const tableDataURL = useMemo(() => ({ ...getListEntriesURL(listId) }), [listId]);

  return (
    <>
      <Box px={1} display={'flex'} flexDirection={'column'} flex={1} m={2}>
        {isLoading ? (
          <div className='w-full my-4 text-center'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex justify-between'>
                <div className='flex items-center gap-3 mb-3'>
                  {isEdit ? (
                    <div className='w-60'>
                      <HFTextInput name='name' label='List Name' control={listControl} />
                    </div>
                  ) : (
                    <Header title={listDetails?.name} />
                  )}
                  <Chip
                    size='medium'
                    label={`Type: ${listDetails?.list_type}`}
                    color={`${listTypeBadgeColor(listDetails?.list_type)}`}
                    className='text-2xl font-bold capitalize'
                  />
                  <Chip
                    size='medium'
                    label={`${listDetails?.status}`}
                    color={`${ruleStatusBadgeColor(listDetails?.status)}`}
                    className='text-2xl font-bold capitalize'
                  />
                </div>
                <div className='flex gap-3'>
                  {isEdit ? (
                    <>
                      <div>
                        <Button
                          onClick={handleCancelEdit}
                          variant='outlined'
                          color='error'
                          className='!me-3'
                          size='large'
                          sx={buttonStyle}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          type='submit'
                          variant='contained'
                          color='secondary'
                          size='large'
                          loading={isSubmitLoading}
                          loadingPosition='center'
                          // onClick={handleSubmit(onSubmit)}
                          sx={activeButtonStyle}
                        >
                          Save
                        </LoadingButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <MenuButton icon={<MenuIcon />}>
                        <MenuItem onClick={() => handleListEdit(true)}>
                          <ListItemIcon>
                            <EditIcon fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>Edit</ListItemText>
                        </MenuItem>
                      </MenuButton>
                    </>
                  )}
                </div>
              </div>

              {isEdit ? (
                <div className='mt-3'>
                  <HFTextInput
                    name='description'
                    label='List Description'
                    multiline
                    control={listControl}
                    classes={{ wrapper: 'mb-7' }}
                  />
                </div>
              ) : (
                <>
                  <Typography
                    variant='h4'
                    gutterBottom
                    color={colors.grey[100]}
                    className='whitespace-pre-line'
                  >
                    {listDetails?.description}
                  </Typography>
                </>
              )}
            </form>

            <div className='flex gap-3 !mt-8 mb-1 justify-between'>
              <div className='flex gap-3'>
                <Typography
                  variant='h3'
                  gutterBottom
                  color={colors.grey[100]}
                  className='capitalize '
                >
                  Entries
                </Typography>
                <FiltersCreator
                  className='ms-3'
                  filterObj={filterObj}
                  setFilterObj={setFilterObj}
                  fields={fields}
                  wrapperClass={wrapperClass}
                  initialValues={{ query: initialQuery }}
                />
              </div>
              <div className=''>
                {selectedEntries.length > 0 ? (
                  <Button
                    variant='outlined'
                    color='error'
                    sx={{
                      textTransform: 'none',
                      color: colors.grey[100],
                    }}
                    startIcon={<DeleteIcon color='error' />}
                    onClick={handleShowAlert}
                  >
                    Delete {selectedEntries?.length > 1 ? 'Entries' : 'Entry'}
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='secondary'
                    sx={{
                      textTransform: 'none',
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[100],
                    }}
                    startIcon={<AddIcon />}
                    onClick={handleEntriesModelOpen}
                  >
                    Add Entry
                  </Button>
                )}
              </div>
            </div>
            {listDetails ? (
              <Table
                columns={columns}
                dataURL={tableDataURL}
                rowSelection={true}
                filter={filterObj}
                checkEqual={tableRefresh}
                handleSelection={(id) => setSelectedEntries(id)}
                rowSelectionModel={selectedEntries}
              />
            ) : null}
          </>
        )}
      </Box>
      <AddEntriesModel
        handleModelClose={handleEntriesModelClose}
        openModel={addEntriesModel}
        setTableRefresh={setTableRefresh}
      />
      <DeleteAlertModel
        listId={listId}
        selectedEntries={selectedEntries}
        setSelectedEntries={setSelectedEntries}
        handleAlertClose={handleAlertClose}
        setTableRefresh={setTableRefresh}
        alertModel={alertModel}
      />
    </>
  );
};

export default ListDetails;
