import React from 'react';

import { Box, Button, useTheme } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Header, Table } from 'components';
import { tokens } from 'theme';
import { Link } from 'react-router-dom';

const AIFraudAnalysts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
      renderCell: ({ row }) => (
        <>
          <Link to={`/ai_fraud_analysts/${row.id}`} className='underline'>
            {row?.id}
          </Link>
        </>
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {/* <Link to={`/rules/${row.id}`} className='underline'> */}
          {row?.title}
          {/* </Link> */}
        </>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 120,
      flex: 2.5,
      renderCell: ({ row }) => <>{row?.description}</>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'right',
      headerAlign: 'right',
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <Link to={`/ai_fraud_analysts/${row.id}`}>
              <Button
                variant='contained'
                color='primary'
                sx={{
                  textTransform: 'none',
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[700],
                }}
                startIcon={<VisibilityIcon />}
              >
                Open
              </Button>
            </Link>
          </>
        );
      },
    },
  ];
  return (
    <Box m='20px'>
      <Header title='AI Fraud Analysts' />
      <Table
        columns={columns}
        tableDATA={[
          {
            id: 1,
            title: 'Run KYC Checks',
            description:
              'Use available KYC vendors to determine level of risk for customers',
            status: 'active',
            created_at: 1694999496,
          },
          {
            id: 2,
            title: 'Run EDD',
            description: 'Run enhanced due diligence on high risk customers periodically',
            status: 'active',
            created_at: 1694999496,
          },
          {
            id: 3,
            title: 'Run KYB Checks',
            description:
              'Use available KYB vendors to determine level of risk for businesses',
            status: 'active',
            created_at: 1694999496,
          },
        ]}
        // dataURL={rulesListURL}
        // filter={filterObj}
      />
    </Box>
  );
};

export default AIFraudAnalysts;
