import { useEffect, useState } from 'react';
import { AxiosCached } from 'services/api/Config';
import { useAuthUser } from 'utils/context/AuthUserContext';

function useDataTypes() {
  const { accessAccount } = useAuthUser();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await AxiosCached.get(`dynamic_data/types/full`, {
          params: { account_id: accessAccount?.id },
          cache: true,
        });
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [accessAccount]);

  return { data, loading, error };
}

export default useDataTypes;
