import React from 'react';
import Breadcrumb from 'layout/global/Breadcrumb';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { RedocStandalone } from 'redoc';

const APIDoc = () => {
  var spec = {
    openapi: '3.0.2',
    info: { title: 'Dashboard API (mvp)', version: '0.1.0' },
    paths: {
      '/accounts': {
        get: {
          tags: ['accounts'],
          summary: 'Do Search Accounts',
          description: 'Search accounts',
          operationId: 'do_search_accounts_accounts_get',
          parameters: [
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 1 },
              name: 'page',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Name', type: 'string' },
              name: 'name',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Account Ids', type: 'string' },
              name: 'account_ids',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title: 'Response Do Search Accounts Accounts Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/AccountRead' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/accounts/{account_id}': {
        get: {
          tags: ['accounts'],
          summary: 'Do Read Account',
          description: 'Get an account',
          operationId: 'do_read_account_accounts__account_id__get',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'path',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/AccountRead' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        put: {
          tags: ['accounts'],
          summary: 'Do Update Account',
          description: 'Update an account',
          operationId: 'do_update_account_accounts__account_id__put',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'path',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { $ref: '#/components/schemas/AccountUpdate' },
              },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/AccountRead' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/accounts/{account_id}/api_keys': {
        get: {
          tags: ['accounts'],
          summary: 'Do Search Api Keys',
          description: 'Search API keys',
          operationId: 'do_search_api_keys_accounts__account_id__api_keys_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'path',
            },
            {
              required: false,
              schema: { title: 'Name', type: 'string' },
              name: 'name',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Statuses', type: 'string' },
              name: 'statuses',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Created By User Id', type: 'integer' },
              name: 'created_by_user_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 1 },
              name: 'page',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort By', type: 'string' },
              name: 'sort_by',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort Order', type: 'string' },
              name: 'sort_order',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title:
                      'Response Do Search Api Keys Accounts  Account Id  Api Keys Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/AccountAPIKeyRead' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        post: {
          tags: ['accounts'],
          summary: 'Do Create Api Key',
          description: 'Create an API key for an account',
          operationId: 'do_create_api_key_accounts__account_id__api_keys_post',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'path',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { $ref: '#/components/schemas/AccountCreateAPIKey' },
              },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/AccountAPIKeyRead' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        delete: {
          tags: ['accounts'],
          summary: 'Do Delete Api Key',
          description: 'Delete an API key',
          operationId: 'do_delete_api_key_accounts__account_id__api_keys_delete',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Api Key Id', type: 'integer' },
              name: 'api_key_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/AccountAPIKeyRead' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/accounts/{account_id}/stats': {
        get: {
          tags: ['accounts'],
          summary: 'Do Get Account Stats',
          description: 'Get stats for an account',
          operationId: 'do_get_account_stats_accounts__account_id__stats_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'path',
            },
            {
              description: 'Comma separated list of stat names',
              required: true,
              schema: {
                title: 'Stat Names',
                type: 'string',
                description: 'Comma separated list of stat names',
              },
              name: 'stat_names',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/accounts/{account_id}/invite': {
        post: {
          tags: ['accounts'],
          summary: 'Do Invite User',
          description: 'Invite a user to an account',
          operationId: 'do_invite_user_accounts__account_id__invite_post',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'path',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { $ref: '#/components/schemas/UserEmailRead' },
              },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': { schema: { $ref: '#/components/schemas/UserRead' } },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/audit-logs/{object_type}/{object_id}': {
        get: {
          tags: ['audit_logs'],
          summary: 'Get Audit Logs',
          description: 'Get audit logs for an object',
          operationId: 'get_audit_logs_audit_logs__object_type___object_id__get',
          parameters: [
            {
              required: true,
              schema: { title: 'Object Type', type: 'string' },
              name: 'object_type',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Object Id', type: 'integer' },
              name: 'object_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Title', type: 'string' },
              name: 'title',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Description', type: 'string' },
              name: 'description',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 0 },
              name: 'page',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'User Ids', type: 'string' },
              name: 'user_ids',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Created Start', type: 'integer' },
              name: 'created_start',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Created End', type: 'integer' },
              name: 'created_end',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort By', type: 'string' },
              name: 'sort_by',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort Order', type: 'string' },
              name: 'sort_order',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title:
                      'Response Get Audit Logs Audit Logs  Object Type   Object Id  Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/AuditLogRead' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/charts/transactions_over_time': {
        get: {
          tags: ['charts'],
          summary: 'Do Get Transactions Over Time',
          description: 'Get transactions over time',
          operationId: 'do_get_transactions_over_time_charts_transactions_over_time_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/dynamic_data/types': {
        get: {
          tags: ['dynamic_data'],
          summary: 'Do Search Dynamic Data Tables',
          operationId: 'do_search_dynamic_data_tables_dynamic_data_types_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Name', type: 'string' },
              name: 'name',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Display Name', type: 'string' },
              name: 'display_name',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Type Id', type: 'integer' },
              name: 'type_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 1 },
              name: 'page',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title:
                      'Response Do Search Dynamic Data Tables Dynamic Data Types Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/DynamicDataTableSearch' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        post: {
          tags: ['dynamic_data'],
          summary: 'Do Create Dynamic Data Table',
          operationId: 'do_create_dynamic_data_table_dynamic_data_types_post',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { $ref: '#/components/schemas/DynamicDataTableCreate' },
              },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/dynamic_data/types/{type_id}': {
        get: {
          tags: ['dynamic_data'],
          summary: 'Do Get Dynamic Data Properties',
          operationId: 'do_get_dynamic_data_properties_dynamic_data_types__type_id__get',
          parameters: [
            {
              required: true,
              schema: { title: 'Type Id', type: 'integer' },
              name: 'type_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'string' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/DynamicDataTable' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        put: {
          tags: ['dynamic_data'],
          summary: 'Do Update Dynamic Data Properties',
          operationId:
            'do_update_dynamic_data_properties_dynamic_data_types__type_id__put',
          parameters: [
            {
              required: true,
              schema: { title: 'Type Id', type: 'integer' },
              name: 'type_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'string' },
              name: 'account_id',
              in: 'query',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { $ref: '#/components/schemas/UpdateDynamicDataTable' },
              },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/DynamicDataTable' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/dynamic_data/data': {
        get: {
          tags: ['dynamic_data'],
          summary: 'Do Search Dynamic Data',
          operationId: 'do_search_dynamic_data_dynamic_data_data_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'string' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: true,
              schema: { title: 'Ddt Type', type: 'string' },
              name: 'ddt_type',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Ddt Id', type: 'integer' },
              name: 'ddt_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 1 },
              name: 'page',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { title: 'Filter Dict', type: 'object', default: {} },
              },
            },
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        put: {
          tags: ['dynamic_data'],
          summary: 'Do Update Dynamic Data Ddt',
          operationId: 'do_update_dynamic_data_ddt_dynamic_data_data_put',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'string' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: true,
              schema: { title: 'Ddt Type', type: 'string' },
              name: 'ddt_type',
              in: 'query',
            },
            {
              required: true,
              schema: { title: 'Ddt Id', type: 'integer' },
              name: 'ddt_id',
              in: 'query',
            },
          ],
          requestBody: {
            content: {
              'application/json': { schema: { title: 'Fields', type: 'object' } },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        post: {
          tags: ['dynamic_data'],
          summary: 'Do Create Dynamic Data Ddt',
          operationId: 'do_create_dynamic_data_ddt_dynamic_data_data_post',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'string' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: true,
              schema: { title: 'Ddt Type', type: 'string' },
              name: 'ddt_type',
              in: 'query',
            },
          ],
          requestBody: {
            content: {
              'application/json': { schema: { title: 'Fields', type: 'object' } },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/rules': {
        get: {
          tags: ['rules'],
          summary: 'Do Search Rules',
          description: 'Search Rules',
          operationId: 'do_search_rules_rules_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Title', type: 'string' },
              name: 'title',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Description', type: 'string' },
              name: 'description',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Statuses', type: 'string' },
              name: 'statuses',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Created Start', type: 'integer' },
              name: 'created_start',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Created End', type: 'integer' },
              name: 'created_end',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Author Ids', type: 'string' },
              name: 'author_ids',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort By', type: 'string' },
              name: 'sort_by',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort Order', type: 'string' },
              name: 'sort_order',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 1 },
              name: 'page',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title: 'Response Do Search Rules Rules Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/RuleReadSearch' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        post: {
          tags: ['rules'],
          summary: 'Do Create Rule',
          description: 'Create new Rule',
          operationId: 'do_create_rule_rules_post',
          requestBody: {
            content: {
              'application/json': { schema: { $ref: '#/components/schemas/RuleCreate' } },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleReadDetail' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/rules/{rule_id}/duplicate': {
        post: {
          tags: ['rules'],
          summary: 'Do Duplicate Rule',
          description: 'Duplicate a Rule',
          operationId: 'do_duplicate_rule_rules__rule_id__duplicate_post',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { $ref: '#/components/schemas/RuleDuplicate' },
              },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleReadDetail' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/rules/{rule_id}/enable_testing': {
        put: {
          tags: ['rules'],
          summary: 'Enable Testing Mode',
          description: 'Enable Testing Mode on a Rule',
          operationId: 'enable_testing_mode_rules__rule_id__enable_testing_put',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleReadDetail' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/rules/{rule_id}/disable_testing': {
        put: {
          tags: ['rules'],
          summary: 'Disable Testing Mode',
          description: 'Disable Testing Mode on a Rule',
          operationId: 'disable_testing_mode_rules__rule_id__disable_testing_put',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleReadDetail' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/rules/{rule_id}/activate': {
        put: {
          tags: ['rules'],
          summary: 'Activate Rule',
          description: 'Activate a Rule',
          operationId: 'activate_rule_rules__rule_id__activate_put',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleReadDetail' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/rules/{rule_id}/deactivate': {
        put: {
          tags: ['rules'],
          summary: 'Deactivate Rule',
          description: 'Deactivate a Rule',
          operationId: 'deactivate_rule_rules__rule_id__deactivate_put',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleReadDetail' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/rules/{rule_id}/stats': {
        get: {
          tags: ['rules'],
          summary: 'Do Get Rule Stats By Id',
          description: 'Get Rule Stats by ID',
          operationId: 'do_get_rule_stats_by_id_rules__rule_id__stats_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
            {
              description: 'Comma separated list of stat names',
              required: true,
              schema: {
                title: 'Stat Names',
                type: 'string',
                description: 'Comma separated list of stat names',
              },
              name: 'stat_names',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/rules/{rule_id}/decisions': {
        get: {
          tags: ['rules'],
          summary: 'Do Search Rule Decisions',
          operationId: 'do_search_rule_decisions_rules__rule_id__decisions_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: false,
              schema: { $ref: '#/components/schemas/ModeOnRuleDecision' },
              name: 'mode',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Decision', type: 'string' },
              name: 'decision',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Object Type', type: 'string' },
              name: 'object_type',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Object Id', type: 'integer' },
              name: 'object_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Start Time', type: 'integer' },
              name: 'start_time',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'End Time', type: 'integer' },
              name: 'end_time',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 1 },
              name: 'page',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title:
                      'Response Do Search Rule Decisions Rules  Rule Id  Decisions Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/RuleDecisionRead' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        post: {
          tags: ['rules'],
          summary: 'Do Create Rule Decision',
          operationId: 'do_create_rule_decision_rules__rule_id__decisions_post',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { $ref: '#/components/schemas/RuleDecisionCreate' },
              },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleDecisionRead' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/rules/{rule_id}': {
        get: {
          tags: ['rules'],
          summary: 'Do Get Rule By Id',
          description: 'Get Rule by ID',
          operationId: 'do_get_rule_by_id_rules__rule_id__get',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleReadSearch' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        put: {
          tags: ['rules'],
          summary: 'Do Update Rule By Id',
          description: 'Update Rule by ID',
          operationId: 'do_update_rule_by_id_rules__rule_id__put',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          requestBody: {
            content: {
              'application/json': { schema: { $ref: '#/components/schemas/RuleUpdate' } },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleReadDetail' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        delete: {
          tags: ['rules'],
          summary: 'Do Delete Rule By Id',
          description: 'Delete Rule by ID',
          operationId: 'do_delete_rule_by_id_rules__rule_id__delete',
          parameters: [
            {
              required: true,
              schema: { title: 'Rule Id', type: 'integer' },
              name: 'rule_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/RuleReadSearch' },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/teams': {
        get: {
          tags: ['teams'],
          summary: 'Do Read Teams',
          description: 'Search teams',
          operationId: 'do_read_teams_teams_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 1 },
              name: 'page',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Name', type: 'string' },
              name: 'name',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Description', type: 'string' },
              name: 'description',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Statuses', type: 'string' },
              name: 'statuses',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title: 'Response Do Read Teams Teams Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/TeamRead' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        post: {
          tags: ['teams'],
          summary: 'Do Create Team',
          description: 'Create a team',
          operationId: 'do_create_team_teams_post',
          requestBody: {
            content: {
              'application/json': { schema: { $ref: '#/components/schemas/TeamCreate' } },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': { schema: { $ref: '#/components/schemas/TeamRead' } },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/teams/{team_id}': {
        get: {
          tags: ['teams'],
          summary: 'Do Read Team',
          description: 'Get a team',
          operationId: 'do_read_team_teams__team_id__get',
          parameters: [
            {
              required: true,
              schema: { title: 'Team Id', type: 'integer' },
              name: 'team_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': { schema: { $ref: '#/components/schemas/TeamRead' } },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        put: {
          tags: ['teams'],
          summary: 'Do Update Team',
          description: 'Update a team',
          operationId: 'do_update_team_teams__team_id__put',
          parameters: [
            {
              required: true,
              schema: { title: 'Team Id', type: 'integer' },
              name: 'team_id',
              in: 'path',
            },
          ],
          requestBody: {
            content: {
              'application/json': { schema: { $ref: '#/components/schemas/TeamUpdate' } },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': { schema: { $ref: '#/components/schemas/TeamRead' } },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        delete: {
          tags: ['teams'],
          summary: 'Do Delete Team',
          description: 'Deactivate a team',
          operationId: 'do_delete_team_teams__team_id__delete',
          parameters: [
            {
              required: true,
              schema: { title: 'Team Id', type: 'integer' },
              name: 'team_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': { schema: { $ref: '#/components/schemas/TeamRead' } },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/teams/{team_id}/users': {
        get: {
          tags: ['teams'],
          summary: 'Do Get Users On Team',
          description: 'Get users on a team',
          operationId: 'do_get_users_on_team_teams__team_id__users_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Team Id', type: 'integer' },
              name: 'team_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Non Members', type: 'boolean', default: false },
              name: 'non_members',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort By', type: 'string' },
              name: 'sort_by',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort Order', type: 'string' },
              name: 'sort_order',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 1 },
              name: 'page',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title: 'Response Do Get Users On Team Teams  Team Id  Users Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/UserReadMin' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        post: {
          tags: ['teams'],
          summary: 'Do Add Users To Team',
          description: 'Add users to a team',
          operationId: 'do_add_users_to_team_teams__team_id__users_post',
          parameters: [
            {
              required: true,
              schema: { title: 'Team Id', type: 'integer' },
              name: 'team_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { title: 'User Ids', type: 'array', items: { type: 'integer' } },
              },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        delete: {
          tags: ['teams'],
          summary: 'Do Remove Users From Team',
          description: 'Remove users from a team',
          operationId: 'do_remove_users_from_team_teams__team_id__users_delete',
          parameters: [
            {
              required: true,
              schema: { title: 'Team Id', type: 'integer' },
              name: 'team_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          requestBody: {
            content: {
              'application/json': {
                schema: { title: 'User Ids', type: 'array', items: { type: 'integer' } },
              },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/users/me': {
        get: {
          tags: ['users'],
          summary: 'Do Get Current User',
          description: 'Get current User',
          operationId: 'do_get_current_user_users_me_get',
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': { schema: { $ref: '#/components/schemas/UserRead' } },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/users/{user_id}': {
        get: {
          tags: ['users'],
          summary: 'Do Get User',
          description: 'Get a User',
          operationId: 'do_get_user_users__user_id__get',
          parameters: [
            {
              required: true,
              schema: { title: 'User Id', type: 'integer' },
              name: 'user_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': { schema: { $ref: '#/components/schemas/UserRead' } },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
        put: {
          tags: ['users'],
          summary: 'Do Update User',
          description: 'Update a User',
          operationId: 'do_update_user_users__user_id__put',
          parameters: [
            {
              required: true,
              schema: { title: 'User Id', type: 'integer' },
              name: 'user_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          requestBody: {
            content: {
              'application/json': { schema: { $ref: '#/components/schemas/UserUpdate' } },
            },
            required: true,
          },
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': { schema: { $ref: '#/components/schemas/UserRead' } },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/users': {
        get: {
          tags: ['users'],
          summary: 'Do Search Users',
          description: 'Search Users',
          operationId: 'do_search_users_users_get',
          parameters: [
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Name', type: 'string' },
              name: 'name',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Email', type: 'string' },
              name: 'email',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Statuses', type: 'string' },
              name: 'statuses',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Created Start', type: 'integer' },
              name: 'created_start',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Created End', type: 'integer' },
              name: 'created_end',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort By', type: 'string' },
              name: 'sort_by',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Sort Order', type: 'string' },
              name: 'sort_order',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Limit', type: 'integer', default: 10 },
              name: 'limit',
              in: 'query',
            },
            {
              required: false,
              schema: { title: 'Page', type: 'integer', default: 1 },
              name: 'page',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title: 'Response Do Search Users Users Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/UserRead' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/users/{user_id}/permissions': {
        get: {
          tags: ['users'],
          summary: 'Do Get User Permissions',
          description: "Get a User's Permissions",
          operationId: 'do_get_user_permissions_users__user_id__permissions_get',
          parameters: [
            {
              required: true,
              schema: { title: 'User Id', type: 'integer' },
              name: 'user_id',
              in: 'path',
            },
            {
              required: true,
              schema: { title: 'Account Id', type: 'integer' },
              name: 'account_id',
              in: 'query',
            },
          ],
          responses: {
            200: {
              description: 'Successful Response',
              content: {
                'application/json': {
                  schema: {
                    title:
                      'Response Do Get User Permissions Users  User Id  Permissions Get',
                    type: 'array',
                    items: { $ref: '#/components/schemas/UserPermissionRead' },
                  },
                },
              },
            },
            422: {
              description: 'Validation Error',
              content: {
                'application/json': {
                  schema: { $ref: '#/components/schemas/HTTPValidationError' },
                },
              },
            },
          },
          security: [{ HTTPBearer: [] }],
        },
      },
      '/scripts/init-processing-decisions': {
        post: {
          tags: ['scripts'],
          summary: 'Do Init Processing Decisions',
          description: 'init script',
          operationId:
            'do_init_processing_decisions_scripts_init_processing_decisions_post',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/scripts/create-permissions': {
        get: {
          tags: ['scripts'],
          summary: 'Do Create Permissions',
          description: 'Create permissions',
          operationId: 'do_create_permissions_scripts_create_permissions_get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/mocks/users/{user_id}/permissions': {
        get: {
          tags: ['mocks'],
          summary: 'Get User',
          description: "Get a user's permissions",
          operationId: 'get_user_mocks_users__user_id__permissions_get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/mocks/transactions': {
        get: {
          tags: ['mocks'],
          summary: 'Search Transactions',
          description: 'Search transactions',
          operationId: 'search_transactions_mocks_transactions_get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/mocks/transactions/{transaction_id}': {
        get: {
          tags: ['mocks'],
          summary: 'Get Transaction',
          description: 'Get a transaction',
          operationId: 'get_transaction_mocks_transactions__transaction_id__get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/mocks/entities': {
        get: {
          tags: ['mocks'],
          summary: 'Search Entities',
          description: 'Search entities',
          operationId: 'search_entities_mocks_entities_get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/mocks/entities/{entity_id}': {
        get: {
          tags: ['mocks'],
          summary: 'Get Entity',
          description: 'Get an entity',
          operationId: 'get_entity_mocks_entities__entity_id__get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/mocks/instruments': {
        get: {
          tags: ['mocks'],
          summary: 'Search Instruments',
          description: 'Search instruments',
          operationId: 'search_instruments_mocks_instruments_get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/mocks/instruments/{instrument_id}': {
        get: {
          tags: ['mocks'],
          summary: 'Get Instrument',
          description: 'Get an instrument',
          operationId: 'get_instrument_mocks_instruments__instrument_id__get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/mocks/events': {
        get: {
          tags: ['mocks'],
          summary: 'Search Events',
          description: 'Search events',
          operationId: 'search_events_mocks_events_get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
      '/mocks/events/{event_id}': {
        get: {
          tags: ['mocks'],
          summary: 'Get Event',
          description: 'Get an event',
          operationId: 'get_event_mocks_events__event_id__get',
          responses: {
            200: {
              description: 'Successful Response',
              content: { 'application/json': { schema: {} } },
            },
          },
        },
      },
    },
    components: {
      schemas: {
        AccountAPIKeyRead: {
          title: 'AccountAPIKeyRead',
          required: [
            'id',
            'name',
            'api_key',
            'status',
            'account_id',
            'created_by_user_id',
            'created',
          ],
          type: 'object',
          properties: {
            id: { title: 'Id', type: 'integer' },
            name: { title: 'Name', type: 'string' },
            api_key: { title: 'Api Key', type: 'string' },
            status: { title: 'Status', type: 'string' },
            account_id: { title: 'Account Id', type: 'integer' },
            created_by_user_id: { title: 'Created By User Id', type: 'integer' },
            created: { title: 'Created', type: 'integer' },
          },
        },
        AccountCreateAPIKey: {
          title: 'AccountCreateAPIKey',
          required: ['name'],
          type: 'object',
          properties: { name: { title: 'Name', type: 'string' } },
        },
        AccountRead: {
          title: 'AccountRead',
          required: ['name', 'id', 'created'],
          type: 'object',
          properties: {
            name: { title: 'Name', type: 'string' },
            id: { title: 'Id', type: 'integer' },
            created: { title: 'Created', type: 'integer' },
          },
        },
        AccountUpdate: {
          title: 'AccountUpdate',
          required: ['name'],
          type: 'object',
          properties: { name: { title: 'Name', type: 'string' } },
        },
        AuditLogRead: {
          title: 'AuditLogRead',
          required: [
            'action',
            'object_type',
            'object_id',
            'title',
            'description',
            'id',
            'user',
            'timestamp',
          ],
          type: 'object',
          properties: {
            action: { title: 'Action', type: 'string' },
            object_type: { title: 'Object Type', type: 'string' },
            object_id: { title: 'Object Id', type: 'integer' },
            user_id: { title: 'User Id', type: 'integer' },
            before_state: { title: 'Before State', type: 'object' },
            after_state: { title: 'After State', type: 'object' },
            title: { title: 'Title', type: 'string' },
            description: { title: 'Description', type: 'string' },
            account_id: { title: 'Account Id', type: 'integer' },
            id: { title: 'Id', type: 'integer' },
            user: { $ref: '#/components/schemas/UserReadMin' },
            timestamp: { title: 'Timestamp', type: 'integer' },
          },
        },
        DynamicDataTable: {
          title: 'DynamicDataTable',
          required: ['id', 'name', 'display_name', 'fields'],
          type: 'object',
          properties: {
            id: { title: 'Id', type: 'integer' },
            name: { title: 'Name', type: 'string' },
            display_name: { title: 'Display Name', type: 'string' },
            fields: {
              title: 'Fields',
              type: 'array',
              items: { $ref: '#/components/schemas/Field' },
            },
          },
        },
        DynamicDataTableCreate: {
          title: 'DynamicDataTableCreate',
          required: ['name', 'fields', 'display_name'],
          type: 'object',
          properties: {
            name: { title: 'Name', type: 'string' },
            fields: {
              title: 'Fields',
              type: 'array',
              items: { $ref: '#/components/schemas/Field' },
            },
            display_name: { title: 'Display Name', type: 'string' },
          },
        },
        DynamicDataTableSearch: {
          title: 'DynamicDataTableSearch',
          required: ['id', 'name', 'display_name'],
          type: 'object',
          properties: {
            id: { title: 'Id', type: 'integer' },
            name: { title: 'Name', type: 'string' },
            display_name: { title: 'Display Name', type: 'string' },
          },
        },
        Field: {
          title: 'Field',
          required: ['name', 'type'],
          type: 'object',
          properties: {
            name: { title: 'Name', type: 'string' },
            type: { title: 'Type', type: 'string' },
            nullable: { title: 'Nullable', type: 'boolean' },
            unique: { title: 'Unique', type: 'boolean' },
            default: { title: 'Default', type: 'string' },
            primary_key: { title: 'Primary Key', type: 'boolean' },
            display_name: { title: 'Display Name', type: 'string', default: '' },
            delete_field: { title: 'Delete Field', type: 'boolean', default: false },
          },
        },
        HTTPValidationError: {
          title: 'HTTPValidationError',
          type: 'object',
          properties: {
            detail: {
              title: 'Detail',
              type: 'array',
              items: { $ref: '#/components/schemas/ValidationError' },
            },
          },
        },
        ModeOnRuleDecision: {
          title: 'ModeOnRuleDecision',
          enum: ['live', 'test'],
          type: 'string',
          description: 'An enumeration.',
        },
        RuleCreate: {
          title: 'RuleCreate',
          required: ['title', 'description', 'account_id'],
          type: 'object',
          properties: {
            title: { title: 'Title', type: 'string' },
            description: { title: 'Description', type: 'string' },
            definition: { title: 'Definition', type: 'object' },
            blockly_definition: { title: 'Blockly Definition', type: 'object' },
            account_id: { title: 'Account Id', type: 'integer' },
          },
        },
        RuleDecisionCreate: {
          title: 'RuleDecisionCreate',
          required: ['object_type', 'object_id', 'account_id', 'mode', 'decision'],
          type: 'object',
          properties: {
            object_type: { title: 'Object Type', type: 'string' },
            object_id: { title: 'Object Id', type: 'integer' },
            account_id: { title: 'Account Id', type: 'integer' },
            mode: { $ref: '#/components/schemas/ModeOnRuleDecision' },
            decision: { title: 'Decision', type: 'string' },
            object_data: { title: 'Object Data', type: 'object' },
          },
        },
        RuleDecisionRead: {
          title: 'RuleDecisionRead',
          required: [
            'object_type',
            'object_id',
            'account_id',
            'decision',
            'id',
            'created',
          ],
          type: 'object',
          properties: {
            object_type: { title: 'Object Type', type: 'string' },
            object_id: { title: 'Object Id', type: 'integer' },
            account_id: { title: 'Account Id', type: 'integer' },
            mode: { $ref: '#/components/schemas/ModeOnRuleDecision' },
            decision: { title: 'Decision', type: 'string' },
            object_data: { title: 'Object Data', type: 'object' },
            id: { title: 'Id', type: 'integer' },
            created: { title: 'Created', type: 'integer' },
          },
        },
        RuleDuplicate: {
          title: 'RuleDuplicate',
          required: ['account_id'],
          type: 'object',
          properties: {
            title: { title: 'Title', type: 'string' },
            description: { title: 'Description', type: 'string' },
            definition: { title: 'Definition', type: 'object' },
            blockly_definition: { title: 'Blockly Definition', type: 'object' },
            account_id: { title: 'Account Id', type: 'integer' },
          },
        },
        RuleReadDetail: {
          title: 'RuleReadDetail',
          required: [
            'title',
            'description',
            'id',
            'account_id',
            'status',
            'author_id',
            'created',
            'author',
          ],
          type: 'object',
          properties: {
            title: { title: 'Title', type: 'string' },
            description: { title: 'Description', type: 'string' },
            definition: { title: 'Definition', type: 'object' },
            blockly_definition: { title: 'Blockly Definition', type: 'object' },
            id: { title: 'Id', type: 'integer' },
            account_id: { title: 'Account Id', type: 'integer' },
            status: { title: 'Status', type: 'string' },
            author_id: { title: 'Author Id', type: 'integer' },
            created: { title: 'Created', type: 'integer' },
            author: { $ref: '#/components/schemas/UserReadMin' },
          },
        },
        RuleReadSearch: {
          title: 'RuleReadSearch',
          required: [
            'title',
            'description',
            'id',
            'account_id',
            'status',
            'author_id',
            'created',
            'author',
          ],
          type: 'object',
          properties: {
            title: { title: 'Title', type: 'string' },
            description: { title: 'Description', type: 'string' },
            definition: { title: 'Definition', type: 'object' },
            blockly_definition: { title: 'Blockly Definition', type: 'object' },
            id: { title: 'Id', type: 'integer' },
            account_id: { title: 'Account Id', type: 'integer' },
            status: { title: 'Status', type: 'string' },
            author_id: { title: 'Author Id', type: 'integer' },
            created: { title: 'Created', type: 'integer' },
            author: { $ref: '#/components/schemas/UserReadMin' },
          },
        },
        RuleUpdate: {
          title: 'RuleUpdate',
          type: 'object',
          properties: {
            title: { title: 'Title', type: 'string' },
            description: { title: 'Description', type: 'string' },
            definition: { title: 'Definition', type: 'object' },
            blockly_definition: { title: 'Blockly Definition', type: 'object' },
          },
        },
        StatusOnTeam: {
          title: 'StatusOnTeam',
          enum: ['active', 'deactivated'],
          type: 'string',
          description: 'An enumeration.',
        },
        TeamCreate: {
          title: 'TeamCreate',
          required: ['name', 'description', 'account_id'],
          type: 'object',
          properties: {
            name: { title: 'Name', type: 'string' },
            description: { title: 'Description', type: 'string' },
            account_id: { title: 'Account Id', type: 'integer' },
          },
        },
        TeamRead: {
          title: 'TeamRead',
          required: [
            'name',
            'description',
            'id',
            'account_id',
            'status',
            'created_by_user_id',
            'created',
            'updated',
          ],
          type: 'object',
          properties: {
            name: { title: 'Name', type: 'string' },
            description: { title: 'Description', type: 'string' },
            id: { title: 'Id', type: 'integer' },
            account_id: { title: 'Account Id', type: 'integer' },
            status: { title: 'Status', type: 'string' },
            created_by_user_id: { title: 'Created By User Id', type: 'integer' },
            created: { title: 'Created', type: 'integer' },
            updated: { title: 'Updated', type: 'integer' },
          },
        },
        TeamUpdate: {
          title: 'TeamUpdate',
          type: 'object',
          properties: {
            name: { title: 'Name', type: 'string' },
            description: { title: 'Description', type: 'string' },
            status: { $ref: '#/components/schemas/StatusOnTeam' },
          },
        },
        UpdateDynamicDataTable: {
          title: 'UpdateDynamicDataTable',
          type: 'object',
          properties: {
            name: { title: 'Name', type: 'string' },
            display_name: { title: 'Display Name', type: 'string' },
            fields: {
              title: 'Fields',
              type: 'array',
              items: { $ref: '#/components/schemas/Field' },
            },
          },
        },
        UserEmailRead: {
          title: 'UserEmailRead',
          required: ['email'],
          type: 'object',
          properties: { email: { title: 'Email', type: 'string' } },
        },
        UserPermissionRead: {
          title: 'UserPermissionRead',
          required: ['id', 'name', 'description', 'created', 'updated'],
          type: 'object',
          properties: {
            id: { title: 'Id', type: 'integer' },
            name: { title: 'Name', type: 'string' },
            description: { title: 'Description', type: 'string' },
            created: { title: 'Created', type: 'integer' },
            updated: { title: 'Updated', type: 'integer' },
          },
        },
        UserRead: {
          title: 'UserRead',
          required: ['email', 'id', 'created', 'updated', 'name'],
          type: 'object',
          properties: {
            email: { title: 'Email', type: 'string' },
            id: { title: 'Id', type: 'integer' },
            created: { title: 'Created', type: 'integer' },
            updated: { title: 'Updated', type: 'integer' },
            name: { title: 'Name', type: 'string' },
          },
        },
        UserReadMin: {
          title: 'UserReadMin',
          required: ['email', 'id', 'name'],
          type: 'object',
          properties: {
            email: { title: 'Email', type: 'string' },
            id: { title: 'Id', type: 'integer' },
            name: { title: 'Name', type: 'string' },
          },
        },
        UserUpdate: {
          title: 'UserUpdate',
          required: ['name'],
          type: 'object',
          properties: { name: { title: 'Name', type: 'string' } },
        },
        ValidationError: {
          title: 'ValidationError',
          required: ['loc', 'msg', 'type'],
          type: 'object',
          properties: {
            loc: {
              title: 'Location',
              type: 'array',
              items: { anyOf: [{ type: 'string' }, { type: 'integer' }] },
            },
            msg: { title: 'Message', type: 'string' },
            type: { title: 'Error Type', type: 'string' },
          },
        },
      },
      securitySchemes: { HTTPBearer: { type: 'http', scheme: 'bearer' } },
    },
  };

  // const mySafeHTML = DOMPurify.sanitize(myHTML);

  const { accessAccount } = useAuthUser();

  return (
    <div>
      <div className='flex justify-between p-4 pb-0'>
        <Breadcrumb />
        <span className='text-2xl font-bold'>API Docs</span>
        <div className='flex'>
          <div className='self-center text-base me-3'>{accessAccount?.name}</div>
        </div>
      </div>
      <div className='h-full m-5 !bg-white'>
        <RedocStandalone spec={spec} />
      </div>
    </div>
  );
};

export default APIDoc;
