import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, Divider, Stack } from '@mui/material';
import { inject, svgResize } from 'blockly';
import { WorkspaceOption } from 'blockly/options';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import '../../../blockly/blocks';
import '../../../blockly/generators';
import { BlocklyToolbox } from './BlocklyToolbox';

export function BaseBlocklyWorkspace(props, ref) {
  const workspaceRef = useRef(null);
  const [workspace, setWorkspace] = useState(null);

  useEffect(() => {
    let newWorkspace = null;
    if (workspaceRef.current && !newWorkspace) {
      newWorkspace = inject(workspaceRef.current, WorkspaceOption);
      setWorkspace(newWorkspace);
      newWorkspace.getToolbox().setVisible(false);
      svgResize(newWorkspace);
    }
    return () => {
      if (newWorkspace) {
        newWorkspace.dispose();
        setWorkspace(null);
      }
    };
  }, []);

  useImperativeHandle(ref, () => workspace, [workspace]);
  return (
    <Stack
      direction={'row'}
      alignItems={'stretch'}
      flex={1}
      height={'100%'}
      width={'100%'}
    >
      <BlocklyToolbox workspace={workspace} />
      <Divider orientation='vertical' flexItem />
      <Box ref={workspaceRef} flex={1} position={'relative'}>
        <Box position={'absolute'} zIndex={5} top={24} right={24}>
          {workspace?.allInputsFilled() ? (
            <CheckCircle fontSize='large' color='success' />
          ) : (
            <Cancel fontSize='large' color='error' />
          )}
        </Box>
      </Box>
    </Stack>
  );
}

export const BlocklyWorkspace = forwardRef(BaseBlocklyWorkspace);
