import { Theme, Themes } from 'blockly';
import { blocklyToolbox } from './toolboxes';

export const darkTheme = Theme.defineTheme('KRI_DARK', {
  name: 'DARK',
  base: Themes.Classic,
  componentStyles: {
    workspaceBackgroundColour: '#0c101b',
    toolboxBackgroundColour: '#1F2A40',
    toolboxForegroundColour: '#fff',
    flyoutBackgroundColour: '#1F2A40',
    flyoutForegroundColour: '#ccc',
    flyoutOpacity: 1,
    scrollbarColour: '#1F2A40',
    insertionMarkerColour: '#fff',
    insertionMarkerOpacity: 0.3,
    scrollbarOpacity: 0.4,
    cursorColour: '#d0d0d0',
  },
  fontStyle: {
    size: 14,
    weight: '400',
    family: "'Roboto Condensed', sans-serif",
  },
  startHats: true,
});

export const WorkspaceOption = {
  collapse: true,
  comments: true,
  css: true,
  disable: true,
  horizontalLayout: false,
  maxBlocks: Infinity,
  media: 'https://blockly-demo.appspot.com/static/media/',
  move: {
    drag: true,
    scrollbars: { vertical: true, horizontal: true },
    wheel: true,
  },
  oneBasedIndex: false,
  readOnly: false,
  renderer: 'geras',
  rtl: false,
  scrollbars: { vertical: true, horizontal: true },
  sounds: true,
  toolbox: blocklyToolbox,
  theme: darkTheme,
  grid: {
    colour: '#f2f0f0',
    length: 1,
    snap: true,
    spacing: 30,
  },
  trashcan: true,
  maxTrashcanContents: 10,
  maxInstances: {
    rule_base: 1,
  },
  zoom: {
    controls: true,
    maxScale: 5,
    minScale: 0.5,
    pinch: true,
    scaleSpeed: 1.1,
    startScale: 1,
    wheel: false,
  },
};
