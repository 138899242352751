import ReactApexChart from 'react-apexcharts';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  useTheme,
} from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import { Header, StatBox } from 'components';
import { tokens } from 'theme';
import { useEffect, useState } from 'react';
import Axios from 'services/api/Config';
import { accountStatsURL } from 'services/api/routes/accounts';
import { useAuthUser } from 'utils/context/AuthUserContext';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isDark = theme.palette.mode === 'dark';
  const { accessAccount } = useAuthUser();

  const [isLoading, setIsLoading] = useState(false);
  const [statsDetails, setStatsDetails] = useState({});

  const lineChartConfig = {
    series: [
      {
        name: 'Total Transactions',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        // data: [500, 1000, 1500, 2000, 1800, 4000, 5500, 5900, 6400, 6100, 6400, 7000],
      },
      {
        name: 'Transactions Flagged',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        // data: [50, 100, 120, 170, 80, 200, 444, 400, 500, 2000, 1000, 1500],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        foreColor: isDark ? '#E0E0E0' : '#141414',
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 5,
      },
      dataLabels: {
        enabled: false,
      },
      colors: [colors.blueAccent[500], colors.greenAccent[500]],
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Transactions over time',
        align: 'left',
        offsetY: 10,
        style: {
          fontSize: '20px',
          fontWeight: 600,
        },
      },
      tooltip: {
        theme: theme.palette.mode,
      },
      grid: {
        row: {
          colors: [isDark ? colors.grey[400] : colors.grey[700], 'transparent'],
          opacity: 0.4,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    },
  };

  useEffect(() => {
    setIsLoading(true);
    Axios({
      ...accountStatsURL(accessAccount?.id),
      params: {
        stat_names: 'total_transactions,transactions_flagged',
      },
    })
      .then((response) => {
        const res = response.data;
        setStatsDetails(res);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessAccount?.id]);

  return (
    <Box m='20px'>
      <Header title='Dashboard' className='mb-3' />

      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4'>
        {isLoading ? (
          <>
            <Skeleton variant='rectangular' animation='wave' height={128} />
            <Skeleton variant='rectangular' animation='wave' height={128} />
          </>
        ) : (
          <>
            <Card
              className='h-32'
              sx={{
                backgroundColor: colors.primary[400],
              }}
            >
              <CardActionArea className='h-full'>
                <CardContent>
                  <StatBox
                    amount={statsDetails?.total_transactions ?? 0}
                    title='Total Transactions'
                    icon={
                      <PaidIcon
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </CardContent>
              </CardActionArea>
            </Card>
            <Card
              className='h-32'
              sx={{
                backgroundColor: colors.primary[400],
              }}
            >
              <CardActionArea className='h-full'>
                <CardContent>
                  <StatBox
                    amount={statsDetails?.transactions_flagged ?? 0}
                    title='Transactions Flagged'
                    icon={
                      <ReceiptLongIcon
                        sx={{
                          color: colors.greenAccent[600],
                          fontSize: '26px',
                        }}
                      />
                    }
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </>
        )}
      </div>

      <div className='flex mt-5'>
        <Card
          className='basis-full'
          sx={{
            backgroundColor: colors.primary[400],
          }}
        >
          <ReactApexChart
            options={lineChartConfig?.options}
            series={lineChartConfig?.series}
            type='line'
            height={350}
          />
        </Card>
      </div>
    </Box>
  );
};

export default Dashboard;
