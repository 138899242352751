import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { BackdropLoader } from 'components';
import { useAuthUser } from 'utils/context/AuthUserContext';

import AuthenticationGuard from './auth0/AuthenticationGuard';
import PrivateRoutes from './PrivateRoutes';
import AuthRoutes from './AuthRoutes';

const Router = () => {
  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const { authUser, isFetching: isAuthUserLoading } = useAuthUser();

  if ((isAuth0Loading || isAuthUserLoading || authUser === null) && isAuthenticated) {
    return <BackdropLoader />;
  }

  return (
    <Routes>
      {!isAuthenticated ? (
        <>
          <Route path='auth/*' element={<AuthRoutes />} />
          <Route index element={<Navigate to={'/auth/login'} />} />
        </>
      ) : null}
      <Route path='/*' element={<AuthenticationGuard component={PrivateRoutes} />} />
    </Routes>
  );
};

export default Router;
