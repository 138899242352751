import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  useTheme,
} from '@mui/material';

import { HFTextInput, Header } from 'components';

import Axios from 'services/api/Config';
import { tokens } from 'theme';
import { getDDTypesURL } from 'services/api/routes/dynamicData';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { debounce } from 'utils/lodashImports';

const DataList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const { accessAccount } = useAuthUser();

  const pageLimit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchVal, setSearchVal] = useState('');
  const [showMore, setShowMore] = useState(true);

  const [typesList, setTypesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Axios({
      ...getDDTypesURL,
      params: {
        account_id: accessAccount?.id,
        page: currentPage,
        limit: pageLimit,
        display_name: searchVal,
      },
    })
      .then((response) => {
        const res = response.data;
        if (currentPage === 1) {
          setTypesList(res);
          setShowMore(true);
        } else {
          setTypesList((prev) => [...prev, ...res]);
        }
        if (res.length === 0 || res.length < pageLimit) {
          setShowMore(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessAccount?.id, currentPage, searchVal]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleOnSearch = debounce((value) => {
    setSearchVal(value);
    setCurrentPage(1);
  }, 800);

  return (
    <>
      <Box m='20px'>
        <Header title='Data' />
        <div className='flex flex-col items-center '>
          <div className='mb-3 text-base'>Select a Data Type to View</div>

          <HFTextInput
            isController={false}
            handleOnChange={handleOnSearch}
            name='search'
            label='Search...'
            classes={{ wrapper: 'w-96 mb-3' }}
          />

          <Card
            className='w-full h-full md:w-1/2 lg:w-2/5'
            variant='outlined'
            sx={{ backgroundColor: colors.primary[600] }}
          >
            <CardContent className='flex flex-col items-center gap-3 text-center !pb-4'>
              {typesList.map((element) => {
                return (
                  <Card
                    key={element?.id}
                    className='w-full'
                    sx={{ backgroundColor: colors.primary[400] }}
                  >
                    <CardActionArea
                      onClick={() =>
                        navigate(`/data/${element?.id}?ddtId=${element?.name}`)
                      }
                    >
                      <CardContent>{element?.display_name}</CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
              {typesList.length > 0 && !isLoading && showMore ? (
                <Button
                  variant='text'
                  color='primary'
                  size='medium'
                  sx={{ textTransform: 'none' }}
                  onClick={handleLoadMore}
                >
                  Show More
                </Button>
              ) : null}
              {/* {!showMore && typesList.length > 0 && !isLoading && (
								<>No More Data Types to Display</>
							)} */}
              {typesList.length === 0 && !isLoading && <>No Data Types Found</>}
              {isLoading && <CircularProgress />}
            </CardContent>
          </Card>
        </div>
      </Box>
    </>
  );
};

export default DataList;
