import * as Blockly from 'blockly';

export class RemoveIcon extends Blockly.icons.Icon {
  clickHandler = null;

  constructor(sourceBlock, clickHandler) {
    super(sourceBlock);
    this.clickHandler = clickHandler;
  }

  getType() {
    return new Blockly.icons.IconType('remove_icon');
  }

  initView(pointerEvent) {
    if (this.svgRoot) return;
    super.initView(pointerEvent);
    this.svgRoot.setAttribute('width', 24);
    this.svgRoot.setAttribute('height', 24);
    this.svgRoot.setAttribute('viewBox', '0 0 24 24');
    this.svgRoot.setAttribute('stroke', 'white');
    this.svgRoot.setAttribute('stroke-width', '2');
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      { stroke: 'none', d: 'M0 0h24v24H0z', fill: 'none' },
      this.svgRoot
    );
    Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      { d: 'M5 12l14 0' },
      this.svgRoot
    );
  }

  getSize() {
    return new Blockly.utils.Size(24, 24);
  }

  getWeight() {
    return 2;
  }

  onClick() {
    if (this.sourceBlock.isInFlyout) return;
    this.clickHandler?.();
  }

  isShownWhenCollapsed() {
    return false;
  }

  dispose() {
    super.dispose();
  }
}
