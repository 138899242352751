import {
  Backdrop,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import useUpdateRule from '../hooks/useUpdateRule';
import { serialization } from 'blockly';
import { jsonGenerator } from 'blockly/json';
import { useSnackbar } from 'notistack';
import Breadcrumb from 'layout/global/Breadcrumb';

export default function TopBar(props) {
  const { workspace } = props;
  const { palette } = useTheme();
  const { updateRule, loading, error } = useUpdateRule();
  const { enqueueSnackbar } = useSnackbar();

  const onSave = async () => {
    try {
      if (workspace) {
        const blockly_definition = serialization.workspaces.save(workspace);
        const code = jsonGenerator.workspaceToCode(workspace);
        const definition = JSON.parse(code);
        await updateRule({ definition, blockly_definition });
        enqueueSnackbar('Logic saved successfully!', { variant: 'success' });
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      minHeight={64}
      py={1}
      px={2}
      flex={1}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Breadcrumb />
      </Stack>
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Button
          variant='contained'
          disabled={!workspace?.allInputsFilled()}
          onClick={onSave}
        >
          <Typography variant='button'>Save Rules</Typography>
        </Button>
      </Stack>
      <Backdrop open={loading} sx={{ zIndex: 5000 }}>
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            backgroundColor: palette.background.default,
            height: '100vh',
            width: '100vw',
          }}
        >
          <CircularProgress />
        </Stack>
      </Backdrop>
    </Stack>
  );
}
