import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { Box, IconButton, Typography, useTheme } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DataObjectIcon from '@mui/icons-material/DataObject';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ArticleIcon from '@mui/icons-material/Article';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

import { tokens } from 'theme';
import { checkActiveTab, isLocal, isProd } from 'utils/common';

import 'react-pro-sidebar/dist/css/styles.css';

const Item = ({ title, to, icon, className, href }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const location = useLocation();
  const { pathname } = location;

  return (
    <MenuItem
      active={checkActiveTab(to, pathname)}
      icon={icon}
      className={className}
      style={{ color: colors.grey[100] }}
    >
      <Typography fontSize={16}>{title}</Typography>
      {to && <Link to={to} />}
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      {href && <a href={href} target='_blank' rel='noreferrer' />}
    </MenuItem>
  );
};

const SubItem = ({ title, icon, children, noStyle = false, to }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={
        noStyle
          ? {
              '& .pro-sub-menu > .pro-inner-item:hover': {
                color: `${colors.grey[100]} !important`,
                cursor: 'default',
              },
            }
          : {}
      }
    >
      <SubMenu
        open={true}
        icon={icon}
        title={
          <Typography fontSize={16}>
            {title}
            {to && <Link to={to} />}
          </Typography>
        }
        style={{ color: colors.grey[100] }}
      >
        {children}
      </SubMenu>
    </Box>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-sidebar-layout': {
          justifyContent: 'space-between',
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#868dfb !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
        '& .pro-arrow-wrapper': {
          display: 'none',
        },
        '& .pro-icon ': {
          animation: 'none !important',
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape='square'>
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: '10px 0 20px 0',
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                ml='15px'
              >
                <Typography variant='h3' color={colors.grey[100]}>
                  Byte Detection
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item to='/dashboard' title='Dashboard' icon={<HomeOutlinedIcon />} />
            <Item to='/rules' title='Rules' icon={<CrisisAlertIcon />} />
            <Item to='/data' title='Data' icon={<DataObjectIcon />} />
            <Item to='/lists' title='Lists' icon={<FormatListBulletedIcon />} />

            {isLocal() && <Item to='/api-docs' title='API Docs' icon={<ArticleIcon />} />}
            {false && isLocal() && (
              <Item
                to='/ai_fraud_analysts'
                title='AI Fraud Analysts'
                icon={<AllInclusiveIcon />}
              />
            )}
            {false && isLocal() && (
              <Item to='/ai_chat' title='AI Chat' icon={<PrecisionManufacturingIcon />} />
            )}
            <SubItem icon={<SettingsIcon />} title='Account' noStyle>
              <Item to='/account/users' title='Users' icon={<PeopleAltIcon />} />
              <Item to='/account/api_key' title='API Keys' icon={<KeyIcon />} />
              <Item to='/account/teams' title='Teams' icon={<Diversity3Icon />} />
              {isProd() && (
                <Item
                  title='Billing'
                  icon={<ReceiptIcon />}
                  href={'https://billing.stripe.com/p/login/3csaHVdYkcreeUU3cc'}
                />
              )}
            </SubItem>
            <Item to='/user' title='My User' icon={<PersonIcon />} />
          </Box>
        </Menu>
        <Menu iconShape='square'>
          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item
              title='Contact Support'
              icon={<SupportAgentIcon />}
              href={'mailto:chalmers@bytedetection.com'}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;

Item.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
};

SubItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  noStyle: PropTypes.bool,
  title: PropTypes.string,
};
