import React from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { BackdropLoader } from 'components';

const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <BackdropLoader />,
  });

  return <Component />;
};

AuthenticationGuard.propTypes = {
  component: PropTypes.any,
};

export default AuthenticationGuard;
