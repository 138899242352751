import { DropDownDiv, Field, FieldDropdown, fieldRegistry, utils } from 'blockly';
import { createRoot } from 'react-dom/client';
import useDataTypes from './useDataTypes';
import { useEffect } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Check } from '@mui/icons-material';

export class FieldDataType extends Field {
  SERIALIZABLE = true;
  CURSOR = 'pointer';
  value_ = {
    DT: 'Select Field',
    SFO: [],
    IF: '',
    V: '',
  };

  initView() {
    super.initView();
    if (this.getConstants()?.FIELD_DROPDOWN_SVG_ARROW) {
      this.createSVGArrow_();
    } else {
      this.createTextArrow_();
    }
  }

  createTextArrow_() {
    this.arrow = utils.dom.createSvgElement(
      utils.Svg.TSPAN,
      { fill: this.sourceBlock_.style.colourPrimary },
      this.textElement_
    );
    this.arrow?.appendChild(
      document.createTextNode(
        this.getSourceBlock()?.RTL
          ? FieldDropdown.ARROW_CHAR + ' '
          : ' ' + FieldDropdown.ARROW_CHAR
      )
    );
    if (this.getSourceBlock()?.RTL) {
      this.getTextElement().insertBefore(this.arrow, this.textContent_);
    } else {
      this.getTextElement().appendChild(this.arrow);
    }
  }

  createSVGArrow_() {
    this.svgArrow = utils.dom.createSvgElement(
      utils.Svg.IMAGE,
      {
        height: this.getConstants()?.FIELD_DROPDOWN_SVG_ARROW_SIZE + 'px',
        width: this.getConstants()?.FIELD_DROPDOWN_SVG_ARROW_SIZE + 'px',
      },
      this.fieldGroup_
    );
    this.svgArrow?.setAttributeNS(
      utils.dom.XLINK_NS,
      'xlink:href',
      this.getConstants()?.FIELD_DROPDOWN_SVG_ARROW_DATAURI
    );
  }

  static fromJson(options) {
    return new this(options['text']);
  }

  saveState() {
    return {
      value: this.getValue(),
    };
  }

  loadState(state) {
    this.setValue(state.value);
  }

  getDisplayText_() {
    return this.getValue().DT || this.getValue().V;
  }

  onItemSelect = (field) => {
    const value = {};
    value.IF = field.idField;
    value.SFO = field.subFieldOptions;
    value.DT = field.displayText;
    value.V = field.value;
    this.setValue(value);
    DropDownDiv.hideIfOwner(this, true);
  };

  showEditor_() {
    this.div_ = DropDownDiv.getContentDiv();
    this.root_ = createRoot(this.div_);
    this.root_.render(this.render());
  }

  dropdownDispose() {
    if (this.root_) this.root_.unmount();
  }

  render() {
    return (
      <FieldDataTypeDropdown
        value={this.getValue().V}
        field={this}
        onItemSelect={this.onItemSelect}
      />
    );
  }
}

function FieldDataTypeDropdown(props) {
  const { value, onItemSelect, field } = props;
  const { data, loading } = useDataTypes();

  useEffect(() => {
    if (!loading && field) {
      window.requestAnimationFrame(() => {
        DropDownDiv.showPositionedByField(field, field.dropdownDispose.bind(field));
      });
    }
  }, [loading, field]);

  return (
    <List dense sx={{ padding: 0 }}>
      {Array.from(data || []).map((item) => {
        const { name, display_name, id_field, fields, id } = item;
        const options = [];
        Array.from(fields || []).forEach((field) => {
          const { type, name } = field;
          options.push([name, `${name}**${type}`]);
        });
        return (
          <ListItemButton
            dense
            disableGutters
            key={id}
            sx={{ paddingX: 1 }}
            onClick={() =>
              onItemSelect({
                idField: id_field,
                value: name,
                displayText: display_name,
                subFieldOptions: options,
              })
            }
          >
            {value === name && (
              <ListItemIcon sx={{ minWidth: 'auto !important' }}>
                <Check sx={{ paddingRight: 1 }} />
              </ListItemIcon>
            )}
            <ListItemText
              primary={display_name}
              sx={{ color: '#212121 !important' }}
            ></ListItemText>
          </ListItemButton>
        );
      })}
    </List>
  );
}

fieldRegistry.register('field_data_type', FieldDataType);
