export const TEAMS_STATUS_TYPE = {
  DEACTIVATED: 'deactivated',
  ACTIVE: 'active',
};

export const teamsStatusBadgeColor = (status) => {
  switch (status) {
    case TEAMS_STATUS_TYPE.DEACTIVATED:
      return 'error';
    case TEAMS_STATUS_TYPE.ACTIVE:
      return 'success';
    default:
      return 'default';
  }
};
