import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';

import { Table } from 'components';
import { tokens } from 'theme';

const FieldsModel = (props) => {
  const { fieldsModel = false, handleFieldsClose, typeDetail, fields = [] } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClose = () => {
    handleFieldsClose();
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Field Name',
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => <>{row?.name}</>,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => <div className='capitalize'>{row?.type}</div>,
    },
  ];

  return (
    <Dialog
      maxWidth='sm'
      fullWidth={true}
      open={fieldsModel}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: colors.primary[500],
        },
      }}
    >
      <DialogTitle id='alert-dialog-title'>{typeDetail?.display_name} Fields</DialogTitle>
      <DialogContent className='max-h-[580px]'>
        <Table
          rowID='name'
          columns={columns}
          tableDATA={fields}
          hideFooter={true}
          showPagination={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose} sx={{ textTransform: 'none' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FieldsModel.propTypes = {
  fields: PropTypes.array,
  fieldsModel: PropTypes.bool,
  handleFieldsClose: PropTypes.func,
  typeDetail: PropTypes.object,
};

export default FieldsModel;
