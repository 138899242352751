import { CircularProgress } from '@mui/material';
import React from 'react';

const SuspenseLoader = () => {
  return (
    <div className='flex flex-col items-center justify-center h-full'>
      <div>
        <span className='text-3xl'>Byte Detection</span>
      </div>
      <div className='mt-4'>
        <CircularProgress color='secondary' size='25px' />
      </div>
    </div>
  );
};

export default SuspenseLoader;
