// Teams
export const teamsListURL = {
  url: 'teams',
  method: 'GET',
};

export const addTeamsURL = {
  url: 'teams',
  method: 'POST',
};

export const getTeamByIdURL = (team_id) => ({
  url: `teams/${team_id}`,
  method: 'GET',
});

export const updateTeamURL = (team_id) => ({
  url: `teams/${team_id}`,
  method: 'PUT',
});

export const disableTeamURL = (team_id) => ({
  url: `teams/${team_id}`,
  method: 'DELETE',
});

export const getTeamUsersListURL = (team_id) => ({
  url: `teams/${team_id}/users`,
  method: 'GET',
});

export const addTeamUsersURL = (team_id) => ({
  url: `teams/${team_id}/users`,
  method: 'POST',
});

export const deleteTeamUsersURL = (team_id) => ({
  url: `teams/${team_id}/users`,
  method: 'DELETE',
});
