import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Chip, CircularProgress, Typography, useTheme } from '@mui/material';

import { Header } from 'components';
import { tokens } from 'theme';

import ActionsTable from './components/ActionsTable';
import ChatsTable from './components/ChatsTable';

const AIFraudAnalystsDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let { analystsId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [analystsDetail, setAnalystsDetail] = useState(null);

  const fetch = useCallback(() => {
    setIsLoading(true);
    setAnalystsDetail({
      id: 1,
      title: 'Run KYC Checks',
      description: 'Use available KYC vendors to determine level of risk for customers',
      status: 'active',
      created_at: 1694999496,
    });
    setIsLoading(false);
    // Axios({
    //   ...getRulesByIdURL(ruleId),
    //   params: { account_id: accessAccount?.id },
    // })
    //   .then((response) => {
    //     const res = response.data;
    //     setValue('description', res.description);
    //     setValue('title', res.title);
    //     setRuleDetails(res);
    //   })
    //   .catch(() => navigate('/ai_fraud_analysts'))
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  }, []);

  useEffect(() => {
    if (analystsId) {
      fetch();
    } else {
      navigate('/ai_fraud_analysts');
    }
  }, [fetch, navigate, analystsId]);

  const statusBadgeColor = (status) => {
    switch (status) {
      case 'active':
        return 'success';
      default:
        return 'default';
    }
  };

  return (
    <Box m='20px'>
      {isLoading ? (
        <div className='w-full my-4 text-center'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <>
          <div className='flex items-center gap-3 mb-3'>
            <Header title={analystsDetail?.title} />
            <Chip
              size='medium'
              label={`${analystsDetail?.status}`}
              color={`${statusBadgeColor(analystsDetail?.status)}`}
              className='text-2xl font-bold capitalize'
            />
          </div>
          <Typography
            variant='h4'
            gutterBottom
            color={colors.grey[100]}
            className='whitespace-pre-line'
          >
            {analystsDetail?.description}
          </Typography>

          {analystsDetail ? (
            <>
              <ActionsTable />
              <ChatsTable />
            </>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default AIFraudAnalystsDetails;
