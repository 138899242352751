import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
// import { getDataFromObject } from "../../../../utils/common";

const HFTextInput = (props) => {
  const {
    name,
    label,
    control,
    isDisabled = false,
    multiline = false,
    defaultValue,
    type = 'text',
    isController = true,
    autoFocus = false,
    note = false,
    classes = {},
    handleOnChange,
    placeholder,
    ...extraProps
  } = props;

  const {
    wrapper: wrapperClass = '',
    field: fieldClass = '',
    error: errorClass = '',
  } = classes;

  if (!isController) {
    return (
      <div className={`${wrapperClass}`}>
        <TextField
          {...extraProps}
          type={type}
          color='secondary'
          fullWidth
          size='small'
          label={label}
          multiline={multiline}
          rows={multiline ? 3 : 1}
          placeholder={placeholder ?? label}
          disabled={isDisabled}
          className={fieldClass}
          autoFocus={autoFocus}
          readOnly={isDisabled}
          onChange={(data) => {
            if (handleOnChange) {
              handleOnChange(data?.target?.value, name);
            }
          }}
        />
        {note && <small>{note}</small>}
      </div>
    );
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <div className={wrapperClass}>
            <TextField
              {...field}
              color='secondary'
              fullWidth
              size='small'
              label={label}
              className={fieldClass}
              type={type}
              multiline={multiline}
              rows={multiline ? 3 : 1}
              placeholder={placeholder ?? label}
              error={!!error}
              disabled={isDisabled}
              autoFocus={autoFocus}
              onChange={(e) => {
                field.onChange(e.target.value);
                !!handleOnChange && handleOnChange(e);
              }}
            />
            {note && <p className='small'>{note}</p>}
            {error && (
              <span className={`text-red-500 small ${errorClass}`}>{error.message}</span>
            )}
          </div>
        )}
      />
    </>
  );
};

HFTextInput.propTypes = {
  autoFocus: PropTypes.bool,
  classes: PropTypes.shape({
    error: PropTypes.string,
    field: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  handleOnChange: PropTypes.func,
  isController: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  note: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

export default HFTextInput;
