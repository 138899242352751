import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import { Header, Table } from 'components';
import { tokens } from 'theme';

import Axios from 'services/api/Config';
import { getFormattedDate } from 'utils/common';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { getUsersByIdURL, getUsersPermissionByIdURL } from 'services/api/routes/users';

import EditUserPermissions from './EditUserPermissions';

const UsersDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let { userId } = useParams();
  const navigate = useNavigate();
  const { accessAccount } = useAuthUser();

  const [userDetail, setUserDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [editPermissionsModel, setEditPermissionsModel] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(false);

  const tableDataURL = useMemo(
    () => ({ ...getUsersPermissionByIdURL(userId) }),
    [userId]
  );

  useEffect(() => {
    setIsLoading(true);
    Axios({
      ...getUsersByIdURL(userId),
      params: {
        account_id: accessAccount?.id,
      },
    })
      .then((response) => {
        const res = response.data;
        setUserDetail(res);
      })
      .catch(() => {
        navigate('/dashboard');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessAccount?.id, navigate, userId]);

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      minWidth: 80,
      maxWidth: 120,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 220,
      flex: 1.3,
    },
    {
      field: 'created',
      headerName: 'Created At',
      minWidth: 120,
      flex: 0.7,
      renderCell: ({ row: { created } }) => <>{getFormattedDate(created * 1000)}</>,
    },
  ];

  const handleEditPermissionsClose = () => setEditPermissionsModel(false);
  const handleEditPermissionsOpen = () => setEditPermissionsModel(true);

  return (
    <>
      <Box m='20px'>
        {isLoading ? (
          <div className='w-full my-4 text-center'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <>
            <Header title='User' />
            <div className='mb-8'>
              <Typography
                variant='h4'
                gutterBottom
                color={colors.grey[100]}
                className='!mt-3'
              >
                Name: {userDetail?.name}
              </Typography>
              <Typography
                variant='h4'
                gutterBottom
                color={colors.grey[100]}
                className='!mt-2'
              >
                Email: {userDetail?.email}
              </Typography>
            </div>

            <div className='flex justify-between mb-1'>
              <Typography
                variant='h3'
                gutterBottom
                color={colors.grey[100]}
                className='capitalize'
              >
                Permissions
              </Typography>
              <div>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    textTransform: 'none',
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                  }}
                  startIcon={<BorderColorIcon />}
                  onClick={handleEditPermissionsOpen}
                >
                  Edit Permissions
                </Button>
              </div>
            </div>
            {userDetail ? (
              <Table
                columns={columns}
                dataURL={tableDataURL}
                hideFooter={true}
                showPagination={false}
                checkEqual={tableRefresh}
              />
            ) : null}
          </>
        )}
      </Box>
      <EditUserPermissions
        handleTableRefresh={setTableRefresh}
        editModel={editPermissionsModel}
        handleModelClose={handleEditPermissionsClose}
      />
    </>
  );
};

export default UsersDetails;
