import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { HFAutoComplete, HFTextInput } from 'components';
import Axios from 'services/api/Config';
import { tokens } from 'theme';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { addListsURL } from 'services/api/routes/lists';
import { useNavigate } from 'react-router';
import { LIST_OPTIONS } from 'constants/List.constants';

const addListSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  description: Yup.string().trim().required('List Description is required'),
  list_type: Yup.string().required('List type is required.'),
});

const AddListModel = (props) => {
  const { openModel = false, handleModelClose, setTableRefresh } = props;

  const navigate = useNavigate();
  const { accessAccount } = useAuthUser();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    name: '',
    description: '',
    list_type: '',
  };

  const {
    reset,
    handleSubmit,
    control: listControl,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(addListSchema),
  });

  const onSubmit = (data) => {
    const tData = {
      ...data,
      account_id: accessAccount?.id,
    };
    setIsLoading(true);
    Axios({ ...addListsURL, data: tData })
      .then((res) => {
        handleClose();
        setTableRefresh((prev) => !prev);
        navigate(`/lists`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    reset(initialValues);
    handleModelClose();
  };

  return (
    <>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={openModel}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: colors.primary[500],
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id='alert-dialog-title' className='!text-lg !font-bold'>
            Create List
          </DialogTitle>
          <DialogContent>
            <div className='mt-2'>
              <HFAutoComplete
                id='list_type'
                name='list_type'
                label='Type'
                placeholder='Select type...'
                control={listControl}
                selectPluginConfig={{
                  isMulti: false,
                  isClearable: false,
                }}
                classes={{ wrapper: 'mb-3' }}
                fillOptions={{ options: LIST_OPTIONS }}
              />
              <HFTextInput
                id='name'
                name='name'
                label='Name'
                control={listControl}
                classes={{ wrapper: 'mb-3' }}
              />
              <HFTextInput
                id='description'
                name='description'
                label='Description'
                multiline={true}
                control={listControl}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant='outlined'
              color='error'
              sx={{
                textTransform: 'none',
                color: colors.grey[100],
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              color='secondary'
              variant='contained'
              loading={isLoading}
              loadingPosition='center'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

AddListModel.propTypes = {
  handleModelClose: PropTypes.func,
  openModel: PropTypes.bool,
};

export default AddListModel;
