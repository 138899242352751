import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DefaultLayout from 'layout/DefaultLayout';
import ChatLayout from 'layout/ChatLayout';

import CallBack from 'pages/Auth/CallBack';

import Rules from 'pages/rules';
import UserSetting from 'pages/User';
import Dashboard from 'pages/dashboard';
import RulesDetails from 'pages/rules/RulesDetails';
import { EditLogic } from 'pages/EditLogic';

import DataList from 'pages/data/Data';
import DataTypesTable from 'pages/data/DataTypesTable';

//Account
import UsersTable from 'pages/account/UserTable';
import TeamsTable from 'pages/account/TeamsTable';
import APIKeyTable from 'pages/account/APIKeyTable';
import TeamsDetail from 'pages/account/components/teams/TeamsDetail';
import UsersDetails from 'pages/account/components/Users/UserDetails';

import AIChat from 'pages/AIChat';
import AIFraudAnalysts from 'pages/AIFraudAnalysts';
import AIFraudAnalystsDetails from 'pages/AIFraudAnalysts/AIFraudAnalystsDetails';
import ApiDocLayout from 'layout/ApiDocLayout';
import APIDoc from 'pages/APIDoc';
import Lists from 'pages/lists';
import ListDetails from 'pages/lists/ListDetails';

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path='/accounts' element={<CallBack />} />
      <Route path='/' element={<DefaultLayout />}>
        <Route index element={<Navigate to='dashboard' replace />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='rules' element={<Rules />} />
        <Route path='rules/:ruleId' element={<RulesDetails />} />
        <Route path='lists' element={<Lists />} />
        <Route path='lists/:listId' element={<ListDetails />} />
        <Route path='data' element={<DataList />} />
        <Route path='data/:typeId' element={<DataTypesTable />} />
        <Route path='account/api_key' element={<APIKeyTable />} />
        <Route path='account/users' element={<UsersTable />} />
        <Route path='account/teams' element={<TeamsTable />} />
        <Route path='account/teams/:teamId' element={<TeamsDetail />} />
        <Route path='account/users/:userId' element={<UsersDetails />} />
        <Route path='user' element={<UserSetting />} />
        <Route path='ai_fraud_analysts' element={<AIFraudAnalysts />} />
        <Route
          path='ai_fraud_analysts/:analystsId'
          element={<AIFraudAnalystsDetails />}
        />
        <Route path='*' element={<Navigate to='/dashboard' />} />
      </Route>
      <Route path='/ai_chat' element={<ChatLayout />}>
        <Route index element={<AIChat />} />
      </Route>
      <Route path='/api-docs' element={<ApiDocLayout />}>
        <Route index element={<APIDoc />} />
      </Route>
      <Route path='rules/:ruleId/logic' element={<EditLogic />} />
    </Routes>
  );
};

export default PrivateRoutes;
