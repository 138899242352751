import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';

import Axios from 'services/api/Config';
import { HFTextInput, Header, MenuButton } from 'components';

import DecisionTable from './components/tables/DecisionTable';
import ActivityLogsTable from './components/tables/ActivityLogsTable';
import AISuggestionsTable from './components/tables/AISuggestionsTable';

import { tokens } from 'theme';
import { isEmpty } from 'utils/lodashImports';
import { useAuthUser } from 'utils/context/AuthUserContext';
import {
  getRulesByIdURL,
  updateRulesURL,
  activateRulesURL,
  enableRulesTestingURL,
  disableRulesTestingURL,
  disableRulesURL,
  ruleDecisionsListURL,
} from 'services/api/routes/rules';
import { RULE_STATUS_TYPE, ruleStatusBadgeColor } from 'constants/Rules.constants';

const ruleEditSchema = Yup.object().shape({
  description: Yup.string().trim().required('Description is required'),
  title: Yup.string().trim().required('Title is required'),
});

const RulesDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accessAccount } = useAuthUser();

  let { ruleId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [ruleDetails, setRuleDetails] = useState(null);
  const [decisionLength, setDecisionLength] = useState(0);
  const [isDecisionStep, setIsDecisionStep] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [alertModel, setAlertModel] = useState(false);
  const [alertLoading, setAlertLoading] = useState(false);

  const initialValues = {
    description: '',
    title: '',
  };

  const buttonStyle = {
    textTransform: 'none',
    color: colors.grey[100],
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: colors.blueAccent[700],
  };

  const modifiedButtonStyle = {
    ...buttonStyle,
    backgroundColor: colors.primary[600],
    border: `1px solid ${colors.grey[100]}`,
  };

  const {
    handleSubmit,
    setValue,
    control: ruleControl,
    reset,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(ruleEditSchema),
  });

  const fetch = useCallback(() => {
    setIsLoading(true);
    Axios({
      ...getRulesByIdURL(ruleId),
      params: { account_id: accessAccount?.id },
    })
      .then((response) => {
        const res = response.data;
        setValue('description', res.description);
        setValue('title', res.title);
        setRuleDetails(res);
      })
      .then(() => {
        Axios({
          ...ruleDecisionsListURL(ruleId),
          params: { account_id: accessAccount?.id, page: 1, limit: 1 },
        }).then((response) => {
          const res = response.data;
          setDecisionLength(res.length);
        });
        Axios({
          ...ruleDecisionsListURL(ruleId),
          params: {
            account_id: accessAccount?.id,
            page: 1,
            limit: 1,
            mode: 'test',
          },
        }).then((response) => {
          const res = response.data;
          setIsDecisionStep(res.length > 0 ? true : false);
        });
      })
      .catch(() => navigate('/rules'))
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessAccount?.id, navigate, ruleId, setValue]);

  useEffect(() => {
    if (ruleId) {
      fetch();
    } else {
      navigate('/rules');
    }
  }, [fetch, navigate, ruleId]);

  const handleRuleEdit = (val) => {
    setIsEdit(val);
  };

  const handleShowAlert = () => setAlertModel(true);
  const handleAlertClose = () => setAlertModel(false);

  const handleActivateRule = () => {
    setAlertLoading(true);
    Axios({
      ...activateRulesURL(ruleId),
      params: { account_id: accessAccount?.id },
    })
      .then((res) => fetch())
      .finally(() => {
        setAlertLoading(false);
        handleAlertClose();
      })
      .catch(() => navigate('/rules'));
  };

  const handleStartTestingRule = () => {
    setIsLoading(true);
    Axios({
      ...enableRulesTestingURL(ruleId),
      params: { account_id: accessAccount?.id },
    })
      .then((res) => fetch())
      .catch(() => navigate('/rules'));
  };

  const handleEndTestingRule = () => {
    setIsLoading(true);
    Axios({
      ...disableRulesTestingURL(ruleId),
      params: { account_id: accessAccount?.id },
    })
      .then((res) => fetch())
      .catch(() => navigate('/rules'));
  };

  const handleDeactivateRule = () => {
    setIsLoading(true);
    Axios({
      ...disableRulesURL(ruleId),
      params: { account_id: accessAccount?.id },
    })
      .then((res) => fetch())
      .catch(() => navigate('/rules'));
  };

  const onSubmit = (data) => {
    setIsSubmitLoading(true);
    Axios({
      ...updateRulesURL(ruleId),
      params: { account_id: accessAccount?.id },
      data: data,
    })
      .then((res) => fetch())
      .finally(() => {
        setIsSubmitLoading(false);
        handleRuleEdit(false);
      });
  };

  const handleCancelEdit = () => {
    reset({ description: ruleDetails?.description, title: ruleDetails?.title });
    handleRuleEdit(false);
  };

  return (
    <>
      <Box px={1} display={'flex'} flexDirection={'column'} flex={1} m={2}>
        {isLoading ? (
          <div className='w-full my-4 text-center'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex justify-between'>
                <div className='flex items-center gap-3 mb-3'>
                  {isEdit ? (
                    <div className='w-60'>
                      <HFTextInput
                        name='title'
                        label='Rule Title'
                        control={ruleControl}
                      />
                    </div>
                  ) : (
                    <Header title={ruleDetails?.title} />
                  )}
                  <Chip
                    size='medium'
                    label={`${ruleDetails?.status}`}
                    color={`${ruleStatusBadgeColor(ruleDetails?.status)}`}
                    className='text-2xl font-bold capitalize'
                  />
                </div>
                <div className='flex gap-3'>
                  {isEdit ? (
                    <>
                      <div>
                        <Button
                          onClick={handleCancelEdit}
                          variant='outlined'
                          color='error'
                          className='!me-3'
                          size='large'
                          sx={buttonStyle}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          type='submit'
                          variant='contained'
                          color='secondary'
                          size='large'
                          loading={isSubmitLoading}
                          loadingPosition='center'
                          onClick={handleSubmit(onSubmit)}
                          sx={activeButtonStyle}
                        >
                          Save
                        </LoadingButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <Button
                          variant={
                            isEmpty(ruleDetails?.definition) ? 'contained' : 'outlined'
                          }
                          color='secondary'
                          size='large'
                          sx={
                            isEmpty(ruleDetails?.definition)
                              ? activeButtonStyle
                              : modifiedButtonStyle
                          }
                          onClick={() => navigate(`/rules/${ruleId}/logic`)}
                        >
                          {ruleDetails?.status === RULE_STATUS_TYPE.ACTIVE
                            ? 'View '
                            : isEmpty(ruleDetails?.definition)
                            ? 'Add '
                            : 'Edit '}
                          Logic
                        </Button>
                        {[RULE_STATUS_TYPE.DRAFT].includes(ruleDetails?.status) && (
                          <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            className='!ms-3'
                            sx={activeButtonStyle}
                            onClick={handleStartTestingRule}
                          >
                            Enable Testing
                          </Button>
                        )}
                        {[RULE_STATUS_TYPE.TESTING].includes(ruleDetails?.status) && (
                          <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            className='!ms-3'
                            sx={activeButtonStyle}
                            onClick={handleEndTestingRule}
                          >
                            Disable Testing
                          </Button>
                        )}
                        {[RULE_STATUS_TYPE.DRAFT, RULE_STATUS_TYPE.TESTING].includes(
                          ruleDetails?.status
                        ) && (
                          <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            className='!ms-3'
                            sx={activeButtonStyle}
                            onClick={handleShowAlert}
                          >
                            Activate
                          </Button>
                        )}
                      </div>
                      <MenuButton icon={<MenuIcon />}>
                        {[
                          RULE_STATUS_TYPE.ACTIVE,
                          RULE_STATUS_TYPE.DRAFT,
                          RULE_STATUS_TYPE.TESTING,
                        ].includes(ruleDetails?.status) && (
                          <MenuItem onClick={() => handleRuleEdit(true)}>
                            <ListItemIcon>
                              <EditIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                          </MenuItem>
                        )}
                        {ruleDetails?.status !== RULE_STATUS_TYPE.DEACTIVATED && (
                          <MenuItem onClick={handleDeactivateRule}>
                            <ListItemIcon>
                              <DeleteIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Deactivate</ListItemText>
                          </MenuItem>
                        )}
                      </MenuButton>
                    </>
                  )}
                </div>
              </div>

              {/* {ruleDetails?.status === RULE_STATUS_TYPE.ACTIVE && !showLogic ? (
								<StatBox colors={colors} ruleDetails={ruleDetails} />
							) : null} */}

              {isEdit ? (
                <div className='mt-3'>
                  <HFTextInput
                    name='description'
                    label='Rule Description'
                    multiline
                    control={ruleControl}
                  />
                </div>
              ) : (
                <>
                  <Typography
                    variant='h4'
                    gutterBottom
                    color={colors.grey[100]}
                    className='whitespace-pre-line'
                  >
                    {ruleDetails?.description}
                  </Typography>
                </>
              )}
            </form>

            {![RULE_STATUS_TYPE.DEACTIVATED, RULE_STATUS_TYPE.ACTIVE].includes(
              ruleDetails?.status
            ) ? (
              <div className='flex justify-center mt-6'>
                <Card
                  className='w-full h-full lg:w-2/3 '
                  variant='outlined'
                  sx={{ backgroundColor: colors.primary[600] }}
                >
                  <CardContent className='flex flex-col items-center justify-around'>
                    <p className='mb-3 text-2xl font-bold text-gray-300'>
                      Here are your next steps
                    </p>
                    <ol className='text-base text-gray-300 list-decimal list-outside'>
                      <li
                        className={
                          isEmpty(ruleDetails?.definition)
                            ? ''
                            : 'line-through text-gray-500'
                        }
                      >
                        Add Logic to rule
                      </li>
                      <li className={isDecisionStep ? 'line-through text-gray-500' : ''}>
                        Test run with historical data
                      </li>
                      <li
                        className={
                          ruleDetails?.status === RULE_STATUS_TYPE.ACTIVE
                            ? 'line-through text-gray-500'
                            : ''
                        }
                      >
                        Activate rule so that it can run on live data
                      </li>
                    </ol>
                  </CardContent>
                </Card>
              </div>
            ) : null}

            {ruleDetails ? (
              <>
                {[RULE_STATUS_TYPE.TESTING, RULE_STATUS_TYPE.ACTIVE].includes(
                  ruleDetails?.status
                ) || decisionLength > 0 ? (
                  <DecisionTable ruleDetails={ruleDetails} />
                ) : null}
                {false &&
                  process.env.REACT_APP_ENV === process.env.REACT_APP_ENV_LOCAL && (
                    <AISuggestionsTable />
                  )}
                <ActivityLogsTable API_options={{ log_type: 'rule', obj_id: ruleId }} />
              </>
            ) : null}
          </>
        )}
      </Box>

      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={alertModel}
        onClose={handleAlertClose}
        scroll='paper'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{ '& .MuiDialog-paper': { backgroundColor: colors.primary[500] } }}
      >
        <DialogTitle id='alert-dialog-title' className='!font-bold !text-base'>
          Activate Rule
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <p>
              Are you sure you want to <strong>Activate </strong> this rule?
            </p>
            <p>it will start running in live transactions</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAlertClose}
            variant='outlined'
            color='error'
            sx={{
              textTransform: 'none',
              color: colors.grey[100],
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            type='submit'
            color='secondary'
            variant='contained'
            loading={alertLoading}
            loadingPosition='center'
            onClick={handleActivateRule}
            sx={{
              textTransform: 'none',
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
            }}
          >
            Activate Rule
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RulesDetails;
