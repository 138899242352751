import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { Table } from 'components';
import { useAuthUser } from 'utils/context/AuthUserContext';

import Axios from 'services/api/Config';
import { TEAMS_STATUS_TYPE } from 'constants/Teams.constants';

import { tokens } from 'theme';
import { getTeamUsersListURL, deleteTeamUsersURL } from 'services/api/routes/teams';
import AddUsersModel from './AddUsersModel';

const TeamUsersTable = (props) => {
  const { teamDetails } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let { teamId } = useParams();
  const { accessAccount } = useAuthUser();
  const tableDataURL = useMemo(() => ({ ...getTeamUsersListURL(teamId) }), [teamId]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [addUsersModel, setAddUsersModel] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(false);

  const [alertModel, setAlertModel] = useState(false);
  const [alertLoading, setAlertLoading] = useState(false);

  const entitiesColumns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 80,
      flex: 0.4,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 120,
      flex: 1,
    },
  ];

  const handleDeleteUsers = () => {
    setAlertLoading(true);
    Axios({
      ...deleteTeamUsersURL(teamId),
      data: selectedUsers,
      params: { account_id: accessAccount?.id },
    })
      .then((res) => {
        setSelectedUsers([]);
        handleAlertClose();
      })
      .finally(() => {
        setTableRefresh((prev) => !prev);
        setAlertLoading(false);
      });
  };

  const handleShowAlert = () => setAlertModel(true);
  const handleAlertClose = () => setAlertModel(false);

  const handleAddUsersClose = () => setAddUsersModel(false);
  const handleAddUsersOpen = () => setAddUsersModel(true);

  return (
    <>
      <div className='flex items-end justify-between mb-2'>
        <Typography
          variant='h3'
          gutterBottom
          color={colors.grey[100]}
          className='!mt-8 capitalize'
        >
          Users
        </Typography>
        {teamDetails?.status === TEAMS_STATUS_TYPE.ACTIVE && (
          <div className='flex gap-3'>
            {selectedUsers.length > 0 ? (
              <Button
                variant='outlined'
                color='error'
                sx={{
                  textTransform: 'none',
                  color: colors.grey[100],
                }}
                startIcon={<DeleteIcon color='error' />}
                onClick={handleShowAlert}
              >
                Delete Users
              </Button>
            ) : (
              <Button
                variant='contained'
                color='secondary'
                sx={{
                  textTransform: 'none',
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                }}
                startIcon={<AddIcon />}
                onClick={handleAddUsersOpen}
              >
                Add Users
              </Button>
            )}
          </div>
        )}
      </div>

      <Table
        columns={entitiesColumns}
        dataURL={tableDataURL}
        checkEqual={tableRefresh}
        rowSelection={teamDetails?.status === TEAMS_STATUS_TYPE.ACTIVE}
        handleSelection={(id) => setSelectedUsers(id)}
        rowSelectionModel={selectedUsers}
      />

      <AddUsersModel
        addModel={addUsersModel}
        handleTableRefresh={setTableRefresh}
        handleModelClose={handleAddUsersClose}
      />

      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={alertModel}
        onClose={handleAlertClose}
        scroll='paper'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{ '& .MuiDialog-paper': { backgroundColor: colors.primary[500] } }}
      >
        <DialogTitle id='alert-dialog-title' className='!font-bold !text-base'>
          Delete Users
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to <strong>Delete </strong> this Users?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAlertClose}
            variant='outlined'
            color='error'
            sx={{
              textTransform: 'none',
              color: colors.grey[100],
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            type='submit'
            color='secondary'
            variant='contained'
            loading={alertLoading}
            loadingPosition='center'
            onClick={handleDeleteUsers}
            sx={{
              textTransform: 'none',
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
            }}
          >
            Delete Users
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

TeamUsersTable.propTypes = {
  teamDetails: PropTypes.object,
};

export default TeamUsersTable;
