import React from 'react';
import PropTypes from 'prop-types';

import { Button, Menu, useTheme } from '@mui/material';

import { tokens } from 'theme';

const MenuButton = (props) => {
  const {
    id = 'Menu Button',
    menuId = 'popper-menu',
    children,
    icon,
    size = 'large',
    buttonVariant = 'outlined',
  } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id={id}
        startIcon={icon}
        size={size}
        variant={buttonVariant}
        aria-haspopup='true'
        onClick={handleClick}
        aria-controls={open ? 'fade-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        sx={{
          'textTransform': 'none',
          'borderColor': colors.blueAccent[700],
          'color': colors.grey[100],

          '& .MuiButton-startIcon': {
            marginRight: '-4px',
          },
        }}
      />
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': id }}
      >
        {children}
      </Menu>
    </div>
  );
};

MenuButton.propTypes = {
  buttonVariant: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  id: PropTypes.string,
  menuId: PropTypes.string,
};

export default MenuButton;
