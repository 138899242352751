import { useAuthUser } from 'utils/context/AuthUserContext';
import Breadcrumb from './Breadcrumb';

const Topbar = () => {
  const { accessAccount } = useAuthUser();

  return (
    <div className='flex justify-between p-4 pb-0'>
      <Breadcrumb />
      <div className='flex'>
        <div className='self-center text-base me-3'>{accessAccount?.name}</div>
      </div>
    </div>
  );
};

export default Topbar;
