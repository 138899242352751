import { jsonGenerator } from '../json';

jsonGenerator.forBlock['bd_actions'] = function (block) {
  const type = block.getFieldValue('DD0');
  const code = `
  {
    "name" : "open_investigation",
    "type" : "${type}"
  }
  `;
  return [code, 0];
};
