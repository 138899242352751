import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

import { Toast } from 'components';

import Emitter from 'utils/emitter';
import { EMITTER_CONST } from 'constants/Emitter.constants';

const requestHandler = (config) => {
  let token = localStorage.getItem('auth_token');

  if (!config.headers.Authorization) {
    config.headers.Authorization = token ? `Bearer ${token}` : null;
  }

  return Promise.resolve(config);
};

const errorHandler = async (error) => {
  if (!error.response) {
    Toast.error(error.message || 'Network error - something went wrong');
  }

  if ([500, 422].includes(error.response.status)) {
    Toast.error(error.response.statusText);
  }

  if (error.response && error.response.data) {
    if (error.response?.data?.detail?.message) {
      Toast.error(error.response?.data?.detail?.message);
      // Todo: might need to add navigate to dashboard.
    }

    if (['Unauthorized'].includes(error.response.statusText)) {
      Emitter.emit(EMITTER_CONST.FORCE_LOGOUT, {
        message: error.response?.data?.detail?.message,
      });
    }
  }
  return Promise.reject(error);
};

const Axios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'Allow-Access-Control-Allow-Origin': '*',
  },
});

//request interceptor
Axios.interceptors.request.use(
  (config) => requestHandler(config),
  (error) => Promise.reject(error)
);

//response interceptor
Axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => errorHandler(error)
);

export default Axios;

export const AxiosCached = setupCache(
  axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
      'Allow-Access-Control-Allow-Origin': '*',
    },
  })
);

//response interceptor
AxiosCached.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => errorHandler(error)
);

AxiosCached.interceptors.request.use(
  (config) => requestHandler(config),
  (error) => Promise.reject(error)
);
