import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Axios from 'services/api/Config';
import { getSelectOptions } from 'utils/common';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const HFAutoComplete = (props) => {
  const {
    name,
    label,
    placeholder = 'Select...',
    isController = true,
    control,
    defaultValue,
    options = [],
    handleOnChange = () => {},
    selectPluginConfig = {
      isClearable: true,
      isMulti: false,
    },
    isLoading = false,
    isDisabled = false,
    classes = {},
    inputNote = null,
    fillOptions,
    note = null,
    ...extraProps
  } = props;

  const {
    wrapper: wrapperClass = '',
    field: fieldClass = '',
    error: errorClass = '',
  } = classes;

  const { isClearable = true, isMulti = false } = selectPluginConfig;
  const { apiConfig } = fillOptions;

  const [optionsData, setOptionsData] = useState(options);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const optionsList =
    options?.length > 0 ? options : optionsData?.length > 0 ? optionsData : [];

  const obj = useCallback(() => ({ ...fillOptions.body }), [fillOptions.body]);

  const handleDefaultValue = (defaultValue) => {
    if (isMulti) {
      const result = optionsList.filter((option) => defaultValue.includes(option.value));
      return result;
    } else {
      const result = optionsList.find((obj) => obj?.value === defaultValue);
      return result;
    }
  };

  useEffect(() => {
    if (fillOptions?.byApi) {
      setIsLoadingData(true);
      Axios({
        url: apiConfig?.url,
        method: apiConfig?.method,
        params: apiConfig?.params,
        data: obj(),
      })
        .then((res) => {
          const list = res.data;
          setOptionsData(
            getSelectOptions(list, apiConfig?.labelField, apiConfig?.valueField)
          );
        })
        .catch((_err) => {})
        .finally(() => {
          setIsLoadingData(false);
        });
    } else {
      if (fillOptions?.options.length > 0) {
        setOptionsData(fillOptions?.options);
      } else {
        setOptionsData([]);
      }
    }
  }, [
    apiConfig?.labelField,
    apiConfig?.method,
    apiConfig?.params,
    apiConfig?.url,
    apiConfig?.valueField,
    fillOptions,
    fillOptions?.byApi,
    obj,
  ]);

  if (!isController) {
    return (
      <div className={`${wrapperClass}`}>
        <Autocomplete
          {...extraProps}
          size='small'
          name={name}
          className={fieldClass}
          placeholder={placeholder}
          loading={isLoading || isLoadingData}
          multiple={isMulti}
          disabled={isDisabled}
          disableClearable={!isClearable}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              {isMulti ? (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color='secondary'
                />
              ) : null}
              {option.label}
            </li>
          )}
          value={defaultValue ? handleDefaultValue(defaultValue) : undefined}
          onChange={(event, newValue) => {
            if (handleOnChange) {
              if (isMulti) {
                const res = newValue.map((val) => val?.value);
                handleOnChange(res, name);
              } else {
                handleOnChange(newValue, name);
              }
            }
          }}
          options={optionsList}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              label={label}
              color='secondary'
            />
          )}
        />
        {note && <small>{note}</small>}
      </div>
    );
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <div className={wrapperClass}>
            <Autocomplete
              {...extraProps}
              size='small'
              name={name}
              className={fieldClass}
              placeholder={placeholder}
              loading={isLoading || isLoadingData}
              multiple={isMulti}
              disabled={isDisabled}
              disableClearable={!isClearable}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              value={field.value ? handleDefaultValue(field.value) : undefined}
              onChange={(event, newValue) => {
                if (isMulti) {
                  const res = newValue.map((val) => val?.value);
                  field.onChange(res);
                  if (handleOnChange) {
                    handleOnChange(res, name);
                  }
                } else {
                  field.onChange(newValue?.value);
                  if (handleOnChange) {
                    handleOnChange(newValue?.value, name, newValue);
                  }
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  {isMulti ? (
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color='secondary'
                    />
                  ) : null}
                  {option.label}
                </li>
              )}
              options={optionsList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  label={label}
                  error={!!error}
                  color='secondary'
                />
              )}
            />
            {inputNote && <small>{inputNote}</small>}
            {error && (
              <span className={`text-red-500 small ${errorClass}`}>{error.message}</span>
            )}
          </div>
        )}
      />
      {note && <small>{note}</small>}
    </>
  );
};

HFAutoComplete.propTypes = {
  classes: PropTypes.shape({
    error: PropTypes.string,
    field: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  fillOptions: PropTypes.shape({
    apiConfig: PropTypes.shape({
      labelField: PropTypes.string,
      method: PropTypes.string,
      params: PropTypes.object,
      url: PropTypes.string,
      valueField: PropTypes.string,
    }),
    body: PropTypes.object,
    byApi: PropTypes.bool,
    options: PropTypes.array,
  }),
  handleOnChange: PropTypes.func,
  inputNote: PropTypes.string,
  isController: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  note: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  selectPluginConfig: PropTypes.shape({
    isClearable: PropTypes.bool,
    isMulti: PropTypes.bool,
  }),
};

export default HFAutoComplete;
