import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'services/api/Config';
import { useAuthUser } from 'utils/context/AuthUserContext';

function useGetRule() {
  const { ruleId } = useParams();
  const { accessAccount } = useAuthUser();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await Axios.get(`rules/${ruleId}`, {
          params: { account_id: accessAccount?.id },
        });
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return { data, loading, error };
}

export default useGetRule;
