export const LIST_OPTIONS = [
  { value: 'string', label: 'String' },
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone Number' },
  { value: 'ip_address', label: 'IP Address' },
];

export const listTypeBadgeColor = (list_type) => {
  switch (list_type) {
    case 'string':
      return 'secondary';
    case 'email':
      return 'secondary';
    case 'phone':
      return 'secondary';
    case 'ip_address':
      return 'secondary';
    default:
      return 'default';
  }
};
