import * as Blockly from 'blockly';
import { FieldDataType } from 'blockly/fields/FieldDataType';
import FieldDateInput from 'blockly/fields/FieldDateTime';
import { FieldList } from 'blockly/fields/FieldListDropdown';
import { AddIcon } from 'blockly/icons/AddIcon';
import { RemoveIcon } from 'blockly/icons/RemoveIcon';

Blockly.Blocks['bd_value_number'] = {
  init: function () {
    this.appendDummyInput().appendField(new Blockly.FieldNumber(0), 'F0');
    this.setOutput(true, 'number');
    this.setColour(20);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['bd_value_string'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput('Byte Detection'),
      'F0'
    );
    this.setOutput(true, 'string');
    this.setColour(20);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['bd_value_boolean'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ['True', 'true'],
        ['False', 'false'],
      ]),
      'F0'
    );
    this.setOutput(true, 'boolean');
    this.setColour(20);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['bd_value_percent'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldNumber(0, 0), 'F0')
      .appendField('%');
    this.setOutput(true, 'number');
    this.setColour(20);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['bd_value_field'] = {
  init: function () {
    this.appendDummyInput('D0')
      .appendField('Field')
      .appendField(new FieldDataType(), 'F0');
    this.setOutput(true, null);
    this.setColour(20);
    this.setTooltip('');
    this.setHelpUrl('');
    this.getField('F0').setValidator(this.validateF0.bind(this));
  },
  validateF0: function (value) {
    const options = value.SFO || [];
    this.getInput('D0').removeField('F1', true);
    this.getInput('D0').removeField('D', true);
    if (options.length === 0) options.push(['', '']);
    this.getInput('D0').appendField('.', 'D');
    this.getInput('D0').appendField(
      new Blockly.FieldDropdown(options, this.validateF1.bind(this)),
      'F1'
    );
    return value;
  },
  validateF1: function (value) {
    let returnType = value.split('**')[1];
    if (['integer', 'float', 'number', 'double'].includes(returnType)) {
      returnType = 'number';
    }
    this.setOutput(true, returnType);
    return value;
  },
};

Blockly.Blocks['bd_value_list'] = {
  init: function () {
    this.appendDummyInput('D0').appendField('List').appendField(new FieldList(), 'F0');
    this.setOutput(true, null);
    this.setColour(20);
    this.setTooltip('');
    this.setHelpUrl('');
    this.getField('F0').setValidator(this.validateF0.bind(this));
  },
  validateF0: function (value) {
    // console.info(value);
    // this.setOutput(true, value.T);
    return value;
  },
};

Blockly.Blocks['bd_value_time'] = {
  init: async function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ['Relative', 'relative'],
        ['Absolute', 'absolute'],
      ]),
      'F0'
    );
    this.setInputsInline(true);
    this.setOutput(true, 'datetime');
    this.setColour(20);
    this.setTooltip('');
    this.setHelpUrl('');
    this.getField('F0').setValidator(this.validateInput.bind(this));
    this.getField('F0').setValue('relative');
  },
  validateInput: function (value) {
    this.removeInput('DI0', true);
    if (value === 'absolute') {
      this.appendDummyInput('DI0').appendField(
        new FieldDateInput(new Date().toISOString()),
        'F1'
      );
    } else {
      this.appendDummyInput('DI0')
        .appendField(new Blockly.FieldNumber(0, 0, Infinity, 1), 'F1')
        .appendField(
          new Blockly.FieldDropdown([
            ['Minutes', 'minutes'],
            ['Hours', 'hours'],
            ['Days', 'days'],
            ['Weeks', 'weeks'],
            ['Months', 'months'],
            ['Quarters', 'quarters'],
            ['Years', 'years'],
          ]),
          'F2'
        )
        .appendField('ago');
    }
    return value;
  },
};

Blockly.Blocks['bd_value_filters'] = {
  init: function () {
    this.inputCount = 0;
    this.appendDummyInput().appendField('Make Filter List');
    this.setInputsInline(false);
    this.setOutput(true, 'filters');
    this.setColour(20);
    this.setTooltip('');
    this.setHelpUrl('');
    this.addIcon(new AddIcon(this, this.onAddInput.bind(this)));
    this.addIcon(new RemoveIcon(this, this.onRemoveInput.bind(this)));
  },
  saveExtraState: function () {
    return { inputCount: this.inputCount };
  },
  loadExtraState: function (state) {
    this.inputCount = state.inputCount;
    this.onUpdateShape();
  },
  onAddInput: function () {
    this.inputCount += 1;
    this.onUpdateShape();
  },
  onRemoveInput: function () {
    if (this.inputCount === 0) return;
    this.inputCount -= 1;
    this.onUpdateShape();
  },
  onUpdateShape: function () {
    if (this.isInsertionMarker()) return;
    Blockly.Events.setGroup(true);
    const inputBlocks = [];
    let i = 0;
    while (this.getInput(`EV${i}`)) {
      const block = this.getInputTargetBlock(`EV${i}`);
      block?.unplug(false);
      inputBlocks.push(block);
      this.removeInput(`EV${i}`, true);
      i += 1;
    }
    for (let j = 0; j < this.inputCount; j++) {
      const input = this.appendValueInput(`EV${j}`).setCheck('boolean');
      if (inputBlocks[j]) {
        input.connection.connect(inputBlocks[j].outputConnection);
      }
    }
    Blockly.Events.setGroup(false);
  },
};
