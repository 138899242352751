import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import moment from 'moment';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useState } from 'react';

const HFDateSelector = (props) => {
  const {
    name,
    label,
    control,
    isError = false,
    fullWidth = true,
    isDisabled = false,
    disableFuture = false,
    disablePast = false,
    minDate,
    maxDate,
    defaultValue,
    handleOnChange,
    autoFocus = false,
    isController = true,
    classes = {},
    ...extraProps
  } = props;

  const {
    wrapper: wrapperClass = '',
    field: fieldClass = '',
    error: errorClass = '',
  } = classes;

  const [isOpen, setIsOpen] = useState(false);

  if (!isController) {
    return (
      <div className={`${wrapperClass}`}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            {...extraProps}
            open={isOpen}
            onOpen={() => !isOpen && setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            defaultValue={defaultValue ? moment(defaultValue) : undefined}
            autoFocus={autoFocus}
            className={fieldClass}
            label={label}
            format='MMM DD YYYY'
            placeholder={label}
            onChange={(date) => {
              if (handleOnChange) {
                handleOnChange(date, name);
              }
            }}
            disabled={isDisabled}
            slotProps={{
              textField: {
                size: 'small',
                color: 'secondary',
                fullWidth: fullWidth,
                error: isError,
                onClick: () => setIsOpen(true),
              },
              actionBar: {
                actions: ['today', 'clear', 'accept'],
              },
            }}
            disableFuture={disableFuture}
            disablePast={disablePast}
            maxDate={maxDate ? moment(maxDate) : undefined}
            minDate={minDate ? moment(minDate) : undefined}
            closeOnSelect
            views={['year', 'month', 'day']}
          />
        </LocalizationProvider>
      </div>
    );
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <div className={wrapperClass}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                {...extraProps}
                open={isOpen}
                onOpen={() => !isOpen && setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                defaultValue={field.value ? moment(field.value) : undefined}
                autoFocus={autoFocus}
                className={fieldClass}
                label={label}
                format='MMM DD YYYY'
                placeholder={label}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                disabled={isDisabled}
                slotProps={{
                  textField: {
                    size: 'small',
                    color: 'secondary',
                    fullWidth: fullWidth,
                    error: !!error,
                    onClick: () => setIsOpen(true),
                  },
                  actionBar: {
                    actions: ['today', 'clear', 'accept'],
                  },
                }}
                disableFuture={disableFuture}
                disablePast={disablePast}
                maxDate={maxDate ? maxDate : undefined}
                minDate={minDate ? minDate : undefined}
                closeOnSelect
                views={['year', 'month', 'day']}
              />
            </LocalizationProvider>
            {error && (
              <span className={`text-red-500 small ${errorClass}`}>{error.message}</span>
            )}
          </div>
        )}
      />
    </>
  );
};

HFDateSelector.propTypes = {
  autoFocus: PropTypes.bool,
  classes: PropTypes.shape({
    error: PropTypes.string,
    field: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleOnChange: PropTypes.func,
  isController: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  name: PropTypes.string,
};

export default HFDateSelector;
