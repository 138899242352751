import { Button, Stack, Typography } from '@mui/material';
import React from 'react';

export function BlocklyToolbox(props) {
  const { workspace } = props;
  return (
    <Stack
      minWidth={140}
      direction={'column'}
      py={1}
      alignItems={'stretch'}
      justifyContent={'space-between'}
    >
      <Stack direction={'column'} spacing={1} alignItems={'stretch'}>
        {workspace?.getToolbox()?.toolboxDef_?.contents?.map((item, i) => {
          const { name } = item;
          return (
            <Button
              key={name}
              onClick={() => {
                workspace?.getToolbox()?.selectItemByPosition(i);
              }}
              sx={{ justifyContent: 'flex-start' }}
            >
              <Typography letterSpacing={1} variant='h5'>
                {name}
              </Typography>
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
}
