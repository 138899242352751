import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { Typography, useTheme } from '@mui/material';

import { FiltersCreator, Table } from 'components';

import { tokens } from 'theme';
import { getFormattedDate } from 'utils/common';
import { CRUD_FILERS_TYPE } from 'constants/Filter.constants';
import { auditLogURL } from 'services/api/routes/common';

const filterJSON = {
  initialQuery: { user_ids: [] },
  wrapperClass: 'gap-3 grid grid-cols-6',
  fields: [
    {
      type: CRUD_FILERS_TYPE.SEARCH,
      name: 'title',
      label: 'Search Title',
      placeholder: 'Search...',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.SELECT_USER,
      name: 'user_ids',
      label: 'Activity By',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      selectPluginConfig: {
        isClearable: true,
        isMulti: true,
      },
    },
    {
      type: CRUD_FILERS_TYPE.SEARCH,
      name: 'description',
      label: 'Search Description',
      placeholder: 'Search...',
      classes: {
        wrapper: 'col-span-full',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.DATE_TIME,
      name: 'created_start',
      label: 'From',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
    },
    {
      type: CRUD_FILERS_TYPE.DATE_TIME,
      name: 'created_end',
      label: 'To',
      classes: {
        wrapper: 'col-span-full md:col-span-3',
        field: '',
        error: '',
      },
      dateConfig: {
        dependent: 'created_start',
      },
    },
  ],
};

const ActivityLogsTable = (props) => {
  const { API_options = {} } = props;
  const { log_type = '', obj_id = '' } = API_options;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { initialQuery: filterQuery = {}, fields = [], wrapperClass = '' } = filterJSON;

  const initialQuery = { ...filterQuery };
  const [filterObj, setFilterObj] = useState({ query: initialQuery });

  const activityColumns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 60,
      flex: 0.4,
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 120,
      flex: 0.9,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 120,
      flex: 2.1,
      renderCell: ({ row: { description } }) => (
        <div className='capitalize'>{description}</div>
      ),
    },
    {
      field: 'user_id',
      headerName: 'Activity By',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: ({ row: { user } }) => <div className='capitalize'>{user.name}</div>,
    },
    {
      field: 'timestamp',
      headerName: 'Created At',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row: { timestamp } }) => <>{getFormattedDate(timestamp * 1000)}</>,
    },
  ];

  const tableDataURL = useMemo(
    () => ({ ...auditLogURL(log_type, obj_id) }),
    [log_type, obj_id]
  );

  return (
    <>
      <div className='flex gap-3 !mt-8 mb-1'>
        <Typography
          variant='h3'
          gutterBottom
          color={colors.grey[100]}
          className='capitalize '
        >
          Activity Logs
        </Typography>
        <FiltersCreator
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          fields={fields}
          wrapperClass={wrapperClass}
          initialValues={{ query: initialQuery }}
        />
      </div>
      <Table columns={activityColumns} dataURL={tableDataURL} filter={filterObj} />
    </>
  );
};

ActivityLogsTable.propTypes = {
  API_options: PropTypes.shape({
    log_type: PropTypes.string,
    obj_id: PropTypes.string,
  }),
};

export default ActivityLogsTable;
