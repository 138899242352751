import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Axios from 'services/api/Config';
import { tokens } from 'theme';
import { useAuthUser } from 'utils/context/AuthUserContext';

import { copyObj, createQuery } from 'utils/common';
import { isEqual } from 'utils/lodashImports';

const Table = (props) => {
  const {
    columns = [],
    tableDATA = [],
    dataURL,
    filter,
    showPagination = true,
    hideFooter = false,
    checkEqual = true,
    rowSelection = false,
    rowID = 'id',
    handleSelection,
    rowSelectionModel = [],
  } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accessAccount } = useAuthUser();

  const [tableData, setTableData] = useState(tableDATA);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [obj, setObj] = useState({
    query: filter ? filter?.query : undefined,
    options: {
      limit: pageSize,
      page: currentPage,
      sort_by: undefined,
      sort_order: undefined,
    },
  });

  useEffect(() => {
    if (obj && dataURL) {
      setLoading(true);
      Axios({
        ...dataURL,
        params: {
          ...obj.options,
          account_id: accessAccount?.id,
          ...createQuery(obj.query),
        },
      })
        .then((res) => {
          const resData = res.data;
          setTableData(resData === null ? [] : resData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dataURL, obj, checkEqual, accessAccount?.id]);

  useEffect(() => {
    if (!dataURL) {
      setTableData(tableDATA);
    }
  }, [dataURL, tableDATA]);

  useMemo(() => {
    if (filter?.query !== null && filter?.query !== undefined) {
      const options = { ...copyObj(obj), query: filter?.query };
      if (isEqual({}, filter?.query)) {
        delete options?.query;
      }
      setObj(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.query]);

  const handleSortModelChange = ([sortConfig]) => {
    let options = {
      ...obj,
      options: {
        ...obj.options,
        sort_by: sortConfig?.field,
        sort_order: sortConfig?.sort,
      },
    };
    setObj(options);
  };

  const handlePageSizeChange = (pgSize) => {
    let options = {
      ...obj,
      options: {
        ...obj.options,
        limit: pgSize,
        page: 1,
      },
    };
    setCurrentPage(1);
    setObj(options);
    setPageSize(pgSize);
  };

  const handlePageChange = (pgNo) => {
    let options = {
      ...obj,
      options: {
        ...obj.options,
        page: pgNo,
      },
    };
    setObj(options);
    setCurrentPage(pgNo);
  };

  return (
    <div>
      <Box
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
            fontSize: '14px',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[800],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
            height: tableData.length > 0 ? null : '120px',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: '#0a0b19',
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: `16px !important`,
            fontWeight: 600,
          },
          '& .MuiDataGrid-columnSeparator': {
            color: 'white !important',
          },
        }}
      >
        <DataGrid
          sx={{
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          }}
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={tableData}
          loading={loading}
          getRowId={(row) => row[rowID]}
          disableColumnFilter
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          checkboxSelection={rowSelection}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleSelection}
          slots={{
            loadingOverlay: TableLoadingOverlay,
            noRowsOverlay: EmptyTableOverlay,
            pagination: TablePagination,
          }}
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          paginationMode='server'
          rowCount={0}
          hideFooterPagination={!showPagination}
          slotProps={{
            pagination: {
              pageSize,
              handlePageSizeChange,
              currentPage,
              handlePageChange,
              records: tableData.length,
            },
          }}
          hideFooter={hideFooter}
        />
      </Box>
    </div>
  );
};

const TablePagination = (props) => {
  const { pageSize, handlePageSizeChange, currentPage, handlePageChange, records } =
    props;

  const handleBackButtonClick = () => {
    handlePageChange(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    handlePageChange(currentPage + 1);
  };

  return (
    <div className='flex items-center justify-center h-full gap-4'>
      <div className='flex items-center gap-3'>
        <p>Rows per page:</p>
        <Select
          value={pageSize}
          onChange={(e) => handlePageSizeChange(e.target.value)}
          size='small'
          displayEmpty
        >
          {[10, 20, 30, 50].map((val) => (
            <MenuItem value={val} key={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className='flex items-center gap-2 me-3'>
        <Tooltip title='Previous page' placement='top' arrow>
          <span>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={currentPage === 1}
              aria-label='previous page'
            >
              <ArrowBackIosNewIcon fontSize='small' />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title='Next page' placement='top' arrow>
          <span>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={records < pageSize}
              aria-label='next page'
            >
              <ArrowForwardIosIcon fontSize='small' />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

const TableLoadingOverlay = () => {
  return (
    <div className='flex items-center justify-center h-full'>
      <CircularProgress color='secondary' />
    </div>
  );
};

const EmptyTableOverlay = () => {
  return (
    <div className='flex flex-col items-center justify-center h-full'>
      <ManageSearchIcon color='action' className='!text-4xl' />
      <p>No Records Found</p>
    </div>
  );
};

Table.propTypes = {
  checkEqual: PropTypes.bool,
  columns: PropTypes.array,
  dataURL: PropTypes.object,
  filter: PropTypes.shape({
    query: PropTypes.object,
  }),
  handleSelection: PropTypes.func,
  hideFooter: PropTypes.bool,
  rowID: PropTypes.string,
  rowSelection: PropTypes.bool,
  rowSelectionModel: PropTypes.array,
  showPagination: PropTypes.bool,
  tableDATA: PropTypes.array,
};

export default Table;
