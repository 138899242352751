import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router';

import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import TocIcon from '@mui/icons-material/Toc';

import { Header, Table } from 'components';

import Axios from 'services/api/Config';
import { tokens } from 'theme';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { getDDPropertiesURL, searchDDTableURL } from 'services/api/routes/dynamicData';

import FieldsModel from './components/FieldsModel';
import { getFormattedDate } from 'utils/common';

const DataTypesTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let { typeId } = useParams();
  const { search } = useLocation();
  const { accessAccount } = useAuthUser();

  let params = useMemo(() => new URLSearchParams(search), [search]);

  const [typeDetail, setTypeDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);

  const [fieldModel, setFieldModel] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Axios({
      ...getDDPropertiesURL(typeId),
      params: { account_id: accessAccount?.id },
    })
      .then((response) => {
        const res = response.data;
        setTypeDetail(res);

        const allFields = res?.fields;
        const tempColumn = [];

        allFields.forEach((field) => {
          let tField = {
            field: field?.name,
            headerName: field?.display_name === '' ? field?.name : field?.display_name,
            flex: 1,
            minWidth: 120,
            sortable: false,
          };

          if (field?.type === 'datetime') {
            tField = {
              ...tField,
              minWidth: 180,
              renderCell: ({ row }) => <>{getFormattedDate(row[field?.name] * 1000)}</>,
            };
          }

          const fieldLength =
            field?.display_name !== ''
              ? field?.display_name?.length
              : field?.name?.length;

          if (fieldLength >= 14 && fieldLength <= 20) {
            tField = { ...tField, minWidth: 180 };
          }
          if (fieldLength >= 20 && fieldLength < 25) {
            tField = { ...tField, minWidth: 220 };
          }
          if (fieldLength >= 25) {
            tField = { ...tField, minWidth: 250 };
          }

          tempColumn.push(tField);
        });

        setTableColumn(tempColumn);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessAccount?.id, typeId]);

  const handleModelClose = () => setFieldModel(false);
  const handleModelOpen = () => setFieldModel(true);

  return (
    <>
      <Box m='20px'>
        {isLoading ? (
          <div className='w-full my-4 text-center'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <>
            <div className='flex justify-between'>
              <Header title={typeDetail?.display_name} />

              <div>
                <Button
                  variant='outlined'
                  color='secondary'
                  sx={{
                    textTransform: 'none',
                    color: colors.grey[100],
                    backgroundColor: colors.primary[600],
                    border: `1px solid ${colors.grey[100]}`,
                  }}
                  startIcon={<TocIcon />}
                  onClick={handleModelOpen}
                >
                  View Fields
                </Button>
              </div>
            </div>
            {typeDetail && (
              <Table
                columns={tableColumn}
                dataURL={searchDDTableURL}
                filter={{ query: { ddt_type: params.get('ddtId') } }}
              />
            )}
          </>
        )}
      </Box>
      <FieldsModel
        fieldsModel={fieldModel}
        handleFieldsClose={handleModelClose}
        typeDetail={typeDetail}
        fields={typeDetail?.fields}
      />
    </>
  );
};

export default DataTypesTable;
