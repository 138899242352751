import moment from 'moment-timezone';
import { isEmpty, compact, isArray } from './lodashImports';

/**
 * Find Value from an object
 * @param  {} data
 * @param  {} key
 */
export const getDataFromObject = (data, key) => {
  if (!data || !key || typeof data !== 'object') return false;
  key = key.replace(/\[(\w+)\]/g, '.$1').replace(/^\./, '');
  let keys = key.split('.');

  for (var i = 0, n = keys.length; i < n; ++i) {
    var _key = keys[i];
    if (_key in data && data[_key]) {
      data = data[_key];
    } else {
      return false;
    }
  }
  return data;
};

/**
 * creates query params from objects
 * @param  {} obj
 */
export const createQuery = (obj) => {
  let finalQuery = { ...cleanDeepObj(obj) };

  Object.entries(finalQuery).forEach(([key, value]) => {
    if (isArray(value)) {
      finalQuery[key] = value.join();
    }
  });

  return finalQuery;
};

/**
 * copy an object
 * @param  {} object
 */
export const copyObj = (object) => {
  return JSON.parse(JSON.stringify(object));
};

/**
 * Clean Object
 * @param  {} object
 */
export const cleanObj = (object) => {
  Object.entries(object).forEach((entry) => {
    const [key, value] = entry;
    if (value === '' || value === null) {
      delete object[key];
    }
  });
  return object;
};

/**
 * Clean Object Deep
 * @param  {} object
 */
export const cleanDeepObj = (object) => {
  if (typeof object === 'object' && object !== null) {
    Object.entries(object).forEach((entry) => {
      const [key, value] = entry;

      if (value === '' || value === null) {
        delete object[key];
      }

      if (typeof value === 'object' && value !== null) {
        const temp = cleanDeepObj(value);
        if (isEmpty(temp)) {
          delete object[key];
        } else {
          object[key] = isArray(temp) ? compact(temp) : temp;
        }
      }
    });
  }
  return object;
};

/**
 * Get Options List
 * @param  {} array=[]
 * @param  {} nameKey Label
 * @param  {} valueKey Value
 */
export const getSelectOptions = (array = [], nameKey, valueKey) => {
  let finalOptions = [];
  array.forEach((opt) => {
    finalOptions.push({ label: opt[nameKey], value: opt[valueKey], ...opt });
  });
  return finalOptions;
};

/**
 * get image url for public folder
 * @param  {} pathname
 */
export const toAbsoluteUrl = (pathname) => import.meta.env.PUBLIC_URL + pathname;

/**
 * checks if current path is active
 * @param  {} path
 * @param  {} location
 */
export const checkActiveTab = (path, location) => location.startsWith(path);

/**
 * clear LocalStorage
 */
export const clearLocal = () => localStorage.clear();

/**
 * get formatted date < ddd	MMM DD, hh:mm:ss A z >
 * @param  {} data
 */
export const getFormattedDate = (data) => {
  return moment.tz(data, moment.tz.guess()).format('ddd	MMM DD, hh:mm:ss A z');
};

/**
 * converts number to readable string
 * @param  {} number
 */
export const formatNumber = (number) => {
  const abbreviations = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ];

  const match = abbreviations
    .slice()
    .reverse()
    .find((abbreviation) => {
      return number >= abbreviation.value;
    });

  if (!match) {
    return number.toString();
  }

  const formattedNumber = (number / match.value).toFixed(2);
  return `${formattedNumber} ${match.symbol}`;
};

/**
 * Custom Retry function for Lazy Loading
 * @param  {} lazyComponent
 * @param  {} retriesLeft=5
 * @param  {} interval=1000
 */
export const retry = (lazyComponent, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(lazyComponent, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const isLocal = () => {
  return process.env.REACT_APP_ENV === process.env.REACT_APP_ENV_LOCAL;
};

export const isProd = () => {
  return process.env.REACT_APP_ENV === process.env.REACT_APP_ENV_PROD;
};
