import React from 'react';

import * as Blockly from 'blockly/core';
import { createRoot } from 'react-dom/client';
import { LocalizationProvider, StaticDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from 'theme';
import dayjs from 'dayjs';
import { FieldDropdown, utils } from 'blockly';

export class FieldDateInput extends Blockly.Field {
  SERIALIZABLE = true;
  CURSOR = 'pointer';

  static fromJson(options) {
    return new this(options['text']);
  }

  initView() {
    super.initView();
    if (this.getConstants()?.FIELD_DROPDOWN_SVG_ARROW) {
      this.createSVGArrow_();
    } else {
      this.createTextArrow_();
    }
  }

  createTextArrow_() {
    this.arrow = utils.dom.createSvgElement(
      utils.Svg.TSPAN,
      { fill: this.sourceBlock_.style.colourPrimary },
      this.textElement_
    );
    this.arrow?.appendChild(
      document.createTextNode(
        this.getSourceBlock()?.RTL
          ? FieldDropdown.ARROW_CHAR + ' '
          : ' ' + FieldDropdown.ARROW_CHAR
      )
    );
    if (this.getSourceBlock()?.RTL) {
      this.getTextElement().insertBefore(this.arrow, this.textContent_);
    } else {
      this.getTextElement().appendChild(this.arrow);
    }
  }

  createSVGArrow_() {
    this.svgArrow = utils.dom.createSvgElement(
      utils.Svg.IMAGE,
      {
        height: this.getConstants()?.FIELD_DROPDOWN_SVG_ARROW_SIZE + 'px',
        width: this.getConstants()?.FIELD_DROPDOWN_SVG_ARROW_SIZE + 'px',
      },
      this.fieldGroup_
    );
    this.svgArrow?.setAttributeNS(
      utils.dom.XLINK_NS,
      'xlink:href',
      this.getConstants()?.FIELD_DROPDOWN_SVG_ARROW_DATAURI
    );
  }

  showEditor_() {
    this.div_ = Blockly.DropDownDiv.getContentDiv();
    this.react_root = createRoot(this.div_);
    this.react_root.render(this.render());

    var border = this.sourceBlock_.style.colourTertiary;
    border = border.colourBorder || border.colourLight;
    Blockly.DropDownDiv.setColour(this.sourceBlock_.getColour(), border);

    Blockly.DropDownDiv.getContentDiv().style.maxHeight = 'max-content';
    Blockly.DropDownDiv.getContentDiv().style.maxWidth = 'max-content';

    window.requestAnimationFrame(() => {
      Blockly.DropDownDiv.showPositionedByField(this, this.dropdownDispose.bind(this));
    });
  }

  onDateSelected = (value) => {
    this.setValue(value.$d.toISOString());
    Blockly.DropDownDiv.hideIfOwner(this, true);
  };

  getDisplayText_() {
    return dayjs(this.getValue()).format('DD/MM/YYYY HH:mm:ss');
  }

  onDateChange = (value) => {
    this.setValue(value.$d.toISOString());
  };

  dropdownDispose() {
    if (this.react_root) this.react_root.unmount();
  }

  render() {
    return (
      <FieldRenderComponent
        value={this.getValue()}
        onDateChange={this.onDateChange}
        onDateSelected={this.onDateSelected}
      />
    );
  }
}

function FieldRenderComponent(props) {
  const { value, onDateChange, onDateSelected } = props;
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateTimePicker
            orientation='landscape'
            value={dayjs(value)}
            onChange={onDateChange}
            onAccept={onDateSelected}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

Blockly.fieldRegistry.register('field_date_input', FieldDateInput);

export default FieldDateInput;
