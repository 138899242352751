import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  useTheme,
} from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import Axios from 'services/api/Config';
import { Toast } from 'components';

import { tokens } from 'theme';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { currentUserAccessAccountURL } from 'services/api/routes/accounts';

const CallBack = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [accountList, setAccountList] = useState([]);
  const [isAccountLoader, setIsAccountLoader] = useState(true);

  const { isAuth, handleLogout, authUser, handleAccessAccount, fetchNewPermissions } =
    useAuthUser();

  const handleAccountSelect = useCallback(
    (val) => {
      handleAccessAccount(val);
      fetchNewPermissions(val?.id);
      navigate('/dashboard');
    },
    [fetchNewPermissions, handleAccessAccount, navigate]
  );

  const fetch = useCallback(() => {
    if (authUser) {
      setIsAccountLoader(true);
      Axios({ ...currentUserAccessAccountURL })
        .then((response) => {
          const res = response.data;
          setAccountList(res);
          if (res.length === 1) {
            handleAccountSelect(res[0]);
          }
        })
        .catch(() => {
          handleLogout();
          Toast.error('Something went wrong!');
        })
        .finally(() => {
          setIsAccountLoader(false);
        });
    }
  }, [authUser, handleAccountSelect, handleLogout]);

  useEffect(() => {
    if (isAuth) fetch();
  }, [fetch, isAuth]);

  return (
    <>
      {isAccountLoader ? (
        <div className='w-full my-4 text-center'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center'>
          <div className='flex flex-col mt-6 mb-3 text-center'>
            <span className='text-4xl'>Byte Detection</span>
            <span className='text-xl text-gray-400'>Choose an Account</span>
          </div>
          <Card
            className='w-full h-full md:w-1/2 lg:w-2/5'
            variant='outlined'
            sx={{ backgroundColor: colors.primary[600] }}
          >
            <CardContent className='flex flex-col items-center gap-3 text-center !p-3'>
              {accountList.map((element) => {
                return (
                  <Card
                    key={element?.id}
                    className='w-full lg:w-1/2'
                    sx={{ backgroundColor: colors.primary[400] }}
                  >
                    <CardActionArea onClick={() => handleAccountSelect(element)}>
                      <CardContent>{element?.name}</CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
              {accountList.length === 0 && (
                <span className='text-xl text-gray-300'>
                  You don't have access to any accounts
                </span>
              )}
            </CardContent>
          </Card>
          <div>
            <Button
              variant='text'
              color='info'
              className='!mt-3'
              sx={{ textTransform: 'none', color: colors.grey[100] }}
              startIcon={<PowerSettingsNewIcon />}
              onClick={() => handleLogout()}
            >
              Logout
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CallBack;
