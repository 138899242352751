// Users
export const usersListURL = {
  url: 'users',
  method: 'GET',
};

export const getUsersPermissionByIdURL = (user_id) => ({
  url: `users/${user_id}/permissions`,
  method: 'GET',
});

export const getUsersByIdURL = (id) => ({
  url: `users/${id}`,
  method: 'GET',
});

export const updateUsersURL = (id) => ({
  url: `users/${id}`,
  method: 'PUT',
});

export const currentUserURL = {
  url: 'users/me',
  method: 'GET',
};
