import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { HFTextInput, Toast } from 'components';
import { tokens } from 'theme';

import Axios from 'services/api/Config';
import { useAuthUser } from 'utils/context/AuthUserContext';
import { addAPIKeyURL } from 'services/api/routes/accounts';

const addAPISchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const AddAPIModel = (props) => {
  const { addModel = false, handleAddClose, tableRefresh } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accessAccount } = useAuthUser();

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = { name: '' };

  const {
    reset,
    handleSubmit,
    control: addControl,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(addAPISchema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    Axios({ ...addAPIKeyURL(accessAccount?.id), data: data })
      .then((res) => {
        Toast.success('API key Added.');
        tableRefresh();
        handleClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    reset(initialValues);
    handleAddClose();
  };

  return (
    <>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={addModel}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: colors.primary[500],
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id='alert-dialog-title'>Create New API key</DialogTitle>
          <DialogContent>
            <div className='mt-2'>
              <HFTextInput id='name' name='name' label='Name' control={addControl} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant='outlined'
              color='error'
              sx={{
                textTransform: 'none',
                color: colors.grey[100],
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              color='secondary'
              variant='contained'
              loading={isLoading}
              loadingPosition='center'
              sx={{
                textTransform: 'none',
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
            >
              Create API key
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

AddAPIModel.propTypes = {
  addModel: PropTypes.bool,
  handleAddClose: PropTypes.func,
  tableRefresh: PropTypes.func,
};

export default AddAPIModel;
