import { jsonGenerator } from '../json';

jsonGenerator.forBlock['bd_value_number'] = function (block, generator) {
  const F0 = block.getFieldValue('F0');
  const code = `
  {
    "block" :  "number",
    "return_type" :  "number",
    "val" :  ${F0}
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_value_string'] = function (block, generator) {
  const F0 = block.getFieldValue('F0');
  const code = `
  {
    "block" :  "string",
    "return_type" :  "string",
    "val" :  "${F0}"
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_value_boolean'] = function (block, generator) {
  const F0 = block.getFieldValue('F0');
  const code = `
  {
    "block" :  "boolean",
    "return_type" :  "boolean",
    "val" :  ${F0}
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_value_percent'] = function (block, generator) {
  const F0 = block.getFieldValue('F0');
  const code = `
  {
    "block" :  "number",
    "return_type" :  "number",
    "val" :  ${F0 / 100}
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_value_field'] = function (block, generator) {
  const op0 = block.getFieldValue('F0');
  const op1 = block.getFieldValue('F1');
  const code = `
  {
    "block": "dynamic_field",
    "return_type": "${op1.split('**')[1]}",
    "val": "${op0.V}.${op1.split('**')[0]}"
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_value_list'] = function (block, generator) {
  const op0 = block.getFieldValue('F0');
  const code = `
  {
    "block": "account_list",
    "return_type": "list",
    "val": "${op0.V}"
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_value_time'] = function (block, generator) {
  const T_TYPE = block.getFieldValue('F0');
  const VAL = block.getFieldValue('F1');
  const TU = block.getFieldValue('F2');
  const code = `
  {
    "block": "time_value",
    "return_type": "datetime",
    "val" : "${VAL}",
    "time_type" : "${T_TYPE}",
    "unit" : ${T_TYPE === 'relative' ? `"${TU}"` : `""`}
  }
  `;
  return [code, 0];
};

jsonGenerator.forBlock['bd_value_filters'] = function (block, generator) {
  const inputs = [];
  let i = 0;
  while (block.getInput(`EV${i}`)) {
    const input = generator.valueToCode(block, `EV${i}`, 0) || `""`;
    inputs.push(input);
    i++;
  }
  const code = `[${inputs.toString()}]`;
  return [code, 0];
};
