// Account
export const currentUserAccessAccountURL = {
  url: 'accounts',
  method: 'GET',
};

export const currentAccountDetailsURL = (account_id) => ({
  url: `accounts/${account_id}`,
  method: 'GET',
});

export const accountStatsURL = (account_id) => ({
  url: `accounts/${account_id}/stats`,
  method: 'GET',
});

export const getAPIListURL = (account_id) => ({
  url: `accounts/${account_id}/api_keys`,
  method: 'GET',
});

export const addAPIKeyURL = (account_id) => ({
  url: `accounts/${account_id}/api_keys`,
  method: 'POST',
});

export const deleteAPIKeyURL = (account_id) => ({
  url: `accounts/${account_id}/api_keys`,
  method: 'DELETE',
});

export const inviteAccountURL = (account_id) => ({
  url: `accounts/${account_id}/invite`,
  method: 'POST',
});
